import React from "react";
import { salesPageNavigationLinks } from "constants/navigationConstants";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/SharedStyle.styled";
import Title from "components/Title/Title";
import { SalesPageCardContainer } from "./SalesPage.styled";
import PageCardWithText from "components/Cards/PageCard/PageCardWithText";

const SalesPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Title title={t("pages.sales")} />
      <SalesPageCardContainer>
        {salesPageNavigationLinks.map((link) => (
          <PageCardWithText
            key={link.title}
            path={link.path}
            Icon={link.icon}
            title={link.title}
            text={link.text}
          />
        ))}
      </SalesPageCardContainer>
    </PageContainer>
  );
};

export default SalesPage;
