import React from "react";
import PropTypes from "prop-types";
import { Box, Divider } from "@mui/material";
import { RowContainer } from "styles/SharedStyle.styled";
import TextInput from "components/Inputs/TextInput";
import { useTranslation } from "react-i18next";
import SelectInput from "components/Inputs/SelectInput";
import CountrySelect from "components/Inputs/CountrySelect";
import LocationSelect from "components/Inputs/LocationSelect";
import FormActions from "../FormActions/FormActions";
import {
  ACCOMMODATION,
  ARRANGEMENT,
  TRANSPORT,
} from "constants/offerDetailsLevel";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { selectCurrentAgencyName } from "features/auth/authSlice";
import { useSelector } from "react-redux";
import CategorySelect from "components/Inputs/CategorySelect";
import OfferTypeSelect from "components/Inputs/OfferTypeSelect";
import AccommodationTypeSelect from "components/Inputs/AccommodationTypeSelect";
import ServiceSelect from "components/Inputs/ServicesSelect";
import TransportationTypeSelect from "components/Inputs/TransportationTypeSelect";
import AccommodationUnitTypeSelect from "components/Inputs/AccommodationUnitTypeSelect";
import AccommodationSelect from "components/Inputs/AccommodationSelect";

const OfferForm = ({ formik, offerDetailsLevelId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(PAGES.OFFERS.route);
  };
  const agencyName = useSelector(selectCurrentAgencyName);
  const accommodationApiProps = {
    countryId: formik.values?.countryId,
    locationId: formik.values?.locationId,
    accommodationTypeId: formik.values?.accommodationTypeId,
    skip:
      !formik.values?.countryId ||
      !formik.values?.locationId ||
      !formik.values?.accommodationTypeId,
  }
  return (
    <Box component="form">
      <RowContainer>
        <TextInput
          fullWidth
          name="name"
          label={t("field.offerNamePlaceholderRequired")}
          value={formik?.values?.name || ""}
          onChange={formik?.handleChange}
          error={formik?.touched?.name && Boolean(formik?.errors?.name)}
          helperText={formik?.touched?.name && formik?.errors?.name}
          style={{ marginRight: "24px" }}
        />
        <TextInput
          fullWidth
          name="number"
          label={t("field.offerNumberPlaceholderRequired")}
          value={formik.values?.number || ""}
          onChange={formik.handleChange}
          error={formik.touched.number && Boolean(formik.errors.number)}
          helperText={formik.touched.number && formik.errors.number}
        />
      </RowContainer>
      <RowContainer>
        <TextInput
          fullWidth
          name="travelOrganizerId"
          label={t("field.travelOrganizerPlaceholderRequired")}
          value={agencyName || ""}
          onChange={formik.handleChange}
          error={
            formik.touched.travelOrganizerId &&
            Boolean(formik.errors.travelOrganizerId)
          }
          helperText={
            formik.touched.travelOrganizerId && formik.errors.travelOrganizerId
          }
          style={{ marginRight: "24px" }}
          disabled
        />
        <TextInput
          fullWidth
          name="jid"
          label={t("field.jidPlaceholder")}
          value={formik.values?.jid || ""}
          onChange={formik.handleChange}
          error={formik.touched.jid && Boolean(formik.errors.jid)}
          helperText={formik.touched.jid && formik.errors.jid}
        />
      </RowContainer>

      <Divider style={{ marginBottom: 24 }} />

      <RowContainer>
        <CategorySelect
          name="categoryId"
          label={t("field.categoryPlaceholderRequired")}
          formik={formik}
          style={{ marginRight: "24px" }}
        />

        <OfferTypeSelect
          name="offerTypeId"
          label={t("field.offerTypePlaceholderRequired")}
          formik={formik}
        />
      </RowContainer>

      <RowContainer>
        <CountrySelect
          formik={formik}
          name="countryId"
          label={t("field.countryIdPlaceholder")}
          style={{ marginRight: "24px" }}
        />
        <LocationSelect
          name="locationId"
          formik={formik}
          disabled={!formik.values?.countryId}
          label={t("field.locationIdPlaceholder")}
          countryId={formik.values.countryId}
        />
      </RowContainer>

      {(offerDetailsLevelId === ARRANGEMENT ||
        offerDetailsLevelId === ACCOMMODATION) && (
        <RowContainer>
          <AccommodationTypeSelect
            name="accommodationTypeId"
            label={t("field.accommodationTypeIdPlaceholder")}
            formik={formik}
            style={{ marginRight: "24px" }}
          />
          <AccommodationSelect
            name="accommodationId"
            label={t("field.accommodationIdPlaceholderRequired")}
            formik={formik}
            disabled={
              !formik.values.countryId ||
              !formik.values.locationId ||
              !formik.values.accommodationTypeId
            }
            apiProps={accommodationApiProps}
          />
        </RowContainer>
      )}

      {(offerDetailsLevelId === ARRANGEMENT ||
        offerDetailsLevelId === ACCOMMODATION) && (
        <RowContainer>
          <AccommodationUnitTypeSelect
            name="accommodationUnitTypeId"
            label={t("field.accommodationUnitTypeRequiredPlaceholder")}
            formik={formik}
            style={{ marginRight: "24px" }}
          />
          <ServiceSelect
            name="serviceId"
            label={t("field.serviceIdPlaceholderRequired")}
            formik={formik}
          />
        </RowContainer>
      )}

      {(offerDetailsLevelId === ARRANGEMENT ||
        offerDetailsLevelId === TRANSPORT) && (
        <RowContainer>
          <TransportationTypeSelect
            name="transportationTypeId"
            label={t("field.transportatopnTypeIdPlaceholderRequired")}
            formik={formik}
            style={{ marginRight: "24px" }}
          />
          <Box style={{ width: "100%" }}></Box>
        </RowContainer>
      )}

      <Divider style={{ marginBottom: 24 }} />

      <RowContainer>
        <SelectInput
          fullWidth
          name="priceId"
          label={"Cenovnik*"}
          onChange={formik.handleChange}
          value={formik.values?.accommodationUnitTypeId || ""}
          //items={accommodationUnitTypes}
        />
      </RowContainer>

      <Divider style={{ marginBottom: 24 }} />

      <RowContainer>
        <TextInput
          fullWidth
          multiline
          minRows={4}
          maxRows={8}
          name="travelProgram"
          label={t("field.travelProgramPlaceholder")}
          value={formik?.values?.travelProgram || ""}
          onChange={formik.handleChange}
          style={{ marginRight: "24px" }}
        />

        <TextInput
          fullWidth
          multiline
          minRows={4}
          maxRows={8}
          name="description"
          label={t("field.descriptionPlaceholder")}
          value={formik?.values?.description || ""}
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
        />
      </RowContainer>

      <FormActions
        disabled={!formik.dirty || formik.isSubmitting}
        handleConfirm={formik.handleSubmit}
        handleCancel={handleCancel}
      />
    </Box>
  );
};

OfferForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object,
    handleChange: PropTypes.func,
    touched: PropTypes.object,
    errors: PropTypes.object,
    handleSubmit: PropTypes.func,
    resetForm: PropTypes.func,
    dirty: PropTypes.bool,
    isSubmitting: PropTypes.bool,
  }).isRequired,
  offerDetailsLevelId: PropTypes.oneOf([ARRANGEMENT, ACCOMMODATION, TRANSPORT])
    .isRequired,
};

export default OfferForm;
