import { apiSlice } from "features/api/apiSlice";

export const statementApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Statement", "StatementSingle"],
  endpoints: (builder) => ({
    statementDropdown: builder.query({
      query: () => ({
        url: "/statement/dropdown",
      }),
    }),
    statementList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, filter, sort } = args;
        return {
          url:
            "/statement/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(sort && {
                SortPropertyName: sort.name,
                Ascending: sort.direction === "asc" ? true : false,
              }),
              ...(filter && filter)
            }),
        };
      },
      providesTags: ["Statement"],
    }),
    statementDetails: builder.query({
      query: ({ id }) => ({
        url: `/statement/${id}`,
      }),
      providesTags: ["StatementSingle"],
    }),
    addStatement: builder.mutation({
      query: (data) => ({
        url: "/statement/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Statement", "StatementSingle"],
    }),
    editStatement: builder.mutation({
      query: (data) => ({
        url: "/statement/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Statement", "StatementSingle"],
    }),
    deleteStatement: builder.mutation({
      query: (data) => ({
        url: "/statement",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["Statement"],
    }),
  }),
});

export const {
  useStatementDropdownQuery,
  useStatementListQuery,
  useStatementDetailsQuery,
  useAddStatementMutation,
  useEditStatementMutation,
  useDeleteStatementMutation,
} = statementApiSlice;
