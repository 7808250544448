import React from "react";

import RemoveCircleIconMui from "@mui/icons-material/RemoveCircle";
import styled from "styled-components";

const RemoveCircleIconMuiStyled = styled(RemoveCircleIconMui)`
  fill: ${(props) => props?.theme?.colors?.iconColor};
`;

const RemoveCircleIcon = (props) => {
  return <RemoveCircleIconMuiStyled {...props} />;
};

RemoveCircleIcon.propTypes = {};

export default RemoveCircleIcon;
