import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import { useTranslation } from "react-i18next";
import useAuth from "hooks/useAuth";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { openModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import Status from "components/Status/Status";
import DeleteIcon from "components/Icon/Icons/DeleteIcon";
import CreateIcon from "components/Icon/Icons/CreateIcon";
import RemoveCircleIcon from "components/Icon/Icons/RemoveCircleIcon";
import CheckIcon from "components/Icon/Icons/CheckIcon";
import LockResetIcon from "components/Icon/Icons/LockResetIcon";

const useUserTable = ({ page, rowsPerPage }) => {
  const { isSuperAdmin, isManager } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "agencyId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "surname",
      label: t("tableCols.user.surnameLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: t("tableCols.user.nameLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "role",
      label: t("tableCols.user.roleLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: t("tableCols.user.emailLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "phoneNumber",
      label: t("tableCols.user.phoneNumberLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "office",
      label: t("tableCols.user.agencyNameLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "userActive",
      label: t("tableCols.user.statusLabel"),
      options: {
        filter: false,
        sort: true,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const user = arrayToObject(tableMeta.rowData, columns);
          return (
            <Status
              active={user?.userActive}
              label={
                user?.userActive
                  ? t("tableCols.user.userStatusActive")
                  : t("tableCols.user.userStatusInactive")
              }
            />
          );
        },
      },
    },
    {
      name: "updatedOn",
      label: t("tableCols.user.lastChangesLabel"),
      options: {
        filter: false,
        sort: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return format(new Date(rowData.updatedOn), "dd.MM.yyyy.");
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        display: isSuperAdmin || isManager,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          const user = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex" }}>
              <Tooltip
                title={t("tableCols.user.tooltip.change")}
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    isSuperAdmin
                      ? navigate(
                          PAGES.EDITUSERADMIN.route.replace(":id", user.id),
                          { state: { editAgencyId: user.agencyId } }
                        )
                      : navigate(PAGES.EDITUSER.route.replace(":id", user.id));
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={t("tableCols.user.tooltip.resetPassword")}
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: modalConstants.USER_RESET_PASSWORD_MODAL,
                        data: { id: user.id },
                      })
                    );
                  }}
                >
                  <LockResetIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  user?.userActive
                    ? t("tableCols.user.tooltip.blockUser")
                    : t("tableCols.user.tooltip.activateUser")
                }
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: modalConstants.USER_BLOCK_MODAL,
                        data: { id: user.id, userActive: user.userActive },
                      })
                    );
                  }}
                >
                  {user?.userActive ? <RemoveCircleIcon /> : <CheckIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip
                title={t("tableCols.user.tooltip.deleteUser")}
                placement="top"
              >
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: modalConstants.USER_DELETE_MODAL,
                        data: { id: user.id },
                      })
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useUserTable;
