import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useFormik } from "formik";
import CustomAutoComplete from "./CustomAutoComplete";
import * as Yup from "yup";
import AddEditCodebookItemModal from "components/Modals/AddEditCodebookItemModal/AddEditCodebookItemModal";
import {
  useAccommodationDropdownQuery,
  useAddAccommodationMutation,
} from "features/accommodation/accommodationApiSlice";

const AccommodationSelect = (props) => {
  const { t } = useTranslation();
  const addButtonText = t("accommodation.addButton");
  const [createAccommodation, { isLoading: isLoadingCreateAccommodation }] =
    useAddAccommodationMutation();
  const [openAdd, setOpenAdd] = useState(false);

  const handleSubmit = async (values) => {
    try {
      await createAccommodation(values).unwrap();
      if (!isLoadingCreateAccommodation) {
        addAccommodationFormik.resetForm();
        setOpenAdd(false);
        makeToastMessage(t("accommodation.addSuccessMessage"));
      }
    } catch (e) {
      makeErrorToastMessage(e.data.message);
    }
  };

  const addAccommodationFormik = useFormik({
    initialValues: {
      name: "",
      countryId: "",
      locationId: "",
      accommodationTypeId: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.accommodationRequired")),
      countryId: Yup.number().required(t("field.countryIdRequired")),
      locationId: Yup.number().required(t("field.locationIdRequired")),
      accommodationTypeId: Yup.number().required(
        t("field.accommodationTypeIdRequired")
      ),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleClose = () => {
    setOpenAdd(false);
    addAccommodationFormik.resetForm();
  };
  const { skip, ...rest } = props.apiProps || {};
  return (
    <>
      <AddEditCodebookItemModal
        open={openAdd}
        handleClose={handleClose}
        handleCloseBtn={handleClose}
        formik={addAccommodationFormik}
        title={t("accommodation.addTitle")}
        labelTitle={t("field.accommodationPlaceholder")}
        type={"accommodation"}
      />
      <CustomAutoComplete
        name={props?.name}
        label={props?.label}
        style={props?.style}
        value={props?.value}
        formik={props?.formik}
        addButtonText={addButtonText}
        addButtonClick={() => setOpenAdd(true)}
        useQuery={() => useAccommodationDropdownQuery(rest, {skip})}
        additionalOnChange={props?.setPassedAddressFlag}
        hasAdd={props?.hasAdd}
        disabled={props?.disabled}
      />
    </>
  );
};

AccommodationSelect.defaultProps = {
  hasAdd: true,
  apiProps: {},
};

AccommodationSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  formik: PropTypes.string,
  setPassedAddressFlag: PropTypes.func,
  hasAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  apiProps: PropTypes.object,
};

export default AccommodationSelect;
