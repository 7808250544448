import { Checkbox as Check, FormControlLabel } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { CheckboxContainer } from "./Checkbox.styled";

const Checkbox = ({ label, value, checked, onChange, name }) => {
  return (
    <CheckboxContainer>
      <FormControlLabel
        sx={{ whiteSpace: "nowrap" }}
        control={
          <Check
            fullWidth
            name={name}
            checked={checked}
            value={value}
            onChange={onChange}
          />
        }
        label={label}
      />
    </CheckboxContainer>
  );
};
Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default Checkbox;
