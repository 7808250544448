import React from "react";
import { PageContainer } from "styles/SharedStyle.styled";
import { useFormik } from "formik";
import externalSaleValidation from "validations/externalSaleValidation";
import ExternalContractForm from "components/Forms/ExternalContractForm/ExternalContractForm";
import externalSaleInitialValues from "initialValues/externalSaleInitialValues";
import { convertDateToISO } from "util/dateHelpers";
import {
  useAddExternalSaleMutation,
  useEditExternalSaleMutation,
  useExternalSaleDetailsQuery,
} from "features/externalSale/externalSaleApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useTemplateAgencyInfoGetByAgencyQuery } from "features/templateAgencyInfo/templateAgencyInfoApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentAgency } from "features/auth/authSlice";
import { useParams } from "react-router-dom";
import BackdropComponent from "components/Backdrop/BackdropComponent";
import Error from "pages/ErrorPage/ErrorPage";
import SendContractToEmail from "components/Modals/SendContractToEmail/SendContractToEmail";

const ExternalSalePage = () => {
  const agencyId = useSelector(selectCurrentAgency);
  const { id } = useParams();
  const isAdd = id === undefined;

  const {
    data: externalContract,
    isLoading: isLoadingExternalContract,
    error,
  } = useExternalSaleDetailsQuery({ id }, { skip: isAdd });

  const { data: receiptDescription } = useTemplateAgencyInfoGetByAgencyQuery({
    agencyId,
  });
  const [addExternalSale, { isLoading: isLoadingAddExternalSale }] =
    useAddExternalSaleMutation();
  const [editExternalSale, { isLoading: isLoadingUpdateExternalSale }] =
    useEditExternalSaleMutation();
  const handleSubmit = (values, action) => {
    const newValues = {
      ...values,
      periodFrom: convertDateToISO(values.periodFrom),
      periodTo: convertDateToISO(values.periodTo),
      departureDate: convertDateToISO(values.departureDate),
      date: convertDateToISO(values.date),
      contractTravelers: {
        ...values.contractTravelers,
        travelers: values.contractTravelers.travelers.map((traveler) => ({
          clientId: traveler.clientId,
          contractTravelerId: isAdd ? 0 : traveler.contractTravelerId,
          departureLocation: traveler.departureLocation,
          travelArranger: traveler.travelArranger,
          description: "",
        })),
      },
      contractCalculations: {
        ...values.contractCalculations,
        calculations: values.contractCalculations.calculations.map(
          (calculation) => ({
            ...calculation,
            quantity: !calculation.quantity
              ? 0
              : parseFloat(calculation.quantity),
            domesticAmount: !calculation.domesticAmount
              ? 0
              : parseFloat(calculation.domesticAmount),
            foreignAmount: !calculation.foreignAmount
              ? 0
              : parseFloat(calculation.foreignAmount),
            domesticDiscount: !calculation.domesticDiscount
              ? 0
              : parseFloat(calculation.domesticDiscount),
            foreignDiscount: !calculation.foreignDiscount
              ? 0
              : parseFloat(calculation.foreignDiscount),
            domesticTotalAmount: !calculation.domesticTotalAmount
              ? 0
              : parseFloat(calculation.domesticTotalAmount),
            foreignTotalAmount: !calculation.foreignTotalAmount
              ? 0
              : parseFloat(calculation.foreignTotalAmount),
            currencyId: !calculation.currencyId ? null : calculation.currencyId,
          })
        ),
      },
    };

    if (isAdd) {
      addExternalSale(newValues)
        .unwrap()
        .then(() => {
          if (!isLoadingAddExternalSale) {
            makeToastMessage("Uspešno ste dodali ugovor");
          }
        })
        .catch((error) => {
          makeErrorToastMessage(error.data.message);
        });
    }

    if (!isAdd) {
      editExternalSale(newValues)
        .unwrap()
        .then(() => {
          if (!isLoadingUpdateExternalSale) {
            makeToastMessage("Uspešno ste izmenili ugovor");
          }
        })
        .catch((error) => {
          makeErrorToastMessage(error.data.message);
        });
    }
    action.setSubmitting(false);
  };
  const externalApiInitialValues = () => {
    // eslint-disable-next-line no-unused-vars
    const { id, contractTypeId, ...rest } = externalContract;

    return {
      ...rest,
      id,
      periodFrom: new Date(rest?.periodFrom),
      periodTo: new Date(rest?.periodTo),
      departureDate: new Date(rest?.departureDate),
      date: new Date(rest?.date),
      contractTravelers: {
        contractId: id,
        travelers: rest?.contractTravelers.map(({ id, ...traveler }) => ({
          contractTravelerId: id,
          ...traveler,
        })),
      },
      contractCalculations: {
        contractId: id,
        calculations: rest?.contractCalculations.map(
          // eslint-disable-next-line no-unused-vars
          ({ contractId, id, ...calculation }) => ({
            ...calculation,
            domesticDiscount:
              calculation.domesticDiscount > 0
                ? calculation.domesticDiscount.toFixed(2).toString()
                : "",
            foreignDiscount:
              calculation.foreignDiscount > 0
                ? calculation.foreignDiscount.toFixed(2).toString()
                : "",
            domesticAmount:
              calculation.domesticAmount > 0
                ? calculation.domesticAmount.toFixed(2).toString()
                : "",
            foreignAmount:
              calculation.foreignAmount > 0
                ? calculation.foreignAmount.toFixed(2).toString()
                : "",
            domesticTotalAmount:
              calculation.domesticTotalAmount > 0
                ? calculation.domesticTotalAmount.toFixed(2).toString()
                : "",
            foreignTotalAmount:
              calculation.foreignTotalAmount > 0
                ? calculation.foreignTotalAmount.toFixed(2).toString()
                : "",
            contractCalculationId: contractId,
          })
        ),
      },
    };
  };

  const formik = useFormik({
    initialValues:
      id && !isLoadingExternalContract
        ? externalApiInitialValues()
        : {
            ...externalSaleInitialValues,
            contractNotice: receiptDescription?.contractText,
          },
    validationSchema: externalSaleValidation,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnBlur: false,
  });

  if (isLoadingExternalContract) {
    return (
      <BackdropComponent
        position="absolute"
        isLoading={isLoadingExternalContract}
      />
    );
  }

  if (error) {
    if (error.status === 400) {
      return <Error />;
    }
  }

  return (
    <PageContainer>
      <SendContractToEmail />
      <ExternalContractForm
        isAdd={isAdd}
        formik={formik}
        handleCancel={() => formik.resetForm()}
        isLoading={formik.isSubmitting}
        id={id}
      />
    </PageContainer>
  );
};

export default ExternalSalePage;
