import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const ImagePickerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #cccccc;
  border-radius: 8px;
  padding: 20px;
  cursor: ${(props) => (props?.disabledActions ? "auto" : "pointer")};
  width: 100%;
  min-height: 200px;
`;

export const ImageWrapper = styled(Box)`
  position: relative;
  margin: 10px;
  &:hover > div {
    display: flex;
  }
`;

export const ImagePickerLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: #344054;
`;

export const ImageContainer = styled(Box)``;

export const IconWrapper = styled(Box)`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: row;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 5px;
  gap: 5px;
`;
