/* eslint-disable react/prop-types */
import React from "react";
import {
  InformationContainer,
  InformationMainText,
  FormContainer,
} from "./AgencyForm.styled";
import { RowContainer } from "styles/SharedStyle.styled";
import { useTranslation } from "react-i18next";
import {
  phoneNumberChars,
  preventNonNumericChars,
} from "util/helpers/preventNonNumericChars";
import ImagePicker from "components/ImagePicker/ImagePicker";
import { useAgencyTypeQuery } from "features/agency-type/agencyTypeApiSlice";
import useAuth from "hooks/useAuth";
import { SUPERADMIN_ROLE } from "constants/roleConstants";
import { formatDateString } from "util/dateHelpers";
import TextInput from "components/Inputs/TextInput";
import SelectInput from "components/Inputs/SelectInput";
import DatePick from "components/Inputs/DatePick";
import CountrySelect from "components/Inputs/CountrySelect";
import LocationSelect from "components/Inputs/LocationSelect";
import FormActions from "../FormActions/FormActions";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";

const AgencyForm = ({
  formik,
  setAgencyLogoImage,
  setSignatureImage,
  agencyLogoImage,
  signatureImage,
  isLoadingLogo,
  isLoadingSignature,
  handleDeleteLogo,
  handleDeleteSignature,
}) => {
  const { hasRole, isManager, isClerk } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: agencyTypes } = useAgencyTypeQuery();
  return (
    <>
      <FormContainer style={{ marginBottom: "24px" }}>
        <InformationContainer>
          <InformationMainText>
            {t("agency.section.basicInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer style={{ marginBottom: 24 }}>
          <TextInput
            name="name"
            label={t("field.agencyNamePlaceholder")}
            value={formik.values.name}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            style={{ marginRight: "24px" }}
            disabled={isClerk}
          />
          <SelectInput
            name="agencyTypeId"
            label={t("field.agencyTypeIdPlaceholder")}
            disabled={isManager || isClerk}
            onChange={formik.handleChange}
            value={formik.values.agencyTypeId}
            items={agencyTypes}
            fullWidth
            error={
              formik.touched.agencyTypeId && Boolean(formik.errors.agencyTypeId)
            }
            helperText={
              formik.touched.agencyTypeId && formik.errors.agencyTypeId
            }
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="email"
            label={t("field.emailPlaceholder")}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            disabled={isClerk}
          />
        </RowContainer>

        <RowContainer style={{ marginBottom: 24 }}>
          <CountrySelect
            name="countryId"
            label={t("field.countryIdPlaceholder")}
            formik={formik}
            style={{ marginRight: "24px" }}
            disabled={isClerk}
          />
          <LocationSelect
            formik={formik}
            name="locationId"
            label={t("field.locationIdPlaceholder")}
            disabled={isClerk || !formik.values.countryId}
            countryId={formik.values.countryId}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="address"
            label={t("field.addressPlaceholder")}
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            disabled={isClerk}
          />
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            name="postalCode"
            label={t("field.postalCodePlaceholder")}
            value={formik.values.postalCode}
            onChange={formik.handleChange}
            disabled={isClerk}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            name="phoneNumber"
            fullWidth
            label={t("field.phoneNumberRequiredPlaceholder")}
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            onKeyDown={phoneNumberChars}
            style={{ marginRight: "24px" }}
            disabled={isClerk}
          />
          <TextInput
            fullWidth
            name="webAddress"
            label={t("field.webAddressPlaceholder")}
            value={formik.values.webAddress}
            onChange={formik.handleChange}
            disabled={isClerk}
          />
        </RowContainer>
      </FormContainer>

      <FormContainer style={{ marginBottom: "24px" }}>
        <InformationContainer>
          <InformationMainText>
            {t("agency.section.agencyInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer style={{ marginBottom: 24 }}>
          <TextInput
            fullWidth
            name="pib"
            label={t("field.pibPlaceholder")}
            value={formik.values.pib}
            onChange={formik.handleChange}
            error={formik.touched.pib && Boolean(formik.errors.pib)}
            helperText={formik.touched.pib && formik.errors.pib}
            onKeyDown={preventNonNumericChars}
            style={{ marginRight: "24px" }}
            disabled={isClerk || isManager}
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 9);
            }}
          />
          <TextInput
            fullWidth
            name="legalIdentificationNumber"
            label={t("field.legalIdentificationNumberPlaceholderRequired")}
            value={formik.values.legalIdentificationNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.legalIdentificationNumber &&
              Boolean(formik.errors.legalIdentificationNumber)
            }
            disabled={isClerk || isManager}
            helperText={
              formik.touched.legalIdentificationNumber &&
              formik.errors.legalIdentificationNumber
            }
            onKeyDown={preventNonNumericChars}
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 8);
            }}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="personInCharge"
            label={t("field.personInChargePlaceholder")}
            value={formik.values.personInCharge}
            onChange={formik.handleChange}
            disabled={isClerk}
            error={
              formik.touched.personInCharge &&
              Boolean(formik.errors.personInCharge)
            }
            helperText={
              formik.touched.personInCharge && formik.errors.personInCharge
            }
          />
        </RowContainer>
      </FormContainer>
      {formik.values.agencyTypeId === 2 && (
        <FormContainer style={{ marginBottom: "24px" }}>
          <InformationContainer>
            <InformationMainText>
              {t("agency.section.licenceInfo")}
            </InformationMainText>
          </InformationContainer>
          <RowContainer>
            <TextInput
              fullWidth
              name="licenceNumber"
              label={t("field.licenceNumberPlaceholder")}
              value={formik.values.licenceNumber}
              style={{ marginRight: "24px" }}
              onChange={formik.handleChange}
              disabled={isClerk}
              error={
                formik.touched.licenceNumber &&
                Boolean(formik.errors.licenceNumber)
              }
              helperText={
                formik.touched.licenceNumber && formik.errors.licenceNumber
              }
            />
            <DatePick
              name="licenceNumberExpirationDate"
              label={t("field.licenceNumberExpirationDatePlaceholder")}
              value={new Date(formik.values.licenceNumberExpirationDate)}
              onChange={(value) =>
                formik.setFieldValue("licenceNumberExpirationDate", value, true)
              }
              disabled={isClerk}
              error={
                formik.touched.licenceNumberExpirationDate &&
                Boolean(formik.errors.licenceNumberExpirationDate)
              }
              helperText={
                formik.touched.licenceNumberExpirationDate &&
                formik.errors.licenceNumberExpirationDate
              }
            />
          </RowContainer>
        </FormContainer>
      )}
      {!hasRole(SUPERADMIN_ROLE) && (
        <FormContainer style={{ marginBottom: "24px" }}>
          <InformationContainer>
            <InformationMainText>
              {t("agency.section.systemLicenceInfo")}
            </InformationMainText>
          </InformationContainer>
          <RowContainer>
            <TextInput
              fullWidth
              disabled
              name="systemLicenceNumber"
              label={t("field.systemLicenceStatusPlaceholder")}
              InputLabelProps={{
                shrink: formik.values.systemLicenceNumber,
              }}
              value={formik.values.systemLicenceNumber}
              style={{ marginRight: "24px" }}
            />
            <TextInput
              fullWidth
              disabled
              name="systemLicenceNumberExpirationDate"
              label={t("field.systemLicenceExpirationDatePlaceholder")}
              InputLabelProps={{
                shrink: formik.values.systemLicenceNumberExpirationDate,
              }}
              value={
                formik.values.systemLicenceNumberExpirationDate
                  ? formatDateString(
                      formik.values.systemLicenceNumberExpirationDate
                    )
                  : ""
              }
            />
          </RowContainer>
        </FormContainer>
      )}

      <FormContainer style={{ marginBottom: "24px" }}>
        <InformationContainer>
          <InformationMainText>
            {t("agency.section.additionalInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <ImagePicker
            descriptionText={t("agency.logoDescriptionText")}
            additionalDescriptionText={t("common.pictureSize", { size: "2" })}
            label={t("field.agencyLogoPlaceholder")}
            images={agencyLogoImage}
            setImages={setAgencyLogoImage}
            styleContainer={{ marginRight: "24px" }}
            name="logo"
            isLoading={isLoadingLogo}
            handleDelete={handleDeleteLogo}
            disabledActions={isClerk}
          />

          <ImagePicker
            descriptionText={t("agency.signatureDescriptionText")}
            additionalDescriptionText={t("common.pictureSize", { size: "2" })}
            label={t("field.signaturePlaceholder")}
            images={signatureImage}
            setImages={setSignatureImage}
            name="signature"
            isLoading={isLoadingSignature}
            handleDelete={handleDeleteSignature}
            disabledActions={isClerk}
          />
        </RowContainer>
      </FormContainer>
      {!isClerk && (
        <FormActions
          disabled={!formik.dirty || formik.isSubmitting}
          handleConfirm={formik.handleSubmit}
          handleCancel={() => {
            navigate(PAGES.BASE.route);
          }}
        />
      )}
    </>
  );
};

export default AgencyForm;
