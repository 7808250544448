/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomDialog from "../CustomDialog/CustomDialog";
import PasswordInput from "components/Inputs/PasswordInput";
import formikParams from "constants/formikParams";
import ModalActions from "../ModalActions/ModalActions";

const ResetPasswordModal = ({ open, handleClose, formik, handleCloseBtn }) => {
  const { t } = useTranslation();
  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title={t("resetPassword.title")}
    >
      <Box component="form">
        <RowContainer>
          <PasswordInput
            label={t("field.passwordPlaceholder")}
            formik={formik}
            fullWidth
          />
        </RowContainer>

        <RowContainer>
          <PasswordInput
            name={formikParams.CONFIRM_PASSWORD}
            label={t("field.confirmPasswordPlaceholder")}
            formik={formik}
            fullWidth
          />
        </RowContainer>
        <ModalActions
          onClose={handleCloseBtn}
          disabled={!formik.dirty || formik.isSubmitting}
          onAction={formik.handleSubmit}
        />
      </Box>
    </CustomDialog>
  );
};

export default ResetPasswordModal;
