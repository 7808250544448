import { apiSlice } from "features/api/apiSlice";

export const accommodationUnitApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["AccommodationUnit", "AccommodationUnitSingle"],
  endpoints: (builder) => ({
    accommodationUnitList: builder.query({
      query: (args) => {
        const { accommodationId, page, rowsPerPage, search, sort } = args;
        return {
          url:
            "/accommodation-unit/list?" +
            new URLSearchParams({
              AccommodationId: accommodationId,
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(sort && {
                SortPropertyName: sort.name,
                Ascending: sort.direction === "asc" ? true : false,
              }),
              ...(search && { Name: search }),
              //   ...(sortDirection && { orderBy: sortDirection }),
            }),
        };
      },
      providesTags: ["AccommodationUnit"],
    }),
    accommodationUnitDetails: builder.query({
      query: ({ id }) => ({
        url: `/accommodation-unit/${id}`,
      }),
      providesTags: ["AccommodationUnitSingle"],
    }),
    addAccommodationUnit: builder.mutation({
      query: (data) => ({
        url: "/accommodation-unit/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["AccommodationUnit"],
    }),
    editAccommodationUnit: builder.mutation({
      query: (data) => ({
        url: "/accommodation-unit/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["AccommodationUnit", "AccommodationUnitSingle"],
    }),
    deleteAccommodationUnit: builder.mutation({
      query: (data) => ({
        url: "/accommodation-unit",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["AccommodationUnit"],
    }),
    accommodationUnitPhotos: builder.query({
      query: ({ accommodationUnitId }) => ({
        url: `/accommodation-unit/photos?accommodationUnitId=${accommodationUnitId}`,
      }),
    }),
  }),
});

export const {
  useAccommodationUnitListQuery,
  useAccommodationUnitDetailsQuery,
  useAddAccommodationUnitMutation,
  useEditAccommodationUnitMutation,
  useDeleteAccommodationUnitMutation,
  useAccommodationUnitPhotosQuery,
} = accommodationUnitApiSlice;
