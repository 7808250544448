import i18n from "../i18nt/index";
import { PAGES } from "./pages";

export const BREADCRUMBS = {
  ADDAGENCY: {
    route: PAGES.ADDAGENCY.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.agencyAdd"), route: "" },
    ],
  },
  PROFILE: {
    route: PAGES.PROFILE.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.profile"), route: "" },
    ],
  },
  SETTINGS: {
    route: PAGES.SETTINGS.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.settings"), route: "" },
    ],
  },
  CLIENTS: {
    route: PAGES.CLIENTS.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.clients"), route: "" },
    ],
  },
  ADDCLIENT: {
    route: PAGES.ADDCLIENT.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.clients"), route: PAGES.CLIENTS.route },
      { title: i18n.t("breadcrumbs.clientsAdd"), route: "" },
    ],
  },
  EDITCLIENT: {
    route: "/clients/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.clients"), route: PAGES.CLIENTS.route },
      { title: i18n.t("breadcrumbs.clientsEdit"), route: "" },
    ],
  },
  ADDPARTNER: {
    route: PAGES.ADDPARTNER.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.partners"), route: PAGES.PARTNERS.route },
      { title: i18n.t("breadcrumbs.partnersAdd"), route: "" },
    ],
  },
  PARTNERS: {
    route: PAGES.PARTNERS.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.partners"), route: "" },
    ],
  },
  EDITPARTNER: {
    route: "/partners/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.partners"), route: PAGES.PARTNERS.route },
      { title: i18n.t("breadcrumbs.partnersEdit"), route: "" },
    ],
  },
  ACCOMMODATION_UNIT: {
    route: "/accommodation/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
      { title: i18n.t("breadcrumbs.object"), route: PAGES.ACCOMMODATION.route },
      { title: i18n.t("breadcrumbs.accommodationUnit"), route: "" },
    ],
  },
  CODEBOOK: {
    route: PAGES.CODEBOOK.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: "" },
    ],
  },
  OFFERS: {
    route: PAGES.OFFERS.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.offers"), route: "" },
    ],
  },
  ADDOFFER: {
    route: PAGES.ADDOFFER.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.offers"), route: PAGES.OFFERS.route },
      { title: i18n.t("breadcrumbs.offersAdd"), route: "" },
    ],
  },
  EDITOFFER: {
    route: "/offers/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.offers"), route: PAGES.OFFERS.route },
      { title: i18n.t("breadcrumbs.offersEdit"), route: "" },
    ],
  },
  SALES: {
    route: PAGES.SALES.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.sales"), route: "" },
    ],
  },
  INTERNAL_SALE: {
    route: PAGES.INTERNAL_SALE.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.sales"), route: PAGES.SALES.route },
      { title: i18n.t("breadcrumbs.internalSale"), route: "" },
    ],
  },
  EXTERNAL_SALE: {
    route: PAGES.EXTERNAL_SALE.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.sales"), route: PAGES.SALES.route },
      { title: i18n.t("breadcrumbs.externalSale"), route: "" },
    ],
  },
  CONTRACTS: {
    route: PAGES.CONTRACTS.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.contracts"), route: "" },
    ],
  },
  SINGLE_CONTRACT: {
    route: "/contract/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.contracts"), route: PAGES.CONTRACTS.route },
      { title: i18n.t("breadcrumbs.singleContract"), route: "" },
    ],
  },
  CONTRACT_INTERNAL: {
    route: "/contract/internal",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.sales"), route: PAGES.SALES.route },
      {
        title: i18n.t("breadcrumbs.internalSale"),
        route: PAGES.INTERNAL_SALE.route,
      },
      { title: i18n.t("breadcrumbs.reserveInternalSale"), route: "" },
    ],
  },
  PAYMENT: {
    route: PAGES.PAYMENT.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.payment"), route: "" },
    ],
  },
  STATEMENTS: {
    route: PAGES.STATEMENTS.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.excerpts"), route: "" },
    ],
  },
  REPORTS: {
    route: PAGES.STATEMENTS.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.reports"), route: "" },
    ],
  },
  NOTIFICATIONS: {
    route: PAGES.NOTIFICATIONS.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.notifications"), route: "" },
    ],
  },
  AGENCY: {
    route: "/agency/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.agency"), route: "" },
    ],
  },
  CATEGORY: {
    route: PAGES.CATEGORY.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
      { title: i18n.t("breadcrumbs.travelCategory"), route: "" },
    ],
  },
  COUNTRY: {
    route: PAGES.COUNTRY.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
      { title: i18n.t("breadcrumbs.country"), route: "" },
    ],
  },
  LOCATION: {
    route: PAGES.LOCATION.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
      { title: i18n.t("breadcrumbs.city"), route: "" },
    ],
  },
  ACCOMMODATION_TYPE: {
    route: PAGES.ACCOMMODATION_TYPE.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
      { title: i18n.t("breadcrumbs.objectType"), route: "" },
    ],
  },
  ACCOMMODATION: {
    route: PAGES.ACCOMMODATION.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
      { title: i18n.t("breadcrumbs.object"), route: "" },
    ],
  },
  ACCOMMODATION_UNIT_TYPE: {
    route: PAGES.ACCOMMODATION_UNIT_TYPE.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
      { title: i18n.t("breadcrumbs.accommodationUnitType"), route: "" },
    ],
  },
  ACCOMMODATION_UNIT_PROPERTY: {
    route: PAGES.ACCOMMODATION_UNIT_PROPERTY.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
      {
        title: i18n.t("breadcrumbs.accommodationUnitCharacteristics"),
        route: "",
      },
    ],
  },
  SERVICES: {
    route: PAGES.SERVICES.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
      { title: i18n.t("breadcrumbs.serviceType"), route: "" },
    ],
  },
  TRANSPORTATION_TYPE: {
    route: PAGES.TRANSPORTATION_TYPE.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
      { title: i18n.t("breadcrumbs.transportationType"), route: "" },
    ],
  },
  OFFER_TYPE: {
    route: PAGES.OFFER_TYPE.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
      { title: i18n.t("breadcrumbs.offerType"), route: "" },
    ],
  },
  RECEIPTS: {
    route: PAGES.RECEIPTS.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.payment"), route: PAGES.PAYMENT.route },
      { title: i18n.t("breadcrumbs.receipts"), route: "" },
    ],
  },
  INVOICE: {
    route: PAGES.INVOICE.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.payment"), route: PAGES.PAYMENT.route },
      { title: i18n.t("breadcrumbs.invoice"), route: "" },
    ],
  },
  PROINVOICE: {
    route: PAGES.PROINVOICE.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.payment"), route: PAGES.PAYMENT.route },
      { title: i18n.t("breadcrumbs.proinvoice"), route: "" },
    ],
  },
  EXCHANGE_RATE: {
    route: PAGES.EXCHANGE_RATE.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.exchangeRate"), route: "" },
    ],
  },
  CURRENCY: {
    route: PAGES.CURRENCY.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.currency"), route: "" },
    ],
  },
  EDITUSER: {
    route: "/settings/user/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.settings"), route: PAGES.SETTINGS.route },
      {
        title: i18n.t("breadcrumbs.users"),
        route: PAGES.SETTINGS.route,
        tabId: 2,
      },
      { title: i18n.t("breadcrumbs.editUser"), route: "" },
    ],
  },
  ADDUSER: {
    route: PAGES.ADDUSER.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.settings"), route: PAGES.SETTINGS.route },
      {
        title: i18n.t("breadcrumbs.users"),
        route: PAGES.SETTINGS.route,
        tabId: 2,
      },
      { title: i18n.t("breadcrumbs.addUser"), route: "" },
    ],
  },
  EDITUSERADMIN: {
    route: "/user/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      {
        title: i18n.t("breadcrumbs.agency"),
        route: PAGES.AGENCY.route,
        dynamic: true,
      },
      {
        title: i18n.t("breadcrumbs.users"),
        route: PAGES.AGENCY.route,
        dynamic: true,
        tabId: 1,
      },
      { title: i18n.t("breadcrumbs.editUser"), route: "" },
    ],
  },
  ADDUSERADMIN: {
    route: PAGES.ADDUSERADMIN.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      {
        title: i18n.t("breadcrumbs.agency"),
        route: PAGES.AGENCY.route,
        dynamic: true,
      },
      {
        title: i18n.t("breadcrumbs.users"),
        route: PAGES.AGENCY.route,
        dynamic: true,
        tabId: 1,
      },
      { title: i18n.t("breadcrumbs.addUser"), route: "" },
    ],
  },
  ADDLICENCE: {
    route: PAGES.ADDLICENCE.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      {
        title: i18n.t("breadcrumbs.agency"),
        route: PAGES.AGENCY.route,
        dynamic: true,
      },
      {
        title: i18n.t("breadcrumbs.licence"),
        route: PAGES.AGENCY.route,
        dynamic: true,
        tabId: 2,
      },
      { title: i18n.t("breadcrumbs.addLicence"), route: "" },
    ],
  },
  EDITLICENCE: {
    route: "/licence/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      {
        title: i18n.t("breadcrumbs.agency"),
        route: PAGES.AGENCY.route,
        dynamic: true,
      },
      {
        title: i18n.t("breadcrumbs.licence"),
        route: PAGES.AGENCY.route,
        dynamic: true,
        tabId: 2,
      },
      { title: i18n.t("breadcrumbs.editLicence"), route: "" },
    ],
  },
  ADDRECEIPT: {
    route: PAGES.ADDRECEIPT.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.payment"), route: PAGES.PAYMENT.route },
      { title: i18n.t("breadcrumbs.receipts"), route: PAGES.RECEIPTS.route },
      { title: i18n.t("breadcrumbs.receiptsAdd"), route: "" },
    ],
  },
  EDITRECEIPT: {
    route: "/receipts/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.payment"), route: PAGES.PAYMENT.route },
      { title: i18n.t("breadcrumbs.receipts"), route: PAGES.RECEIPTS.route },
      { title: i18n.t("breadcrumbs.receiptsEdit"), route: "" },
    ],
  },
  ADDSTATEMENT: {
    route: PAGES.ADDSTATEMENT.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.excerpts"), route: PAGES.STATEMENTS.route },
      { title: i18n.t("breadcrumbs.statementAdd"), route: "" },
    ],
  },
  EDITSTATEMENT: {
    route: "/statements/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.excerpts"), route: PAGES.STATEMENTS.route },
      { title: i18n.t("breadcrumbs.statementEdit"), route: "" },
    ],
  },
  ADDINVOICE: {
    route: PAGES.ADDINVOICE.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.payment"), route: PAGES.PAYMENT.route },
      { title: i18n.t("breadcrumbs.invoice"), route: PAGES.INVOICE.route },
      { title: i18n.t("breadcrumbs.invoiceAdd"), route: "" },
    ],
  },
  EDITINVOICE: {
    route: "/invoice/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.payment"), route: PAGES.PAYMENT.route },
      { title: i18n.t("breadcrumbs.invoice"), route: PAGES.INVOICE.route },
      { title: i18n.t("breadcrumbs.invoiceEdit"), route: "" },
    ],
  },
  ADDPROINVOICE: {
    route: PAGES.ADDPROINVOICE.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.payment"), route: PAGES.PAYMENT.route },
      {
        title: i18n.t("breadcrumbs.proinvoice"),
        route: PAGES.PROINVOICE.route,
      },
      { title: i18n.t("breadcrumbs.proinvoiceAdd"), route: "" },
    ],
  },
  EDITPROINVOICE: {
    route: "/proinvoice/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.payment"), route: PAGES.PAYMENT.route },
      {
        title: i18n.t("breadcrumbs.proinvoice"),
        route: PAGES.PROINVOICE.route,
      },
      { title: i18n.t("breadcrumbs.proinvoiceEdit"), route: "" },
    ],
  },
  ADDBANKACCOUNT: {
    route: PAGES.ADDBANKACCOUNT.route,
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.settings"), route: PAGES.SETTINGS.route },
      {
        title: i18n.t("breadcrumbs.bankAccounts"),
        route: PAGES.SETTINGS.route,
        tabId: 1,
      },
      { title: i18n.t("breadcrumbs.addBankAccount"), route: "" },
    ],
  },
  EDITBANKACCOUNT: {
    route: "/bank-account/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.settings"), route: PAGES.SETTINGS.route },
      {
        title: i18n.t("breadcrumbs.bankAccounts"),
        route: PAGES.SETTINGS.route,
        tabId: 1,
      },
      { title: i18n.t("breadcrumbs.editBankAccount"), route: "" },
    ],
  },
  CLEARING: {
    route: "clearing/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.contracts"), route: PAGES.CONTRACTS.route },
      { title: i18n.t("breadcrumbs.clearing"), route: "" },
    ],
  },
  EDITINTERNALCONTRACT: {
    route: "/contract/internal/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.contracts"), route: PAGES.CONTRACTS.route },
      { title: i18n.t("breadcrumbs.editInternalContract"), route: "" },
    ],
  },
  EDITEXTERNALCONTRACT: {
    route: "/contract/external/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.contracts"), route: PAGES.CONTRACTS.route },
      { title: i18n.t("breadcrumbs.editExternalContract"), route: "" },
    ],
  },
  ADDACCOMMODATIONUNIT: {
    route: "/accommodation//unit/add",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
      { title: i18n.t("breadcrumbs.object"), route: PAGES.ACCOMMODATION.route },
      { title: i18n.t("breadcrumbs.accommodationUnit"), route: "" },
      { title: i18n.t("breadcrumbs.addAccommodationUnit"), route: "" },
    ],
  },
  EDITACCOMMODATIONUNIT: {
    route: "/accommodation//unit/edit/",
    breadcrumbs: [
      { title: i18n.t("breadcrumbs.home"), route: PAGES.BASE.route },
      { title: i18n.t("breadcrumbs.codebook"), route: PAGES.CODEBOOK.route },
      { title: i18n.t("breadcrumbs.object"), route: PAGES.ACCOMMODATION.route },
      { title: i18n.t("breadcrumbs.accommodationUnit"), route: "" },
      { title: i18n.t("breadcrumbs.editAccommodationUnit"), route: "" },
    ],
  },
};
