import { RowContainer } from "styles/SharedStyle.styled";
import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import selectedTheme from "themes";
import buttonVariants from "constants/buttonVariants";

const ModalActions = (props) => {
  const { t } = useTranslation();

  return (
    <RowContainer style={{ marginBottom: 0, justifyContent: "center" }}>
      <Button
        variant={buttonVariants.OUTLINED}
        onClick={props?.onClose}
        style={{ marginRight: "24px" }}
      >
        {t("common.cancel")}
      </Button>
      <Button
        variant={buttonVariants.CONTAINED}
        disabled={props?.disabled}
        onClick={props?.onAction}
        style={{
          backgroundColor: props?.disabled
            ? ""
            : selectedTheme.colors.primaryBlue,
        }}
      >
        {t(props?.actionBtnText)}
      </Button>
    </RowContainer>
  );
};

ModalActions.propTypes = {
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  disabled: PropTypes.bool,
  actionBtnText: PropTypes.string,
};

ModalActions.defaultProps = {
  disabled: false,
  actionBtnText: "common.save",
};

export default ModalActions;