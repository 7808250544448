import { Box } from "@mui/material";
import styled from "styled-components";

export const PartnerCardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
`;

export const PartnerCardInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 24px;
  @media (max-width: 960px) {
    padding-right: 0;
    padding-bottom: 24px;
  }
`;
