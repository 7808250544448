/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { openModal } from "features/modal/modalSlice";
import { useDispatch } from "react-redux";
import { arrayToObject } from "util/helpers/arrayToObject";
import modalConstants from "constants/modalConstants";
import CreateIcon from "components/Icon/Icons/CreateIcon";

const useClearingsTable = ({ page, rowsPerPage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const columns = [
    {
      name: "",
      label: t("tableCols.clearing.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "receiptId",
      label: t("tableCols.clearing.receiptLabel"),
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "receiptNumber",
      label: t("tableCols.clearing.receiptLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "statementId",
      label: t("tableCols.clearing.statementLabel"),
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "statementNumber",
      label: t("tableCols.clearing.statementLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "originalAmount",
      label: t("tableCols.clearing.totalAmountLabel"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          return _value.toFixed(2);
        },
      },
    },
    {
      name: "domesticAmount",
      label: t("tableCols.clearing.domesticCurrencyLabel"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          return _value.toFixed(2);
        },
      },
    },
    {
      name: "foreignAmount",
      label: t("tableCols.clearing.foreignCurrencyInDomesticLabel"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          return _value.toFixed(2);
        },
      },
    },
    {
      name: "exchangeRate",
      label: t("tableCols.clearing.exchangeRateLabel"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          return _value?.toFixed(2);
        },
      },
    },
    {
      name: "foreignAmountInDomesticCurrency",
      label: t("tableCols.clearing.foreignCurrencyLabel"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return rowData.exchangeRate == 0
            ? 0
            : (rowData.foreignAmount / rowData.exchangeRate).toFixed(2);
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip
                title={t("clearing.editLabel")}
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: modalConstants.EDIT_CLEARING_MODAL,
                        data: rowData,
                      })
                    );
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useClearingsTable;
