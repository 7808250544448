/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";
import CreateIcon from "components/Icon/Icons/CreateIcon";
import RemoveCircleIcon from "components/Icon/Icons/RemoveCircleIcon";
import HomeIcon from "components/Icon/Icons/HomeIcon";

const useCodebookTable = ({
  type,
  page,
  rowsPerPage,
  isChangeAllowed = true,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label:
        type === "accommodation"
          ? "Naziv objekta"
          : type === "location"
          ? "Mesto"
          : "Naziv",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "accommodationType",
      label: "Tip objekta",
      options: {
        filter: false,
        sort: false,
        display: type === "accommodation",
      },
    },
    {
      name: "country",
      label: "Država",
      options: {
        filter: false,
        sort: false,
        display: type === "accommodation" || type === "location",
      },
    },
    {
      name: "location",
      label: "Mesto",
      options: {
        filter: false,
        sort: false,
        display: type === "accommodation",
      },
    },
    {
      name: "offerDetailsLevelId",
      label: "Nivo detalja ponude",
      options: {
        filter: false,
        sort: false,
        display: type === "offerType",
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          if (!isChangeAllowed) return null;
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              {type === "accommodation" && (
                <Tooltip
                  title="Definiši objekat"
                  placement="top"
                  style={{ marginRight: 5 }}
                >
                  <IconButton
                    onClick={() => {
                      navigate(`/accommodation/${rowData.id}`);
                    }}
                  >
                    <HomeIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                title="Izmeni"
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: modalConstants.EDIT_CODEBOOK_MODAL,
                        data: rowData,
                      })
                    );
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Obriši" placement="top">
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: modalConstants.DELETE_CODEBOOK_MODAL,
                        data: { id: rowData.id },
                      })
                    );
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useCodebookTable;
