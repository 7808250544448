import { Breadcrumbs } from "@mui/material";
import AccommodationUnitForm from "components/Forms/AccommodationUnitForm/AccommodationUnitForm";
import Title from "components/Title/Title";
import { PAGES } from "constants/pages";
import { useAccommodationDetailsQuery } from "features/accommodation/accommodationApiSlice";
import {
  useAddAccommodationUnitMutation,
  useEditAccommodationUnitMutation,
  useAccommodationUnitDetailsQuery,
} from "features/accommodationUnit/accommodationUnitApiSlice";
import { useFormik } from "formik";
import accommodationUnitInitialValues from "initialValues/accommodationUnitInitialValues";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import accommodationUnitValidation from "validations/accommodationUnitValidation";

const AddEditAccommodationUnitPage = () => {
  const { t } = useTranslation();
  const { id, accommodationUnitId } = useParams();
  const path = useLocation();
  const isAdd =
    path.pathname === PAGES.ADDACCOMMODATIONUNIT.route.replace(":id", id);
  const navigate = useNavigate();

  const { data: accommodation, isLoading: isLoadingAccommodation } =
    useAccommodationDetailsQuery({
      id,
    });

  const {
    data: singleAccommodationUnit,
    isLoading: isLoadingSingleAccommodationUnit,
  } = useAccommodationUnitDetailsQuery(
    { id: accommodationUnitId },
    { skip: !accommodationUnitId }
  );

  const [addAccommodationUnit, { isLoading: isLoadingAddAccommodationUnit }] =
    useAddAccommodationUnitMutation();

  const [editAccommodationUnit, { isLoading: isLoadingEditAccommodationUnit }] =
    useEditAccommodationUnitMutation();

  const handleSubmit = (val) => {
    if (isAdd) {
      addAccommodationUnit({ ...val, accommodationId: Number(id) })
        .unwrap()
        .then(() => {
          if (!isLoadingAddAccommodationUnit) {
            formik.resetForm();
            makeToastMessage(t("accommodationUnit.addSuccessMessage"));
            navigate(`/accommodation/${id}`);
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("accommodationUnit.addErrorMessage")
          )
        );
    }

    if (!isAdd) {
      editAccommodationUnit({ ...val, accommodationId: Number(id) })
        .unwrap()
        .then(() => {
          if (!isLoadingEditAccommodationUnit) {
            formik.resetForm();
            makeToastMessage(t("accommodationUnit.editSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("accommodationUnit.editErrorMessage")
          )
        );
    }
  };

  const transformUnitProperties = (unitProperties) => {
    return unitProperties?.map((property) => {
      if (typeof property === "object") {
        return property?.id;
      }
      return property;
    });
  };

  const detailAccommodationUnitValues = {
    ...singleAccommodationUnit,
    unitProperties: transformUnitProperties(
      singleAccommodationUnit?.unitProperties
    ),
  };

  const formik = useFormik({
    initialValues: accommodationUnitId
      ? isLoadingSingleAccommodationUnit
        ? accommodationUnitInitialValues
        : singleAccommodationUnit && detailAccommodationUnitValues
      : accommodationUnitInitialValues,
    validationSchema: accommodationUnitValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <PageContainer>
      <Title
        title={
          accommodationUnitId && !isLoadingAccommodation
            ? t("accommodationUnit.editTitle", {
                name: accommodation?.name,
              })
            : t("accommodationUnit.addTitle", {
                name: accommodation?.name,
              })
        }
      />
      <Breadcrumbs />
      <AccommodationUnitForm formik={formik} accommodationId={id} />
    </PageContainer>
  );
};

export default AddEditAccommodationUnitPage;
