/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomSelectFilter from "../CustomSelectFilter";
import DateFilter from "../DateFilter";
import inputTypes from "constants/inputTypes";
import CustomTextFilter from "../CustomTextFilter";
import CustomAutoCompleteFilter from "../CustomAutoCompleteFilter";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import { useAgencyDropdownQuery } from "features/agency/agencyApiSlice";
import { useClientDropdownQuery } from "features/client/clientApiSlice";

const ContractFilter = ({ filterForm, appliedFilters }) => {
  const { t } = useTranslation();
  const resetCountryFilterValue = () => {
    filterForm.setFieldValue("CountryId", "");
    filterForm.setFieldValue("LocationId", "");
  };
  const contractTypes = [
    { value: 1, text: t("common.internalSale") },
    { value: 2, text: t("common.externalSale") },
  ];

  return (
    <Box component="form">
      <RowContainer>
        <CustomTextFilter
          name="Number"
          type={inputTypes.NUMBER}
          label={t("field.contractNumberPlaceholder")}
          filterForm={filterForm}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <DateFilter
          name="DepartureDate"
          label={t("field.travelDatePlaceholder")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="ContractType"
          label={t("field.contractType")}
          filterForm={filterForm}
          items={contractTypes}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <CustomAutoCompleteFilter
          name="OrganizerId"
          label={t("field.travelOrganizerPlaceholder")}
          filterForm={filterForm}
          useQuery={() => useAgencyDropdownQuery({ active: true })}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomAutoCompleteFilter
          name="CountryId"
          label={t("field.countryPlaceholder")}
          filterForm={filterForm}
          useQuery={useCountryDropdownQuery}
          resetFilterValue={resetCountryFilterValue}
          appliedFilters={appliedFilters}
          style={{ marginRight: "24px" }}
        />
        <CustomAutoCompleteFilter
          name="LocationId"
          label={t("field.locationPlaceholder")}
          filterForm={filterForm}
          disabled={!filterForm.values.CountryId}
          appliedFilters={appliedFilters}
          useQuery={() =>
            useLocationDropdownQuery(
              { id: filterForm.values.CountryId },
              { skip: !filterForm.values.CountryId }
            )
          }
        />
      </RowContainer>
      <RowContainer>
        <CustomAutoCompleteFilter
          name="ClientId"
          label={t("field.clientFilter")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
          useQuery={useClientDropdownQuery}
        />
      </RowContainer>
    </Box>
  );
};

export default ContractFilter;
