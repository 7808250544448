/* eslint-disable react/prop-types */
import { useFormik } from "formik";
import agencySettingsInitialValues from "initialValues/agencySettingsInitialValues";
import React, { useEffect, useState } from "react";
import {
  useAddLogoMutation,
  useAddSignatureMutation,
  useAgencyDetailsQuery,
  useDeleteAgencyLogoMutation,
  useDeleteSignatureMutation,
  useEditAgencyMutation,
  useGetAgencyLogoQuery,
  useGetAgencySignatureQuery,
} from "features/agency/agencyApiSlice";
import { useParams } from "react-router-dom";
import { ContentContainer } from "styles/SharedStyle.styled";
import AgencyForm from "components/Forms/AgencyForm/AgencyForm";
import { convertDateToISO } from "util/dateHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import ptpAgencySettingsValidation from "validations/ptpAgencySettingsValidation";
import otpAgencyValidation from "validations/otpAgencyValidation";
import { useTranslation } from "react-i18next";
import { PTP_TYPE } from "constants/agencyType";
import BackdropComponent from "components/Backdrop/BackdropComponent";
import Error from "pages/ErrorPage/ErrorPage";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";

const AgencyTab = () => {
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const deleteLogoModal = modals?.[modalConstants.DELETE_LOGO_MODAL];
  const deleteSignatureModal = modals?.[modalConstants.DELETE_SIGNATURE_MODAL];
  const { t } = useTranslation();
  const { id } = useParams();
  const [agencyType, setAgencyType] = useState(null);

  const { data, isLoading, error, isError } = useAgencyDetailsQuery({ id });
  const { data: agencyLogo, isLoading: isLoadingAgencyLogo } =
    useGetAgencyLogoQuery({ agencyId: id }, { skip: isError });
  const { data: agencySignature, isLoading: isLoadingSignature } =
    useGetAgencySignatureQuery({ agencyId: id }, { skip: isError });
  const [addLogo, { isLoading: isLoadingAddLogo }] = useAddLogoMutation();
  const [addSignature, { isLoading: isLoadingAddSignature }] =
    useAddSignatureMutation();
  const [deleteLogo, { isLoading: isLoadingDeleteLogo }] =
    useDeleteAgencyLogoMutation();
  const [deleteSignature, { isLoading: isLoadingDeleteSignature }] =
    useDeleteSignatureMutation();

  const [editAgency, { isLoading: isLoadingUpdateAgency }] =
    useEditAgencyMutation();

  const [agencyLogoImage, setAgencyLogoImage] = useState(
    agencyLogo?.data !== null ? [agencyLogo?.data] : null
  );

  const [agencySignatureImage, setAgencySignatureImage] = useState(
    agencySignature?.data !== null ? [agencySignature?.data] : null
  );

  useEffect(() => {
    if (!isLoadingAgencyLogo) {
      setAgencyLogoImage(agencyLogo?.data !== null ? [agencyLogo?.data] : null);
    }
  }, [isLoadingAgencyLogo]);

  useEffect(() => {
    if (!isLoadingSignature) {
      setAgencySignatureImage(
        agencySignature?.data !== null ? [agencySignature?.data] : null
      );
    }
  }, [isLoadingSignature]);

  const handleAgencyLogoImage = (image) => {
    setAgencyLogoImage(image);
    addLogo({ id, data: image[0] })
      .unwrap()
      .then(() => {
        makeToastMessage(t("agency.editLogoSuccessMessage"));
      })
      .catch((e) =>
        makeErrorToastMessage(
          e.data.message || t("agency.editLogoErrorMessage")
        )
      );
  };

  const handleSignatureImage = (image) => {
    setAgencySignatureImage(image);
    addSignature({ id, data: image[0] })
      .unwrap()
      .then(() => {
        makeToastMessage(t("agency.editSignatureSuccessMessage"));
      })
      .catch((e) =>
        makeErrorToastMessage(
          e.data.message || t("agency.editSignatureErrorMessage")
        )
      );
  };

  const handleDeleteLogo = () => {
    dispatch(openModal({ id: modalConstants.DELETE_LOGO_MODAL }));
  };

  const handleDeleteSignature = () => {
    dispatch(openModal({ id: modalConstants.DELETE_SIGNATURE_MODAL }));
  };

  const handleSubmit = async (values) => {
    editAgency({
      ...values,
      licenceNumberExpirationDate:
        agencyType === PTP_TYPE
          ? null
          : convertDateToISO(values.licenceNumberExpirationDate),
      licenceNumber: agencyType === PTP_TYPE ? null : values.licenceNumber,
    })
      .unwrap()
      .then(() => {
        if (!isLoadingUpdateAgency) {
          makeToastMessage(t("agency.editSuccessMessage"));
        }
      })
      .catch((e) =>
        makeErrorToastMessage(e.data.message || t("agency.editErrorMessage"))
      );
  };

  const formik = useFormik({
    initialValues: !isLoading ? data : agencySettingsInitialValues,
    validationSchema:
      agencyType === PTP_TYPE
        ? ptpAgencySettingsValidation
        : otpAgencyValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleClose = () => {
    dispatch(closeModal({ id: modalConstants.DELETE_IMAGE_MODAL }));
  };

  const handleDelete = () => {
    if (deleteLogoModal?.open) {
      deleteLogo({ agencyId: id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteLogo) {
            makeToastMessage(t("agency.deleteLogoSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("agency.deleteLogoErrorMessage")
          )
        );
      dispatch(closeModal({ id: modalConstants.DELETE_LOGO_MODAL }));
      setAgencyLogoImage(null);
    }

    if (deleteSignatureModal?.open) {
      deleteSignature({ agencyId: id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteSignature) {
            makeToastMessage(t("agency.deleteSignatureSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("agency.deleteSignatureErrorMessage")
          )
        );
      dispatch(closeModal({ id: modalConstants.DELETE_SIGNATURE_MODAL }));
      setAgencySignatureImage(null);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setAgencyType(data?.agencyType);
    } else {
      setAgencyType(null);
    }
  }, [formik, isLoading]);

  if (isLoading) {
    return <BackdropComponent position="absolute" isLoading={isLoading} />;
  }

  if (error) {
    if (error.status === 400) {
      return <Error />;
    }
  }

  return (
    <ContentContainer component="form" onSubmit={formik.handleSubmit}>
      <ConfirmModal
        open={deleteSignatureModal?.open || deleteLogoModal?.open}
        handleConfirm={handleDelete}
        handleCancel={handleClose}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("users.confirmDeleteImage")}
      />
      <AgencyForm
        formik={formik}
        setAgencyLogoImage={handleAgencyLogoImage}
        setSignatureImage={handleSignatureImage}
        agencyLogoImage={agencyLogoImage}
        signatureImage={agencySignatureImage}
        isLoadingSignature={
          isLoadingSignature ||
          isLoadingAddSignature ||
          isLoadingDeleteSignature
        }
        isLoadingLogo={
          isLoadingAgencyLogo || isLoadingAddLogo || isLoadingDeleteLogo
        }
        handleDeleteLogo={handleDeleteLogo}
        handleDeleteSignature={handleDeleteSignature}
      />
    </ContentContainer>
  );
};

export default AgencyTab;
