import React from "react";

import CheckIconMui from "@mui/icons-material/Check";
import styled from "styled-components";

const CheckIconMuiStyled = styled(CheckIconMui)`
  fill: ${(props) => props?.theme?.colors?.iconColor};
`;

const CheckIcon = (props) => {
  return <CheckIconMuiStyled {...props} />;
};

CheckIcon.propTypes = {};

export default CheckIcon;
