import { Button } from "@mui/material";
import Codebooks from "components/Codebooks/Codebooks";
import CustomTable from "components/CustomTable/CustomTable";
import Filter from "components/Filter/Filter";
import LocationFilter from "components/Filter/LocationFilter/LocationFilter";
import Title from "components/Title/Title";
import buttonVariants from "constants/buttonVariants";
import modalConstants from "constants/modalConstants";
import {
  useAddLocationMutation,
  useDeleteLocationMutation,
  useEditLocationMutation,
  useLocationDetailsQuery,
  useLocationListQuery,
} from "features/location/locationApiSlice";
import { closeModal, openModal } from "features/modal/modalSlice";
import { useFormik } from "formik";
import useCodebookTable from "hooks/tables/useCodebookTable";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader, PageContainer } from "styles/SharedStyle.styled";
import selectedTheme from "themes";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import * as Yup from "yup";

const LocationPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const addModal = modals?.[modalConstants.ADD_CODEBOOK_MODAL];
  const editModal = modals?.[modalConstants.EDIT_CODEBOOK_MODAL];
  const deleteModal = modals?.[modalConstants.DELETE_CODEBOOK_MODAL];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState({ name: "name", direction: "asc" });
  const { columns } = useCodebookTable({
    type: "location",
    page,
    rowsPerPage,
  });

  const { data: locations, isLoading } = useLocationListQuery({
    page,
    rowsPerPage,
    sort,
    filter,
  });
  const [addLocation, { isLoading: isLoadingAddLocation }] =
    useAddLocationMutation();

  const [editLocation, { isLoading: isLoadingEditLocation }] =
    useEditLocationMutation();

  const [deleteLocation, { isLoading: isLoadingDeleteLocation }] =
    useDeleteLocationMutation();

  const { data: singleLocationData, isLoading: isLoadingSingleLocation } =
    useLocationDetailsQuery(
      { id: editModal?.data.id },
      { skip: !editModal?.open }
    );

  const handleSubmit = async (val) => {
    if (addModal?.open) {
      try {
        const { name, countryId } = val;
        await addLocation({ name, countryId })
          .unwrap()
          .then(() => {
            if (!isLoadingAddLocation) {
              formik.resetForm();
              dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
              makeToastMessage(t("location.addSuccessMessage"));
            }
          });
      } catch (e) {
        makeErrorToastMessage(e.data.message);
      }
    }

    if (editModal?.open) {
      try {
        const { id, name, countryId } = val;
        await editLocation({ name, id, countryId })
          .unwrap()
          .then(() => {
            if (!isLoadingEditLocation) {
              formik.resetForm();
              dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
              makeToastMessage(t("location.editSuccessMessage"));
            }
          });
      } catch (e) {
        makeErrorToastMessage(e.data.message);
      }
    }
  };

  const handleConfirmDelete = () => {
    if (deleteModal?.open) {
      deleteLocation({ id: deleteModal?.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteLocation) {
            dispatch(closeModal({ id: modalConstants.DELETE_CODEBOOK_MODAL }));
            makeToastMessage(t("location.deleteSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("location.deleteErrorMessage")
          )
        );
    }
  };

  const [appliedFilters, setAppliedFilters] = useState({});
  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
    setAppliedFilters(getNonEmptyProps(values));
  };

  const formik = useFormik({
    initialValues: editModal?.open
      ? isLoadingSingleLocation
        ? { name: "", countryId: null }
        : singleLocationData
      : { name: "", countryId: null },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.locationRequired")),
      countryId: Yup.number().required(t("field.countryIdRequired")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  const filterForm = useFormik({
    initialValues: { CountryId: "", Name: "" },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOpenAdd = () => {
    dispatch(openModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
  };

  return (
    <PageContainer>
      <Codebooks
        formik={formik}
        editTitle={t("location.editTitle")}
        addTitle={t("location.addTitle")}
        labelTitle={t("field.locationIdPlaceholder")}
        confirmCloseDescription={t("location.confirmClose")}
        confirmDeleteDescription={t("location.confirmDelete")}
        handleConfirmDelete={handleConfirmDelete}
        isLoadingDelete={isLoadingDeleteLocation}
        type="location"
      />
      <Title title={t("location.pageTitle")} />
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <LocationFilter
            filterForm={filterForm}
            appliedFilters={appliedFilters}
          />
        </Filter>
        <Button
          variant={buttonVariants.CONTAINED}
          style={{ backgroundColor: selectedTheme.colors.primaryBlue }}
          onClick={handleOpenAdd}
        >
          {t("location.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={locations?.data}
        isLoading={isLoading}
        count={locations?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("location.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default LocationPage;
