/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog/CustomDialog";
import { RowContainer } from "styles/SharedStyle.styled";
import { useDispatch } from "react-redux";
import { closeModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";
import CustomTextInput from "components/Inputs/CustomTextInput";
import formikParams from "constants/formikParams";
import { useFormik } from "formik";
import { clearingInitialValues } from "initialValues/clearingInitialValues";
import clearingValidation from "validations/clearingValidation";
import { useEditClearingMutation } from "features/clearing/clearingApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import ModalActions from "../ModalActions/ModalActions";

const EditClearingModal = ({ open, clearing, currency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editClearing, { isLoading }] = useEditClearingMutation();
  const handleCloseEdit = () => {
    formik.resetForm();
    dispatch(closeModal({ id: modalConstants.EDIT_CLEARING_MODAL }));
  };

  const handleSubmit = async (values) => {
    const { domesticAmount, foreignAmount } = values;
    await editClearing({
      ...clearing,
      domesticAmount: parseFloat(domesticAmount),
      foreignAmount: parseFloat(foreignAmount),
    })
      .unwrap()
      .then(() => {
        makeToastMessage(t("clearing.clearingEditSuccess"));
      })
      .catch((e) =>
        makeErrorToastMessage(e.data.message || t("clearing.clearingEditFail"))
      );
    dispatch(closeModal({ id: modalConstants.EDIT_CLEARING_MODAL }));
  };
  const formik = useFormik({
    initialValues: clearingInitialValues(clearing),
    validationSchema: clearingValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  const handleFieldChange =
    (
      formik,
      changedField,
      dependentField,
      totalField,
      foreignCurrencyField,
      exchangeRateField
    ) =>
    (e) => {
      const value = parseFloat(e.target.value) || 0;
      const total = parseFloat(formik.values[totalField]) || 0;
      const exchangeRate = parseFloat(formik.values[exchangeRateField]) || 0;

      formik.setFieldValue(changedField, e.target.value);
      const newDependentValue = total - value;
      formik.setFieldValue(
        dependentField,
        newDependentValue > 0 ? newDependentValue : 0
      );

      const foreignAmount =
        changedField === formikParams.FOREIGN_AMOUNT
          ? value
          : newDependentValue;
      const foreignCurrencyValue =
        exchangeRate > 0 ? foreignAmount / exchangeRate : 0;
      formik.setFieldValue(
        foreignCurrencyField,
        foreignCurrencyValue.toFixed(2)
      );
    };

  return (
    <CustomDialog
      open={open}
      handleClose={handleCloseEdit}
      handleCloseBtn={handleCloseEdit}
      title={t("clearing.editModal.title")}
    >
      <Box component="form" onSubmit={formik.handleSubmit}>
        <RowContainer>
          <CustomTextInput
            fullWidth
            name={formikParams.DOMESTIC_AMOUNT}
            label={t("field.clearingDinarPartRequired")}
            formik={{
              ...formik,
              handleChange: handleFieldChange(
                formik,
                formikParams.DOMESTIC_AMOUNT,
                formikParams.FOREIGN_AMOUNT,
                formikParams.TOTAL_AMOUNT,
                formikParams.FOREIGN_AMOUNT_IN_FOREIGN_CURRENCY,
                formikParams.EXCHANGE_RATE
              ),
            }}
            style={{ marginRight: "24px" }}
          />
          <CustomTextInput
            fullWidth
            name={formikParams.FOREIGN_AMOUNT}
            label={t("field.clearingForeignExchangePartRequired")}
            formik={{
              ...formik,
              handleChange: handleFieldChange(
                formik,
                formikParams.FOREIGN_AMOUNT,
                formikParams.DOMESTIC_AMOUNT,
                formikParams.TOTAL_AMOUNT,
                formikParams.FOREIGN_AMOUNT_IN_FOREIGN_CURRENCY,
                formikParams.EXCHANGE_RATE
              ),
            }}
            style={{ marginRight: "24px" }}
          />
          <CustomTextInput
            fullWidth
            name={formikParams.TOTAL_AMOUNT}
            label={t("field.clearingTotalAmount")}
            formik={formik}
            disabled
          />
        </RowContainer>
        <RowContainer>
          <CustomTextInput
            fullWidth
            name={formikParams.EXCHANGE_RATE}
            label={t("field.clearingExchangeRate")}
            formik={formik}
            style={{ marginRight: "24px" }}
            disabled
          />
          <CustomTextInput
            fullWidth
            name={formikParams.FOREIGN_AMOUNT_IN_FOREIGN_CURRENCY}
            label={t("field.clearingForeignExchangePartInForeignCurrencies")}
            formik={formik}
            style={{ marginRight: "24px" }}
            disabled
          />
          <CustomTextInput
            fullWidth
            name={formikParams.CURRENCY_ID}
            label={t("field.clearingCurrency")}
            value={currency.toUpperCase()}
            disabled
          />
        </RowContainer>
        <ModalActions
          onClose={handleCloseEdit}
          disabled={!formik.dirty || isLoading}
          onAction={formik.handleSubmit}
        />
      </Box>
    </CustomDialog>
  );
};

export default EditClearingModal;
