import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddEditCodebookItemModal from "components/Modals/AddEditCodebookItemModal/AddEditCodebookItemModal";
import { useAddLocationMutation } from "features/location/locationApiSlice";
import CustomAutoCompleteLocations from "./CustomAutocompleteLocations";

const LocationSelect = (props) => {
  const { t } = useTranslation();
  const addButtonText = t("location.addButton");
  const [
    createLocation,
    { isLoading: isLoadingCreateLocation },
  ] = useAddLocationMutation();
  const [openAdd, setOpenAdd] = useState(false);

  const handleSubmit = async (values) => {
    try {
      await createLocation(values).unwrap();
      if (!isLoadingCreateLocation) {
        addLocationFormik.resetForm();
        setOpenAdd(false);
        makeToastMessage(t("location.addSuccessMessage"));
      }
    } catch (e) {
      makeErrorToastMessage(e.data.message);
    }
  };

  const addLocationFormik = useFormik({
    initialValues: { name: "", countryId: props?.countryId },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.locationIdRequired")),
      countryId: Yup.string().required(t("field.countryIdRequired")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleClose = () => {
    setOpenAdd(false);
    addLocationFormik.resetForm();
  };
  return (
    <>
      <AddEditCodebookItemModal
        open={openAdd}
        handleClose={handleClose}
        handleCloseBtn={handleClose}
        formik={addLocationFormik}
        title={t("location.addTitle")}
        labelTitle={t("field.locationIdPlaceholder")}
        type="location"
      />
      <CustomAutoCompleteLocations
        name={props?.name}
        label={props?.label}
        style={props?.style}
        value={props?.value}
        formik={props?.formik}
        addButtonText={addButtonText}
        addButtonClick={() => setOpenAdd(true)}
        hasAdd={props?.hasAdd}
        disabled={props?.disabled}
        countryId={props?.countryId}
      />
    </>
  );
};

LocationSelect.defaultProps = {
  hasAdd: true,
  disabled: false,
};

LocationSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  formik: PropTypes.string,
  setPassedAddressFlag: PropTypes.func,
  hasAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  countryId: PropTypes.string
};

export default LocationSelect;
