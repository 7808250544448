export default {
  EMAIL: "email",
  PASSWORD: "password",
  OLD_PASSWORD: "oldPassword",
  NEW_PASSWORD: "newPassword",
  CONFIRM_PASSWORD: "confirmPassword",
  DOMESTIC_AMOUNT: "domesticAmount",
  FOREIGN_AMOUNT: "foreignAmount",
  TOTAL_AMOUNT: "totalAmount",
  EXCHANGE_RATE: "exchangeRate",
  FOREIGN_AMOUNT_IN_FOREIGN_CURRENCY:
    "foreignAmountInForeignCurrency",
  CURRENCY_ID: "currencyId",
};
