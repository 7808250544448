import React from "react";

import DeleteIconMui from "@mui/icons-material/Delete";
import styled from "styled-components";

const DeleteIconMuiStyled = styled(DeleteIconMui)`
  fill: ${(props) => props?.theme?.colors?.iconColor};
`;

const DeleteIcon = (props) => {
  return <DeleteIconMuiStyled {...props} />;
};

DeleteIcon.propTypes = {};

export default DeleteIcon;
