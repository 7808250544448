import { apiSlice } from "features/api/apiSlice";

export const invoiceItemApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["InvoiceItem", "InvoiceItemSingle"],
  endpoints: (builder) => ({
    invoiceItemDropdown: builder.query({
      query: () => ({
        url: "/invoice-item/dropdown",
      }),
      providesTags: ["InvoiceItem"],
    }),
    invoiceItemList: builder.query({
      query: (args) => {
        const { invoiceId } = args;
        return {
          url:
            "/invoice-item/list?" +
            new URLSearchParams({
              InvoiceId: invoiceId,
              Active: true,
            }),
        };
      },
      providesTags: ["InvoiceItem"],
    }),
    invoiceItemDetails: builder.query({
      query: ({ id }) => ({
        url: `/invoice-item/${id}`,
      }),
      providesTags: ["InvoiceItemSingle"],
    }),
    saveInvoiceItem: builder.mutation({
      query: (data) => ({
        url: "/invoice-item/save",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["InvoiceItem"],
    }),
  }),
});

export const {
  useInvoiceItemDropdownQuery,
  useInvoiceItemListQuery,
  useInvoiceItemDetailsQuery,
  useSaveInvoiceItemMutation,
  useEditInvoiceItemMutation,
  useDeleteInvoiceItemMutation,
} = invoiceItemApiSlice;
