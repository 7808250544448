/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { CONTRACT_CLEARING, EDIT_CONTRACT } from "constants/contractState";
import { PAGES } from "constants/pages";
import CreateIcon from "components/Icon/Icons/CreateIcon";
import PointOfSaleIcon from "components/Icon/Icons/PointOfSaleIcon";

const useContractTable = ({ page, rowsPerPage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const columns = [
    {
      name: "",
      label: t("tableCols.contracts.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "contractTypeId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "number",
      label: t("tableCols.contracts.contractNumberLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "createdBy",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "date",
      label: t("tableCols.contracts.contractDateLabel"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return (
            <div>
              <div>{format(new Date(rowData.date), "dd.MM.yyyy.")}</div>
              <div>{rowData.createdBy}</div>
            </div>
          );
        },
      },
    },
    {
      name: "contractType",
      label: t("tableCols.contracts.contractTypeLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "client",
      label: t("tableCols.contracts.clientLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "otp",
      label: t("tableCols.contracts.travelOrganizerLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "country",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "location",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "destination",
      label: t("tableCols.contracts.destinationLabel"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return `${rowData.country || ""}${!rowData.country ? "" : ", "} ${
            rowData.location || ""
          }`;
        },
      },
    },
    {
      name: "departureDate",
      label: t("tableCols.contracts.departureDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return format(new Date(rowData.departureDate), "dd.MM.yyyy.");
        },
      },
    },
    {
      name: "updatedOn",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "updatedBy",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "updatedDetails",
      label: t("tableCols.contracts.updated"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return (
            <div>
              <div>{format(new Date(rowData.updatedOn), "dd.MM.yyyy.")}</div>
              <div>{rowData.updatedBy}</div>
            </div>
          );
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip
                title={t("contract.detailsLabel")}
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    if (rowData.contractTypeId === 1) {
                      navigate(
                        PAGES.EDITINTERNALCONTRACT.route.replace(
                          ":id",
                          rowData.id
                        )
                      );
                    }

                    if (rowData.contractTypeId === 2) {
                      navigate(
                        PAGES.EDITEXTERNALCONTRACT.route.replace(
                          ":id",
                          rowData.id
                        )
                      );
                    }
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={t("contract.clearingsLabel")}
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    navigate(`/${CONTRACT_CLEARING}/${rowData.id}`);
                  }}
                >
                  <PointOfSaleIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useContractTable;
