import { Box, Button, Typography } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import buttonVariants from "constants/buttonVariants";
import modalConstants from "constants/modalConstants";
import { PAGES } from "constants/pages";
import {
  useAccommodationUnitListQuery,
  useDeleteAccommodationUnitMutation,
} from "features/accommodationUnit/accommodationUnitApiSlice";
import { closeModal } from "features/modal/modalSlice";
import useAccommodationUnitTable from "hooks/tables/useAccommodationUnitTable";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import selectedTheme from "themes";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";

const AccommodationUnitPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let params = useParams();
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const deleteModal = modals?.[modalConstants.DELETE_CODEBOOK_MODAL];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState({ name: "name", direction: "asc" });

  const { data: accommodationUnits, isLoading: isLoadingAccommodationUnits } =
    useAccommodationUnitListQuery({
      accommodationId: params?.id,
      page,
      rowsPerPage,
      sort,
    });

  const [
    deleteAccommodationUnit,
    { isLoading: isLoadingDeleteAccommodationUnit },
  ] = useDeleteAccommodationUnitMutation();

  const { columns } = useAccommodationUnitTable({
    page,
    rowsPerPage,
    accommodationId: params?.id,
  });

  const handleConfirmDelete = () => {
    if (deleteModal?.open) {
      deleteAccommodationUnit({ id: deleteModal?.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteAccommodationUnit) {
            dispatch(closeModal({ id: modalConstants.DELETE_CODEBOOK_MODAL }));
            makeToastMessage(t("accommodationUnit.deleteSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("accommodationUnit.deleteErrorMessage")
          )
        );
    }
  };

  const handleCancelDelete = () => {
    dispatch(closeModal({ id: modalConstants.DELETE_CODEBOOK_MODAL }));
  };

  return (
    <PageContainer>
      <ConfirmModal
        open={deleteModal?.open}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleCancelDelete}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("accommodationUnit.confirmDelete")}
        isConfirmDisabled={isLoadingDeleteAccommodationUnit}
      />
      <Typography variant="h4" color="" style={{ marginBottom: 24 }}>
        {t("accommodationUnit.pageTitle", {
          name: accommodationUnits?.parentItemName,
        })}
      </Typography>
      <PageHeader>
        <Box />
        <Button
          variant={buttonVariants.CONTAINED}
          onClick={() =>
            navigate(
              PAGES.ADDACCOMMODATIONUNIT.route.replace(":id", params?.id)
            )
          }
          style={{ backgroundColor: selectedTheme.colors.primaryBlue }}
        >
          {t("accommodationUnit.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={accommodationUnits?.data}
        isLoading={isLoadingAccommodationUnits}
        count={accommodationUnits?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("accommodationUnit.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default AccommodationUnitPage;
