import React from "react";

import HomeIconMui from "@mui/icons-material/Home";
import styled from "styled-components";

const HomeIconMuiStyled = styled(HomeIconMui)`
  fill: ${(props) => props?.theme?.colors?.iconColor};
`;

const HomeIcon = (props) => {
  return <HomeIconMuiStyled {...props} />;
};

HomeIcon.propTypes = {};

export default HomeIcon;
