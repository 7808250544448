/* eslint-disable react/prop-types */
import { Box, Button, IconButton } from "@mui/material";
import DatePick from "components/Inputs/DatePick";
import LocationSelect from "components/Inputs/LocationSelect";
import TextInput from "components/Inputs/TextInput";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  useClientDetailsQuery,
  useClientDropdownQuery,
} from "features/client/clientApiSlice";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import PassengerSelect from "components/Inputs/PassengerSelect";
import selectedTheme from "themes";
import buttonVariants from "constants/buttonVariants";

const PassengerForm = ({ formik }) => {
  const { t } = useTranslation();
  const [passengerId, setPassengerId] = useState(null);
  const [passengerIndex, setPassengerIndex] = useState(null);
  const { data: clients } = useClientDropdownQuery();

  const { data: passengersData } = useClientDetailsQuery(
    { id: passengerId },
    { skip: !passengerId }
  );

  useEffect(() => {
    if (passengersData || passengerIndex) {
      const passenger = `contractTravelers.travelers[${passengerIndex}]`;
      formik.setFieldValue(`${passenger}.address`, passengersData?.address);
      formik.setFieldValue(`${passenger}.country`, passengersData?.countryId);
      formik.setFieldValue(`${passenger}.location`, passengersData?.locationId);
      formik.setFieldValue(
        `${passenger}.phoneNumber`,
        passengersData?.phoneNumber
      );
      formik.setFieldValue(
        `${passenger}.dateOfBirth`,
        passengersData?.dateOfBirth
      );
      formik.setFieldValue(
        `${passenger}.passportNumber`,
        passengersData?.passportNumber
      );
    }
  }, [passengersData, passengerIndex]);

  console.log("passengerData", passengersData);

  return (
    <>
      {formik.values?.contractTravelers?.travelers &&
        formik.values?.contractTravelers?.travelers?.map((item, index) => {
          return (
            <RowContainer
              key={index}
              sx={{ display: "flex", alignItems: "flex-start" }}
            >
              <TextInput
                fullWidth
                label="Redni broj"
                value={index + 1}
                style={{ marginRight: "24px" }}
                disabled
              />
              <PassengerSelect
                fullWidth
                name="clientId"
                label={t("field.passengerNamePlaceholderRequired")}
                items={clients}
                value={
                  formik.values?.contractTravelers?.travelers[index].clientId
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    `contractTravelers.travelers[${index}].clientId`,
                    e.target.value
                  );
                  setPassengerId(e.target.value);
                  setPassengerIndex(index);
                }}
                error={
                  formik.touched.contractTravelers?.travelers?.[index]?.clientId &&
                  Boolean(formik.errors.contractTravelers?.travelers?.[index]?.clientId)
                }
                helperText={
                  formik.touched.contractTravelers?.travelers?.[index]?.clientId &&
                  formik.errors.contractTravelers?.travelers?.[index]?.clientId
                }
                style={{ marginRight: "24px" }}
              />
              <TextInput
                fullWidth
                name="address"
                label={t("field.addressPlaceholder")}
                value={formik.values?.contractTravelers?.travelers[index].address || ""}
                style={{ marginRight: "24px" }}
                disabled
              />
              <LocationSelect
                formik={formik}
                name="location"
                label={t("field.locationIdPlaceholder")}
                value={formik.values?.contractTravelers?.travelers[index]?.location || ""}
                countryId={formik.values?.contractTravelers?.travelers[index]?.country || ""}
                style={{ marginRight: "24px" }}
                disabled
              />
              <TextInput
                fullWidth
                name="phoneNumber"
                label={t("field.phoneNumberPlaceholder")}
                value={formik.values?.contractTravelers?.travelers[index]?.phoneNumber || ""}
                style={{ marginRight: "24px" }}
                disabled
              />
              <DatePick
                name="dateOfBirth"
                label={t("field.dateOfBirthPlaceholder")}
                value={new Date(formik.values?.contractTravelers?.travelers[index]?.dateOfBirth) || null}
                disabled
                style={{ marginRight: "24px" }}
              />
              <TextInput
                fullWidth
                name="passportNumber"
                label={t("field.passportNumberPlaceholder")}
                value={formik.values?.contractTravelers?.travelers[index]?.passportNumber || ""}
                style={{ marginRight: "24px" }}
                disabled
              />
              <TextInput
                fullWidth
                name="departureLocation"
                label={t("field.departurePlacePlaceholder")}
                value={formik.values?.contractTravelers?.travelers[index]?.departureLocation || ""}
                style={{ marginRight: "24px" }}
                error={
                  formik.touched.contractTravelers?.travelers?.[index]?.departureLocation &&
                  Boolean(formik.errors.contractTravelers?.travelers?.[index]?.departureLocation)
                }
                helperText={
                  formik.touched.contractTravelers?.travelers?.[index]?.departureLocation &&
                  formik.errors.contractTravelers?.travelers?.[index]?.departureLocation
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    `contractTravelers.travelers[${index}].departureLocation`,
                    e.target.value
                  );
                }}
              />
              <IconButton
                color="error"
                onClick={() => {
                  if (formik.values.contractTravelers.travelers.length > 1) {
                    formik.setFieldValue(
                      "contractTravelers.travelers",
                      formik.values.contractTravelers.travelers.filter(
                        (_, i) => i !== index
                      ) // Remove the item
                    );
                  } else {
                    formik.setFieldValue("contractTravelers.travelers", [
                      {
                        contractTravelerId: 0,
                        clientId: "",
                        departureLocation: "",
                        travelArranger: false,
                        description: "",
                      },
                    ]);
                  }
                }}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </RowContainer>
          );
        })}
      <Box style={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          variant={buttonVariants.CONTAINED}
          color="primary"
          startIcon={<AddIcon />}
          onClick={() =>
            formik.setFieldValue("contractTravelers.travelers", [
              ...formik.values.contractTravelers.travelers,
              {
                contractTravelerId: 0,
                clientId: "",
                departureLocation: "",
                travelArranger: false,
                description: "",
              },
            ])
          }
          style={{
            backgroundColor: selectedTheme.colors.primaryBlue,
          }}
        >
          {t("singleContract.addPassenger")}
        </Button>
      </Box>
    </>
  );
};

export default PassengerForm;
