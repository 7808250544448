export default {
  number: "",
  issueDate: new Date(),
  location: "",
  address: "",
  transactionDate: new Date(),
  dueDate: null,
  contractNumber: "",
  description: "",
  currencyId: "",
  currencyName: "",
  rate: "",
  clientPartner: "",
  clientId: "",
  partnerId: "",
  totalAmount: "",
  charTotalAmount: "",
  note: "",
  clientPartnerName: "",
  clientPartnerAddress: "",
  clientPartnerLocationAndPostalCode: "",
  partnerPib: "",
  partnerLegalIdentificationNumber: "",
  items: [
    {
      description: "",
      price: "",
      quantity: 1,
      total: "",
    },
  ],
};
