/* eslint-disable react/prop-types */
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import ExternalTravelDetailsForm from "./ExternalTravelDetailsForm";
import ExternalCalculationForm from "./ExternalCalculationForm";
import Title from "components/Title/Title";
import ExternalBasicInformation from "./ExternalBasicInformation";
import ExternalDescription from "./ExternalDescription";
import ExternalPassenger from "./ExternalPassenger";
import buttonVariants from "constants/buttonVariants";
import inputTypes from "constants/inputTypes";
import selectedTheme from "themes";
import { openModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";
import tooltipPlacements from "constants/tooltipPlacements";
import EmailIcon from "@mui/icons-material/Email";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useDispatch, useSelector } from "react-redux";
import ContractStatus from "components/ContractStatus/ContractStatus";
import SelectInput from "components/Inputs/SelectInput";
import { useContractStatusDropdownQuery } from "features/contractStatus/contractStatusApiSlice";
import { selectCurrentToken } from "features/auth/authSlice";

const ExternalContractForm = ({
  formik,
  handleCancel,
  isAdd,
  isLoading,
  id,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);

  const { data: contractStatuses } = useContractStatusDropdownQuery();
  const handlePrintContract = async () => {
    // eslint-disable-next-line no-undef
    await fetch(`${process.env.REACT_APP_API_URI}/print/contract?Id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `Ugovor-${formik?.values?.number}-${Date.now()}.pdf`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      }); // eslint-disable-line
  };

  return (
    <Box component="form">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        style={{ marginBottom: 24 }}
      >
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Title
            style={{ marginBottom: 0 }}
            title={
              isAdd
                ? t("externalSale.addTitle")
                : t("externalSale.editTitle", { id: formik.values?.number })
            }
          />
          <ContractStatus status={isAdd ? 1 : formik.values.contractStatusId} />
        </Box>

        {!isAdd && (
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <SelectInput
              name="contractStatusId"
              size="small"
              style={{ minWidth: 200 }}
              label={t("externalSale.status")}
              onChange={formik.handleChange}
              value={formik.values.contractStatusId || ""}
              items={contractStatuses}
            />
            <Tooltip
              title={t("common.sendToEmail")}
              placement={tooltipPlacements.TOP}
            >
              <IconButton
                onClick={() => {
                  dispatch(
                    openModal({
                      id: modalConstants.SEND_CONTRACT_TO_EMAIL_MODAL,
                      data: {
                        clientId:
                          formik.values?.contractTravelers?.travelers?.find(
                            (x) => x.travelArranger === true
                          ).clientId,
                      },
                    })
                  );
                }}
              >
                <EmailIcon
                  style={{
                    width: 32,
                    height: 32,
                    color: selectedTheme.colors.background2,
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("common.pdf")} placement={tooltipPlacements.TOP}>
              <IconButton onClick={handlePrintContract}>
                <PictureAsPdfIcon
                  style={{
                    width: 32,
                    height: 32,
                    color: selectedTheme.colors.background2,
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <ExternalBasicInformation formik={formik} isAdd={isAdd} />
      <ExternalTravelDetailsForm formik={formik} />
      <ExternalPassenger formik={formik} />
      <ExternalCalculationForm formik={formik} />
      <ExternalDescription formik={formik} />
      <RowContainer
        style={{ marginBottom: 0, justifyContent: "center", marginTop: 24 }}
      >
        <Button
          variant={buttonVariants.OUTLINED}
          onClick={handleCancel}
          style={{ marginRight: "24px" }}
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant={buttonVariants.CONTAINED}
          type={inputTypes.SUBMIT}
          disabled={!formik.dirty || isLoading}
          onClick={formik.handleSubmit}
          style={{
            backgroundColor:
              !formik.dirty || isLoading
                ? ""
                : selectedTheme.colors.primaryBlue,
          }}
        >
          {t("common.reserve")}
        </Button>
      </RowContainer>
    </Box>
  );
};

export default ExternalContractForm;
