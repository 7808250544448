import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageContainer, RowContainer } from "styles/SharedStyle.styled";
import Title from "components/Title/Title";
import TabsComponent from "components/Tab/Tabs";
import ClearingsContentPage from "./ClearingsContentPage";
import TextInput from "components/Inputs/TextInput";
import { format } from "date-fns";
import {
  useClearingAmountsQuery,
  useClearingContractDetailsQuery,
} from "features/clearing/clearingApiSlice";
import Error from "pages/ErrorPage/ErrorPage";

const ClearingPage = () => {
  const { t } = useTranslation();
  const { contractId } = useParams();
  const {
    data: contract,
    isLoading: isLoadingContract,
    error,
  } = useClearingContractDetailsQuery({ id: contractId });
  const { data: amounts, isLoading: isLoadingAmounts } =
    useClearingAmountsQuery({ contractId: contractId });
  const tabs = [
    {
      label: t("clearing.tabs.clientTab"),
      component: (
        <ClearingsContentPage
          type="clients"
          contractId={contractId}
          currency={contract?.currency ?? ""}
        />
      ),
    },
    {
      label: t("clearing.tabs.partnerTab"),
      component: (
        <ClearingsContentPage
          type="partners"
          contractId={contractId}
          currency={contract?.currency ?? ""}
        />
      ),
    },
  ];
  if (error) {
    if (error.status === 400) {
      return <Error />;
    }
  }
  return (
    <PageContainer>
      <Title
        title={`${t("clearing.title")} ${
          isLoadingContract ? "" : contract?.number
        }`}
      />
      <RowContainer>
        <TextInput
          name="contractNumber"
          label={t("clearing.input.contractNumber")}
          style={{ marginRight: "24px" }}
          disabled
          fullWidth
          value={isLoadingContract ? "" : contract?.number}
        />
        <TextInput
          name="contractDate"
          label={t("clearing.input.contractDate")}
          style={{ marginRight: "24px" }}
          disabled
          fullWidth
          value={
            isLoadingContract
              ? ""
              : contract?.date
              ? format(new Date(contract.date), "dd.MM.yyyy.")
              : ""
          }
        />
        <TextInput
          name="client"
          label={t("clearing.input.client")}
          style={{ marginRight: "24px" }}
          disabled
          fullWidth
          value={isLoadingContract ? "" : contract?.client}
        />
        <TextInput
          name="domesticAmount"
          label={t("clearing.input.domesticAmount")}
          style={{ marginRight: "24px" }}
          disabled
          fullWidth
          value={isLoadingContract ? "" : contract?.domesticAmount.toFixed(2)}
        />
        <TextInput
          name="foreignAmount"
          label={t("clearing.input.foreignAmount")}
          style={{ marginRight: "24px" }}
          disabled
          fullWidth
          value={isLoadingContract ? "" : contract?.foreignAmount.toFixed(2)}
        />
        <TextInput
          name="currency"
          label={t("clearing.input.currency")}
          style={{ marginRight: "24px" }}
          disabled
          fullWidth
          value={isLoadingContract ? "" : contract?.currency.toUpperCase()}
        />
      </RowContainer>
      <TabsComponent tabs={tabs} tabId={0} />
      <RowContainer
        style={{ marginBottom: 24, marginTop: 24, justifyContent: "flex-end" }}
      >
        <TextInput
          name="domesticCurrencyAmount"
          label={t("clearing.input.domesticCurrencyAmount")}
          style={{ marginRight: "24px" }}
          disabled
          value={isLoadingContract ? "" : contract?.domesticAmount.toFixed(2)}
        />
        <TextInput
          name="foreignCurrencyAmount"
          label={t("clearing.input.foreignCurrencyAmount")}
          style={{ marginRight: "24px" }}
          disabled
          value={isLoadingContract ? "" : contract?.foreignAmount.toFixed(2)}
        />
      </RowContainer>
      <RowContainer style={{ marginBottom: 24, justifyContent: "flex-end" }}>
        <TextInput
          name="sumPaidDomesticCurrency"
          label={t("clearing.input.sumPaidDomesticCurrency")}
          style={{ marginRight: "24px" }}
          disabled
          value={
            isLoadingAmounts ? "" : amounts?.totalDomesticAmount.toFixed(2)
          }
        />
        <TextInput
          name="sumPaidForeignCurrency"
          label={t("clearing.input.sumPaidForeignCurrency")}
          style={{ marginRight: "24px" }}
          disabled
          value={isLoadingAmounts ? "" : amounts?.totalForeignAmount.toFixed(2)}
        />
      </RowContainer>
      <RowContainer style={{ marginBottom: 24, justifyContent: "flex-end" }}>
        <TextInput
          name="toPayDomesticCurrency"
          label={t("clearing.input.toPayDomesticCurrency")}
          style={{ marginRight: "24px" }}
          disabled
          value={
            isLoadingContract || isLoadingAmounts
              ? ""
              : (
                  contract?.domesticAmount - amounts?.totalDomesticAmount
                ).toFixed(2)
          }
        />
        <TextInput
          name="toPayForeignCurrency"
          label={t("clearing.input.toPayForeignCurrency")}
          style={{ marginRight: "24px" }}
          disabled
          value={
            isLoadingContract || isLoadingAmounts
              ? ""
              : (contract?.foreignAmount - amounts?.totalForeignAmount).toFixed(
                  2
                )
          }
        />
      </RowContainer>
    </PageContainer>
  );
};

export default ClearingPage;
