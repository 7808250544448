import React, { useState } from "react";
import PropTypes from "prop-types";
import { PageContainer } from "styles/SharedStyle.styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Title from "components/Title/Title";
import InvoiceForm from "components/Forms/InvoiceForm/InvoiceForm";
import { useFormik } from "formik";
import invoiceInitialValues from "initialValues/invoiceInitialValues";
import invoiceValidation from "validations/invoiceValidation";
import proInvoiceValidation from "validations/proInvoiceValidation";
import { PAGES } from "constants/pages";
import { useSelector } from "react-redux";
import { selectCurrentAgency, selectCurrentToken } from "features/auth/authSlice";
import { useTemplateAgencyInfoGetByAgencyQuery } from "features/templateAgencyInfo/templateAgencyInfoApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import {
  useAddInvoiceMutation,
  useEditInvoiceMutation,
  useInvoiceDetailsQuery,
} from "features/invoice/invoiceApiSlice";
import { convertDateToISO } from "util/dateHelpers";
import Error from "pages/ErrorPage/ErrorPage";
import {
  useInvoiceItemListQuery,
  useSaveInvoiceItemMutation,
} from "features/invoiceItem/invoiceItemApiSlice";
import BackdropComponent from "components/Backdrop/BackdropComponent";
import { useCurrencyDropdownQuery } from "features/currency/currencyApiSlice";
import { Box, IconButton, Tooltip } from "@mui/material";
import tooltipPlacements from "constants/tooltipPlacements";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import selectedTheme from "themes";

const AddEditInvoicePage = ({ isProInvoice }) => {
  const { t } = useTranslation();
  const { id: invoiceId } = useParams();
  const token = useSelector(selectCurrentToken);
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const isAdd = isProInvoice
    ? path === PAGES.ADDPROINVOICE.route
    : path === PAGES.ADDINVOICE.route;
  const [addInvoice, { isLoading: isLoadingAddInvoice }] =
    useAddInvoiceMutation();
  const [editInvoice, { isLoading: isLoadingEdiInvoice }] =
    useEditInvoiceMutation();
  const [saveInvoiceItem, { isLoading: isLoadingAddInvoiceItem }] =
    useSaveInvoiceItemMutation();
  const { data: currencyDropdown } = useCurrencyDropdownQuery();

  const handlePrintInvoice = async () => {
    // eslint-disable-next-line no-undef
    await fetch(`${process.env.REACT_APP_API_URI}/print/invoice?Id=${invoiceId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `Faktura-${formik?.values?.invoiceNumber}-${Date.now()}.pdf`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      }); // eslint-disable-line
  };

  const handleTitle = () => {
    if (isProInvoice) {
      return isAdd ? t("proinvoice.addTitle") : t("proinvoice.editTitle");
    } else {
      return isAdd ? t("invoice.addTitle") : t("invoice.editTitle");
    }
  };

  const {
    data: invoiceDetails,
    isLoading: isLoadingInvoiceDetails,
    error,
    refetch,
  } = useInvoiceDetailsQuery(
    {
      id: invoiceId,
    },
    { skip: !invoiceId }
  );

  const [passedAddressFlag, setPassedAddressFlag] = useState(true);

  const { data: invoiceItemsData, isLoading: isLoadingInvoiceItems } =
    useInvoiceItemListQuery({ invoiceId: invoiceId }, { skip: isAdd });
  const invoiceItems = isLoadingInvoiceItems ? [] : invoiceItemsData?.data;

  const agencyId = useSelector(selectCurrentAgency);
  const { data: templateAgencyInfoData } =
    useTemplateAgencyInfoGetByAgencyQuery({ agencyId: agencyId });

  const handleSubmit = async (values, action) => {
    const data = {
      ...values,
      invoiceNumber: values.number,
      issueDate: convertDateToISO(values.issueDate),
      dueDate: convertDateToISO(values.dueDate),
      transactionDate: convertDateToISO(values.transactionDate),
      issuePlace: values.location,
      curency: currencyDropdown?.find(
        (currency) => currency.value === values?.currencyId
      )?.text,
      exchangeRate: Number(values.rate),
      invoiceReason: values.invoiceReason,
      contractId: values.contractId,
      clientId: values.clientId !== "" ? values.clientId : null,
      partnerId: values.partnerId !== "" ? values.partnerId : null,
      rate: parseFloat(values.rate),
      totalAmount: parseFloat(values.totalAmount),
      items: values.items.map((item) => {
        return {
          description: item.description,
          price: parseFloat(item.price),
          quantity: parseFloat(item.quantity),
          amount: parseFloat(item.total),
        };
      }),
      address:
        `${values.clientPartnerName}\n${values.clientPartnerAddress}\n${values.clientPartnerLocationAndPostalCode}` +
        (values.clientPartner === 1
          ? `\n${values.partnerPib}\n${values.partnerLegalIdentificationNumber}`
          : ""),
    };
    if (isAdd) {
      try {
        const invoiceIdResponse = await addInvoice(data).unwrap();
        if (invoiceIdResponse && !isLoadingAddInvoice) {
          saveInvoiceItem({
            invoiceId: invoiceIdResponse?.id,
            invoiceItems: values.items.map((item) => {
              return {
                invoiceId: invoiceIdResponse?.id,
                description: item.description,
                price: parseFloat(item.price),
                quantity: parseFloat(item.quantity),
                amount: parseFloat(item.total),
              };
            }),
          })
            .unwrap()
            .then(() => {
              if (!isLoadingAddInvoiceItem) {
                makeToastMessage(t("invoice.addSuccessMessage"));
                navigate(
                  PAGES.EDITINVOICE.route.replace(":id", invoiceIdResponse?.id),
                  {
                    replace: true,
                  }
                );
              }
            })
            .catch((err) => {
              makeErrorToastMessage(err.data.message);
            })
            .finally(() => {
              action.setSubmitting(false);
            });
        }
      } catch (err) {
        makeErrorToastMessage(err.data.message || t("invoice.addErrorMessage"));
      }
    } else {
      try {
        await editInvoice({
          ...data,
          invoiceId: Number(invoiceId),
        }).unwrap();
        if (!isLoadingEdiInvoice) {
          saveInvoiceItem({
            invoiceId: Number(invoiceId),
            invoiceItems: values.items.map((item) => {
              return {
                id: item.id,
                invoiceId: Number(invoiceId),
                description: item.description,
                price: parseFloat(item.price),
                quantity: parseFloat(item.quantity),
                amount: parseFloat(item.total),
              };
            }),
          })
            .unwrap()
            .then(() => {
              if (!isLoadingAddInvoiceItem) {
                makeToastMessage(t("invoice.editSuccessMessage"));
                refetch();
              }
            })
            .catch((err) => {
              makeErrorToastMessage(err.data.message);
            })
            .finally(() => {
              action.setSubmitting(false);
            });
        }
      } catch (err) {
        makeErrorToastMessage(
          err.data.message || t("invoice.editErrorMessage")
        );
      }
    }
  };
  
  const formik = useFormik({
    initialValues:
      invoiceId && !isLoadingInvoiceDetails
        ? {
            ...invoiceDetails,
            number: invoiceDetails?.invoiceNumber,
            issueDate: new Date(invoiceDetails?.issueDate),
            transactionDate: new Date(invoiceDetails?.transactionDate),
            dueDate: new Date(invoiceDetails?.dueDate),
            clientPartner: invoiceDetails?.partnerId ? 1 : 2,
            clientId: invoiceDetails.clientId ? invoiceDetails.clientId : "",
            partnerId: invoiceDetails.partnerId ? invoiceDetails.partnerId : "",
            rate: invoiceDetails?.exchangeRate.toString(),
            currencyId: currencyDropdown?.find(
              (currency) => currency.text === invoiceDetails?.curency
            )?.value,
            invoiceReason: invoiceDetails?.invoiceReason,
            contractId: invoiceDetails?.contractId,
            location: invoiceDetails?.issuePlace,
            clientPartnerName: invoiceDetails?.address.split("\n")[0],
            clientPartnerAddress: invoiceDetails?.address.split("\n")[1],
            clientPartnerLocationAndPostalCode:
              invoiceDetails?.address.split("\n")[2],
            partnerPib: invoiceDetails?.address.split("\n")[3],
            partnerLegalIdentificationNumber:
              invoiceDetails?.address.split("\n")[4],
            totalAmount: invoiceItems.reduce(
              (acc, item) => acc + item.amount,
              0
            ),
            items: [
              ...invoiceItems.map((item) => {
                return {
                  id: item.id,
                  invoiceId: item.invoiceId,
                  description: item.description,
                  price: parseFloat(item.price).toFixed(2),
                  quantity: item.quantity,
                  total: parseFloat(item.amount).toFixed(2),
                };
              }),
            ],
          }
        : {
            ...invoiceInitialValues,
            invoiceNotice: templateAgencyInfoData?.invoiceText,
          },
    validationSchema: isProInvoice ? proInvoiceValidation : invoiceValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  if (isLoadingInvoiceDetails) {
    return (
      <BackdropComponent
        position="absolute"
        isLoading={isLoadingInvoiceDetails}
      />
    );
  }

  if (error) {
    if (error.status === 400) {
      return <Error />;
    }
  }

  return (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        style={{ marginBottom: 24 }}
      >
        <Title title={handleTitle()} />
        {!isAdd && (
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Tooltip title={t("common.pdf")} placement={tooltipPlacements.TOP}>
              <IconButton onClick={handlePrintInvoice}>
                <PictureAsPdfIcon
                  style={{
                    width: 32,
                    height: 32,
                    color: selectedTheme.colors.background2,
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      
      <InvoiceForm
        formik={formik}
        isProInvoice={isProInvoice}
        passedAddressFlag={passedAddressFlag}
        setPassedAddressFlag={(value) => setPassedAddressFlag(value)}
      />
    </PageContainer>
  );
};
AddEditInvoicePage.propTypes = {
  isProInvoice: PropTypes.bool.isRequired,
};

export default AddEditInvoicePage;
