import { Box } from "@mui/material";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelpers";
import { ReactComponent as RightArrowIcon } from "assets/images/svg/right-arrow.svg";

export const CardWithTextContainer = styled(Box)`
  cursor: pointer;
  box-shadow: 0px 2px 5px
      ${(props) => hexToRGB(props?.theme?.colors?.boxShadow, 0.15)},
    0px 2px 4px ${(props) => hexToRGB(props?.theme?.colors?.boxShadow, 0.08)};
  width: 536px;
  height: 216px;
  display: flex;
  flex-direction: column;
  margin: 10px 15px 10px 15px;
  border-radius: 8px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const CardContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px 16px 40px 16px;
  gap: 24px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const PageCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconAndTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PageCardTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28.13px;
  color: ${(props) => props?.theme?.colors?.text2};
`;

export const RightArrowStyled = styled(RightArrowIcon)``;

export const PageCardText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
  text-align: justify;
`;
