import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import selectedTheme from "themes";

export const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

export const FilterSearchContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DropdownPartners = styled(SelectInput)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border: 3px solid ${selectedTheme.colors?.text4};
    }
    width: 170px;
    height: 60px;
    display: flex;
    align-items: center;
  }
  & .MuiOutlinedInput-input {
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    line-height: 23px !important;
  }
  & .MuiInputLabel-root {
    &:not(.MuiInputLabel-shrink) {
      white-space: pre-line;
      text-align: center;
      position: absolute;
      width: 170px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 23px;
    }
  }
  & .MuiSelect-icon {
    display: none;
  }
  width: 170px;
  height: 60px; 
`;

export const AddPartnerButton = styled(Button)`
  width: 170px;
  height: 60px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 23px;
`;
