import React from "react";

import LockResetIconMui from "@mui/icons-material/LockReset";
import styled from "styled-components";

const LockResetIconMuiStyled = styled(LockResetIconMui)`
  fill: ${(props) => props?.theme?.colors?.iconColor};
`;

const LockResetIcon = (props) => {
  return <LockResetIconMuiStyled {...props} />;
};

LockResetIcon.propTypes = {};

export default LockResetIcon;
