import { Autocomplete, Button, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import CustomTextInput from "./CustomTextInput";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";

const CustomAutoCompleteLocations = (props) => {
  const { data, isLoading } = useLocationDropdownQuery(
    { id: props.countryId },
    { skip: !props.countryId }
  );
  const [addButtonText, setAddButtonText] = useState("");
  const { hasAdd } = props;

  useEffect(() => {
    if (props?.addButtonText) {
      setAddButtonText(props?.addButtonText);
    }
  }, [props?.addButtonText]);

  const onAddButtonClick = useMemo(() => {
    return props?.addButtonClick;
  }, [props?.addButtonClick]);

  const options = !isLoading
    ? [addButtonText, ...(Array.isArray(data) ? data : [])]
    : [];

  return (
    <>
      <Autocomplete
        fullWidth
        style={props?.style}
        id={props?.name}
        options={options}
        getOptionLabel={(option) => option?.text}
        disabled={props?.disabled}
        value={
          !isLoading
            ? data?.find(
                (option) =>
                  option?.value ===
                  (props?.value ?? props?.formik?.values?.[props?.name])
              ) || null
            : null
        }
        onChange={(event, newValue) => {
          props?.formik?.setFieldValue(
            props?.name,
            newValue ? newValue.value : ""
          );
        }}
        filterOptions={(filteredOptions, state) => {
          const inputValue = state.inputValue.toLowerCase();
          const filtered = data?.filter((singleData) =>
            singleData?.text?.toLowerCase().includes(inputValue)
          );

          return hasAdd ? [addButtonText, ...filtered] : filtered;
        }}
        renderOption={(props, option) => {
          if (hasAdd) {
            if (option === addButtonText) {
              return (
                <Button
                  fullWidth
                  variant="contained"
                  onClick={onAddButtonClick}
                >
                  {addButtonText}
                </Button>
              );
            }
          }
          return (
            <li {...props} key={option?.value}>
              <Typography>{option?.text}</Typography>
            </li>
          );
        }}
        renderInput={(params) => (
          <CustomTextInput
            {...params}
            name={props?.name}
            label={props?.label}
            error={
              props?.formik?.touched?.[props?.name] &&
              Boolean(props?.formik?.errors?.[props?.name])
            }
            helperText={
              props?.formik?.touched?.[props?.name] &&
              props?.formik?.errors?.[props?.name]
            }
          />
        )}
      />
    </>
  );
};

CustomAutoCompleteLocations.defaultProps = {
  hasAdd: true,
  disabled: false,
};

CustomAutoCompleteLocations.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  formik: PropTypes.any,
  addButtonText: PropTypes.string,
  addButtonClick: PropTypes.func,
  hasAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  countryId: PropTypes.number,
};

export default CustomAutoCompleteLocations;
