/* eslint-disable react/prop-types */
import AccommodationSelect from "components/Inputs/AccommodationSelect";
import AccommodationTypeSelect from "components/Inputs/AccommodationTypeSelect";
import AccommodationUnitTypeSelect from "components/Inputs/AccommodationUnitTypeSelect";
import CategorySelect from "components/Inputs/CategorySelect";
import CountrySelect from "components/Inputs/CountrySelect";
import DatePick from "components/Inputs/DatePick";
import LocationSelect from "components/Inputs/LocationSelect";
import OfferTypeSelect from "components/Inputs/OfferTypeSelect";
import SelectInput from "components/Inputs/SelectInput";
import ServiceSelect from "components/Inputs/ServicesSelect";
import TextInput from "components/Inputs/TextInput";
import TransportationTypeSelect from "components/Inputs/TransportationTypeSelect";
import { INTERNAL_CONTRACT } from "constants/contractState";
import {
  ACCOMMODATION,
  ARRANGEMENT,
  TRANSPORT,
} from "constants/offerDetailsLevel";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";

const TravelDetailsForm = ({ formik, offerDetailsLevelId, type }) => {
  const { t } = useTranslation();
  return (
    <>
      <RowContainer>
        <CategorySelect
          formik={formik}
          name="categoryId"
          label={t("field.categoryPlaceholderRequired")}
          style={{ marginRight: "24px" }}
          disabled={type === INTERNAL_CONTRACT}
        />
        <OfferTypeSelect
          formik={formik}
          name="offerTypeId"
          label={t("field.offerTypePlaceholderRequired")}
          style={{ marginRight: "24px" }}
          disabled={type === INTERNAL_CONTRACT}
        />
        <CountrySelect
          formik={formik}
          name="countryId"
          label={t("field.countryIdPlaceholder")}
          style={{ marginRight: "24px" }}
          disabled={type === INTERNAL_CONTRACT}
        />
        <SelectInput
          fullWidth
          name="appointmentDate"
          label={t("field.appointmentDatePlaceholder")}
          onChange={formik.handleChange}
          value={formik.values?.appointmentDate || ""}
          items={[]}
          error={
            formik.touched.appointmentDate &&
            Boolean(formik.errors.appointmentDate)
          }
          helperText={
            formik.touched.appointmentDate && formik.errors.appointmentDate
          }
        />
      </RowContainer>
      <RowContainer>
        <LocationSelect
          name="locationId"
          disabled={type === INTERNAL_CONTRACT}
          label={t("field.locationIdPlaceholder")}
          formik={formik}
          countryId={formik.values.countryId}
          style={{ marginRight: "24px" }}
        />
        {offerDetailsLevelId === null && (
          <>
            <TextInput
              hiddenLabel
              fullWidth
              type="hidden"
              style={{
                marginLeft: "12px",
                marginRight: "12px",
                visibility: "hidden",
                height: "100%",
                width: "100%",
              }}
            />
            <TextInput
              hiddenLabel
              fullWidth
              type="hidden"
              style={{
                marginLeft: "12px",
                marginRight: "12px",
                visibility: "hidden",
                height: "100%",
                width: "100%",
              }}
            />
          </>
        )}
        {offerDetailsLevelId === TRANSPORT && (
          <>
            <TransportationTypeSelect
              name="transportationTypeId"
              label={t("field.transportatopnTypeIdPlaceholderRequired")}
              disabled={type === INTERNAL_CONTRACT}
              formik={formik}
            />
            <TextInput
              hiddenLabel
              fullWidth
              type="hidden"
              style={{
                marginLeft: "12px",
                marginRight: "12px",
                visibility: "hidden",
                height: "100%",
                width: "100%",
              }}
            />
          </>
        )}
        {(offerDetailsLevelId === ARRANGEMENT ||
          offerDetailsLevelId === ACCOMMODATION) && (
          <>
            <AccommodationTypeSelect
              formik={formik}
              name="accommodationTypeId"
              label={t("field.accommodationTypeIdPlaceholder")}
              style={{ marginRight: "24px" }}
              disabled={type === INTERNAL_CONTRACT}
            />
            <AccommodationSelect
              formik={formik}
              name="accommodationId"
              label={t("field.accommodationIdPlaceholderRequired")}
              style={{ marginRight: "24px" }}
              disabled={type === INTERNAL_CONTRACT}
              apiProps={{}}
            />
          </>
        )}
        <DatePick
          name="departureDate"
          style={{ marginLeft: "24px" }}
          label={t("field.departureDatePlaceholder")}
          value={formik.values?.departureDate}
          onChange={(value) => formik.setFieldValue("departureDate", value)}
          error={
            formik.touched.departureDate && Boolean(formik.errors.departureDate)
          }
          helperText={
            formik.touched.departureDate && formik.errors.departureDate
          }
          minDate={new Date()}
        />
      </RowContainer>

      {(offerDetailsLevelId === ARRANGEMENT ||
        offerDetailsLevelId === ACCOMMODATION) && (
        <RowContainer>
          <AccommodationUnitTypeSelect
            name="accommodationUnitTypeId"
            label={t("field.accommodationUnitTypeRequiredPlaceholder")}
            style={{ marginRight: "24px" }}
            disabled={type === INTERNAL_CONTRACT}
            formik={formik}
          />
          <ServiceSelect
            name="serviceId"
            label={t("field.serviceIdPlaceholderRequired")}
            style={{ marginRight: "24px" }}
            disabled={type === INTERNAL_CONTRACT}
            formik={formik}
          />
          {(offerDetailsLevelId === ARRANGEMENT ||
            offerDetailsLevelId === TRANSPORT) && (
            <>
              <TransportationTypeSelect
                name="transportationTypeId"
                label={t("field.transportatopnTypeIdPlaceholderRequired")}
                disabled={type === INTERNAL_CONTRACT}
                style={{ marginRight: "24px" }}
                formik={formik}
              />
            </>
          )}
          {(offerDetailsLevelId === ACCOMMODATION ||
            offerDetailsLevelId === ARRANGEMENT) && (
            <TextInput
              hiddenLabel
              fullWidth
              type="hidden"
              style={{
                visibility: "hidden",
                height: "100%",
                width: "100%",
              }}
            />
          )}
          {offerDetailsLevelId === ACCOMMODATION && (
            <TextInput
              hiddenLabel
              fullWidth
              type="hidden"
              style={{
                marginLeft: "24px",
                visibility: "hidden",
                height: "100%",
                width: "100%",
              }}
            />
          )}
        </RowContainer>
      )}
    </>
  );
};

export default TravelDetailsForm;
