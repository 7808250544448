import { Box, Dialog, Typography } from "@mui/material";
import styled from "styled-components";

export const DialogStyled = styled(Dialog)`
  & .MuiPaper-root {
    border-radius: 8px;
    max-width: ${(props) => props?.maxWidth};
  }
`;

export const DialogContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const TopContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;

export const ContentContainer = styled(Box)`
  margin-top: 24px;
`;
