import React from "react";

import CreateIconMui from "@mui/icons-material/Create";
import styled from "styled-components";

const CreateIconMuiStyled = styled(CreateIconMui)`
  fill: ${(props) => props?.theme?.colors?.iconColor};
`;

const CreateIcon = (props) => {
  return <CreateIconMuiStyled {...props} />;
};

CreateIcon.propTypes = {};

export default CreateIcon;
