/* eslint-disable no-unused-vars */
import { Typography } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import ContractFilter from "components/Filter/ContractFilter/ContractFilter";
import Filter from "components/Filter/Filter";
import { useContractListQuery } from "features/contract/contractApiSlice";
import { useFormik } from "formik";
import useContractTable from "hooks/tables/useContractTable";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { convertDateToISO } from "util/dateHelpers";
import { getNonEmptyProps } from "util/helpers/objectHelpers";

const ContractsPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);
  const { columns } = useContractTable({
    page,
    rowsPerPage,
  });

  const [appliedFilters, setAppliedFilters] = useState({});
  const handleFilter = (values) => {
    setPage(0);
    const formatedValues = {
      ...values,
      DepartureDate: values.DepartureDate
        ? convertDateToISO(values.DepartureDate)
        : null,
    };
    setFilter(getNonEmptyProps(formatedValues));
    setAppliedFilters(getNonEmptyProps(formatedValues));
  };

  const { data: contracts, isLoading: isLoadingContract } =
    useContractListQuery({
      page: page,
      rowsPerPage: rowsPerPage,
      sort: sort,
      filter: filter,
    });

  const filterForm = useFormik({
    initialValues: {
      Number: "",
      OrganizerId: null,
      DepartureDate: null,
      ClientId: null,
      ContractType: null,
      CountryId: null,
      LocationId: null,
      Active: null,
    },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <PageContainer>
      <Typography variant="h4" color="" style={{ marginBottom: 24 }}>
        {t("contract.pageTitle")}
      </Typography>
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <ContractFilter
            filterForm={filterForm}
            appliedFilters={appliedFilters}
          />
        </Filter>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={contracts?.data}
        isLoading={isLoadingContract}
        count={contracts?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("contract.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default ContractsPage;
