import { Box, Chip } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { contractStatus } from "constants/contractStatus";

const ContractStatus = ({ status }) => {
  const { label, color, background } = contractStatus[status] || {};

  return (
    <Chip
      label={label}
      icon={
        <Box style={{ alignSelf: "center", paddingLeft: 8 }}>
          <FiberManualRecordIcon
            style={{
              width: 8,
              height: 8,
              color: color,
            }}
          />
        </Box>
      }
      style={{
        fontSize: 16,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        color: color,
        background: background,
      }}
    />
  );
};
ContractStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ContractStatus;
