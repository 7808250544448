/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";
import { PAGES } from "constants/pages";
import { useInvoiceItemListQuery } from "features/invoiceItem/invoiceItemApiSlice";
import CreateIcon from "components/Icon/Icons/CreateIcon";
import RemoveCircleIcon from "components/Icon/Icons/RemoveCircleIcon";

const useInvoiceTable = ({ page, rowsPerPage, isProInvoice }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: isProInvoice ? "" : "invoiceNumber",
      label: isProInvoice
        ? t("proinvoice.proinvoiceNumber")
        : t("invoice.invoiceNumber"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: isProInvoice ? "" : "issueDate",
      label: isProInvoice
        ? t("proinvoice.proinvoiceDate")
        : t("invoice.invoiceDate"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return rowData.issueDate
            ? format(new Date(rowData.issueDate), "dd.MM.yyyy.")
            : "";
        },
      },
    },
    {
      name: "address",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "client",
      label: t("invoice.client"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return rowData.client ? rowData?.client : "/";
        },
      },
    },
    {
      name: "partner",
      label: t("invoice.partner"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return rowData.partner ? rowData?.partner : "/";
        },
      },
    },
    {
      name: "totalAmount",
      label: t("invoice.amount"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return rowData.totalAmount;
        },
      },
    },
    {
      name: "contractNumber",
      label: t("invoice.contractNumber"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "updatedBy",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "updatedOn",
      label: t("invoice.lastChanges"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h7">
                Datum: {format(new Date(rowData.updatedOn), "dd.MM.yyyy.")}
              </Typography>
              <Typography variant="h7">Izmenio: {rowData.updatedBy}</Typography>
            </Box>
          );
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip
                title="Izmeni"
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    isProInvoice
                      ? navigate(
                          PAGES.EDITPROINVOICE.route.replace(":id", rowData.id)
                        )
                      : navigate(
                          PAGES.EDITINVOICE.route.replace(":id", rowData.id)
                        );
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Obriši" placement="top">
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: isProInvoice
                          ? modalConstants.DELETE_PROINVOICE_MODAL
                          : modalConstants.DELETE_INVOICE_MODAL,
                        data: { id: rowData.id },
                      })
                    );
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useInvoiceTable;
