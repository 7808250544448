import { FormGroup } from "@mui/material";
import styled from "styled-components";

export const CheckboxContainer = styled(FormGroup)`
  justify-content: center;
  width: 100%;
  @media (max-width: 600px) {
    justify-content: flex-start;
  }
`;
