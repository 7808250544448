/* eslint-disable react/prop-types */
import { Autocomplete, Box, Button, IconButton } from "@mui/material";
import TextInput from "components/Inputs/TextInput";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  InformationContainer,
  InformationMainText,
} from "styles/SharedStyle.styled";
import FormActions from "../FormActions/FormActions";
import { useLazyExchangeRateOnDateQuery } from "features/exchange-rate/exchangeRateSlice";
import { convertDateToISO } from "util/dateHelpers";
import { useAgencyPartnerDropdownQuery } from "features/agencyPartner/agencyPartnerApiSlice";
import ClientCard from "components/Cards/ClientCard/ClientCard";
import PartnerCard from "components/Cards/PartnerCard/PartnerCard";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { useCurrencyDropdownQuery } from "features/currency/currencyApiSlice";
import PartnerSelect from "components/Inputs/PartnerSelect";
import ClientSelect from "components/Inputs/ClientSelect";
import CustomTextInput from "components/Inputs/CustomTextInput";
import CustomDatePick from "components/Inputs/CustomDatePick";
import CustomSelectInput from "components/Inputs/CustomSelectInput";
import { InvoiceItemsActionsContainer } from "./InvoiceForm.styled";
import { invoiceFormikParams } from "constants/formik/invoiceFormikParams";
import { invoiceItemFormikParams } from "constants/formik/invoiceItemFormikParams";
import {
  RowContainer,
  ColumnContainer,
  FormContainer,
} from "styles/RefactoredSharedStyle.styled";
import { useContractDropdownQuery } from "features/contract/contractApiSlice";
import { isValidInput } from "util/helpers/numberHelpers";

const InvoiceForm = ({
  formik,
  isProInvoice,
  passedAddressFlag,
  setPassedAddressFlag,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const items = formik.values?.items || [];
  const error = formik.errors?.items || [];
  const touched = formik.touched?.items || [];
  const { data: currencyDropdown } = useCurrencyDropdownQuery();
  const [trigger] = useLazyExchangeRateOnDateQuery();

  const clientPartners = [
    { value: 1, text: "Partner" },
    { value: 2, text: "Klijent" },
  ];

  const { data: partners } = useAgencyPartnerDropdownQuery();

  const { data: contractNumbers, isLoading: isLoadingContractNumbers } =
    useContractDropdownQuery();

  const handleCancel = () => {
    navigate(isProInvoice ? PAGES.PROINVOICE.route : PAGES.INVOICE.route);
  };

  const calculateTotal = (quantity, price) => {
    return parseFloat(quantity * price).toFixed(2);
  };
  const handleInputChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: value,
    };
    updatedItems[index].total = calculateTotal(
      updatedItems[index].quantity,
      updatedItems[index].price
    );
    const totalAmount = updatedItems.reduce(
      (acc, item) => acc + parseFloat(item.total),
      0
    );
    formik.setFieldValue(
      invoiceFormikParams.TOTAL_AMOUNT,
      parseFloat(totalAmount).toFixed(2),
      false
    );

    formik.setFieldValue("items", updatedItems);
  };

  const handleExchangeRate = async (value, field) => {
    const updatedItems = { ...formik.values, [field]: value };

    if (updatedItems.currencyId && updatedItems.issueDate) {
      const { data } = await trigger(
        {
          date: convertDateToISO(updatedItems.issueDate),
          currencyId: updatedItems.currencyId,
        },
        {
          skip: !updatedItems.currencyId || !updatedItems.issueDate,
        }
      );
      formik.setFieldValue(
        invoiceFormikParams.RATE,
        data?.rate > 0 ? data.rate : ""
      );
    }

    formik.setFieldValue(field, value);
  };

  const partnerOnly = useMemo(() => {
    if (partners) {
      return !partners.find(
        (partner) => partner.value === formik.values.partnerId
      )?.partnerOnly;
    } else return false;
  }, [partners, formik.values.partnerId]);

  return (
    <ColumnContainer>
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {isProInvoice
              ? t("proinvoice.section.basicInfo")
              : t("invoice.section.basicInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <Box sx={{ width: "66%" }}>
            <ColumnContainer>
              <RowContainer>
                <ColumnContainer>
                  <CustomTextInput
                    fullWidth
                    name={invoiceFormikParams.NUMBER}
                    label={
                      isProInvoice
                        ? t("field.proInvoiceNumberPlaceholderRequired")
                        : t("field.invoiceNumberPlaceholderRequired")
                    }
                    formik={formik}
                  />
                  <CustomTextInput
                    fullWidth
                    name={invoiceFormikParams.LOCATION}
                    label={
                      isProInvoice
                        ? t("field.proInvoiceLocationPlaceholderRequired")
                        : t("field.invoiceLocationPlaceholderRequired")
                    }
                    formik={formik}
                  />
                  <CustomDatePick
                    fullWidth
                    name={invoiceFormikParams.DUE_DATE}
                    label={
                      isProInvoice
                        ? t("field.proInvoiceDueDatePlaceholderRequired")
                        : t("field.invoiceDueDatePlaceholderRequired")
                    }
                    formik={formik}
                  />
                  <CustomSelectInput
                    fullWidth
                    name={invoiceFormikParams.CURRENCY_ID}
                    label={t("field.currencyPlaceholderRequired")}
                    formik={formik}
                    items={currencyDropdown}
                    onChange={(e) => {
                      handleExchangeRate(
                        e.target.value,
                        invoiceFormikParams.CURRENCY_ID
                      );
                    }}
                  />
                  <CustomSelectInput
                    fullWidth
                    name={invoiceFormikParams.CLIENT_PARTNER}
                    label={t("field.clientPartnerPlaceholderRequired")}
                    value={formik.values.clientPartner}
                    onChange={(e) => {
                      formik.setFieldValue(invoiceFormikParams.CLIENT_ID, "");
                      formik.setFieldValue(invoiceFormikParams.PARTNER_ID, "");
                      formik.setFieldValue(
                        invoiceFormikParams.CLIENT_PARTNER,
                        e.target.value,
                        true
                      );
                    }}
                    formik={formik}
                    items={clientPartners}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <CustomDatePick
                    fullWidth
                    name={invoiceFormikParams.ISSUE_DATE}
                    label={
                      isProInvoice
                        ? t("field.proInvoiceIssueDatePlaceholderRequired")
                        : t("field.invoiceIssueDatePlaceholderRequired")
                    }
                    formik={formik}
                    onChange={(value) => {
                      handleExchangeRate(value, invoiceFormikParams.ISSUE_DATE);
                    }}
                  />
                  <CustomDatePick
                    fullWidth
                    name={invoiceFormikParams.TRANSACTION_DATE}
                    label={t("field.invoiceTransactionDatePlaceholderRequired")}
                    formik={formik}
                  />
                  <Autocomplete
                    fullWidth
                    id={invoiceFormikParams.CONTRACT_ID}
                    options={isLoadingContractNumbers ? [] : contractNumbers}
                    getOptionLabel={(option) => option.text}
                    value={
                      !isLoadingContractNumbers
                        ? contractNumbers.find(
                            (option) =>
                              option.value === formik.values.contractId
                          ) || null
                        : null
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        invoiceFormikParams.CONTRACT_ID,
                        newValue ? newValue.value : ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextInput
                        {...params}
                        name={invoiceFormikParams.CONTRACT_ID}
                        label={t("field.contractNumberLowercase")}
                        error={
                          formik.touched.contractId &&
                          Boolean(formik.errors.contractId)
                        }
                        helperText={
                          formik.touched.contractId && formik.errors.contractId
                        }
                      />
                    )}
                  />
                  <CustomTextInput
                    fullWidth
                    name={invoiceFormikParams.RATE}
                    label={t("field.ratePlaceholder")}
                    formik={formik}
                    onChange={(e) => {
                      e.preventDefault();
                      const inputValue = e.target.value;
                      if (inputValue === "" || isValidInput(inputValue, 4)) {
                        formik.setFieldValue(
                          invoiceFormikParams.RATE,
                          inputValue
                        );
                      }
                    }}
                  />
                  {formik.values.clientPartner === 1 ? (
                    <PartnerSelect
                      name={invoiceFormikParams.PARTNER_ID}
                      label={t("field.partnerPlaceholderRequired")}
                      formik={formik}
                      setPassedAddressFlag={() => setPassedAddressFlag(false)}
                    />
                  ) : formik.values.clientPartner === 2 ? (
                    <ClientSelect
                      name={invoiceFormikParams.CLIENT_ID}
                      label={t("field.clientPlaceholderRequired")}
                      formik={formik}
                      setPassedAddressFlag={() => setPassedAddressFlag(false)}
                    />
                  ) : (
                    <Autocomplete
                      fullWidth
                      options={[]}
                      renderInput={(params) => (
                        <TextInput {...params} fullWidth />
                      )}
                    />
                  )}
                </ColumnContainer>
              </RowContainer>
              <CustomTextInput
                multiline
                fullWidth
                minRows={3}
                maxRows={3}
                name={invoiceFormikParams.NOTE}
                label={t("field.invoiceNote")}
                formik={formik}
              />
            </ColumnContainer>
          </Box>
          <Box sx={{ width: "33%" }}>
            {(formik.values.clientId || formik.values.partnerId) && (
              <FormContainer>
                <InformationContainer>
                  <InformationMainText>
                    {formik.values.clientId
                      ? t("invoice.section.clientInfo")
                      : formik.values.partnerId
                      ? t("invoice.section.partnerInfo")
                      : ""}
                  </InformationMainText>
                </InformationContainer>
                {formik.values.clientId && (
                  <ClientCard
                    formik={formik}
                    clientId={formik.values.clientId}
                    showPassedAddress={true}
                    passedAddressFlag={passedAddressFlag}
                    disabled={{ name: true }}
                  />
                )}
                {formik.values.partnerId && (
                  <PartnerCard
                    formik={formik}
                    partnerId={formik.values.partnerId}
                    partnerOnly={partnerOnly}
                    passedAddressFlag={passedAddressFlag}
                    disabled={{ name: true, pib: true, legal: true }}
                  />
                )}
              </FormContainer>
            )}
          </Box>
        </RowContainer>
      </FormContainer>
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {isProInvoice
              ? t("proinvoice.section.itemsInfo")
              : t("invoice.section.itemsInfo")}
          </InformationMainText>
        </InformationContainer>
        <>
          {formik?.values.items && (
            <ColumnContainer>
              {formik.values.items.map((item, index) => (
                <RowContainer
                  key={index}
                  style={{ gap: "24px", alignItems: "center" }}
                >
                  <CustomTextInput
                    label={t("field.invoiceItemOrdinalNumberAbbreviation")}
                    value={index + 1}
                    style={{ flex: "0 0 70px" }}
                    disabled
                  />
                  <CustomTextInput
                    name={invoiceItemFormikParams.DESCRIPTION}
                    label={t("field.invoiceItemDescriptionPlaceholderRequired")}
                    value={items[index]?.description}
                    style={{ flex: "4" }}
                    onChange={(e) => {
                      e.preventDefault();
                      handleInputChange(
                        index,
                        invoiceItemFormikParams.DESCRIPTION,
                        e.target.value
                      );
                    }}
                    error={
                      touched[index]?.description &&
                      Boolean(error[index]?.description)
                    }
                    helperText={
                      touched[index]?.description && error[index]?.description
                    }
                  />
                  <CustomTextInput
                    name={invoiceItemFormikParams.PRICE}
                    label={t("field.invoiceItemPricePlaceholderRequired")}
                    value={items[index]?.price || ""}
                    style={{ flex: "1" }}
                    onChange={(e) => {
                      e.preventDefault();
                      const inputValue = e.target.value;
                      if (inputValue === "" || isValidInput(inputValue)) {
                        handleInputChange(
                          index,
                          invoiceItemFormikParams.PRICE,
                          inputValue
                        );
                      }
                    }}
                    error={
                      touched[index]?.price && Boolean(error[index]?.price)
                    }
                    helperText={touched[index]?.price && error[index]?.price}
                  />
                  <CustomTextInput
                    name={invoiceItemFormikParams.QUANTITY}
                    label={t("field.invoiceItemQuantityPlaceholderRequired")}
                    value={items[index]?.quantity}
                    style={{ flex: "1" }}
                    onChange={(e) => {
                      e.preventDefault();
                      const inputValue = e.target.value;
                      if (
                        inputValue === "" ||
                        isValidInput(inputValue, 0, true)
                      ) {
                        handleInputChange(
                          index,
                          invoiceItemFormikParams.QUANTITY,
                          inputValue
                        );
                      }
                    }}
                    sx={{ width: 800 }}
                    error={
                      touched[index]?.quantity &&
                      Boolean(error[index]?.quantity)
                    }
                    helperText={
                      touched[index]?.quantity && error[index]?.quantity
                    }
                  />

                  <TextInput
                    name={invoiceItemFormikParams.TOTAL}
                    label={t("field.ivoiceItemTotalPlaceholder")}
                    value={items[index]?.total}
                    style={{ flex: "2" }}
                    error={
                      touched[index]?.total && Boolean(error[index]?.total)
                    }
                    helperText={touched[index]?.total && error[index]?.total}
                    disabled
                  />
                  <IconButton
                    color="error"
                    onClick={() => {
                      formik.setFieldValue(
                        invoiceFormikParams.ITEMS,
                        formik.values.items.filter((_, i) => i !== index)
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </RowContainer>
              ))}
              <InvoiceItemsActionsContainer>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() =>
                    formik.setFieldValue(invoiceFormikParams.ITEMS, [
                      ...formik.values.items,
                      {
                        description: "",
                        price: "",
                        quantity: 1,
                        total: "",
                      },
                    ])
                  }
                >
                  {t("statement.addNewItem")}
                </Button>
                <CustomTextInput
                  name={invoiceFormikParams.TOTAL_AMOUNT}
                  label={t("field.invoiceTotalAmountPlaceholder")}
                  formik={formik}
                  disabled
                  style={{ width: "400px", maxWidth: "100%" }}
                />
                <CustomTextInput
                  name={invoiceFormikParams.DESCRIPTION}
                  label={t("field.invoiceCharTotalAmoutPlaceholder")}
                  formik={formik}
                  style={{ width: "400px", maxWidth: "100%" }}
                />
              </InvoiceItemsActionsContainer>
            </ColumnContainer>
          )}
        </>
      </FormContainer>
      <ColumnContainer>
        <RowContainer>
          <CustomTextInput
            fullWidth
            multiline
            minRows={3}
            maxRows={3}
            label={t("invoice.noteFromPrintSettings")}
            name={invoiceFormikParams.INVOICE_NOTICE}
            formik={formik}
            value={formik.values.invoiceNotice}
          />
        </RowContainer>
        <FormActions
          disabled={!formik.dirty || formik.isSubmitting}
          handleConfirm={formik.handleSubmit}
          handleCancel={handleCancel}
        />
      </ColumnContainer>
    </ColumnContainer>
  );
};

export default InvoiceForm;
