/* eslint-disable react/prop-types */
import { Box, Button, Divider } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import { useAgencyDropdownQuery } from "features/agency/agencyApiSlice";
import {
  SectionContainer,
  SectionTitle,
  Title,
} from "pages/SingleContractPage/SingleContractPage.styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import PassengerForm from "../PassengerForm/PassengerForm";
import TravelDetailsForm from "../TravelDetailsForm/TravelDetailsForm";
import CalculationForm from "../CalculationForm/CalculationForm";
import { INTERNAL_CONTRACT } from "constants/contractState";
import selectedTheme from "themes";
import buttonVariants from "constants/buttonVariants";
import inputTypes from "constants/inputTypes";

const ContractForm = ({ offerDetailsLevelId, formik, handleCancel, type }) => {
  const { t } = useTranslation();

  const { data: agencies, isLoading: isLoadingAgencies } =
    useAgencyDropdownQuery({});

  return (
    <Box component="form">
      <Title>{t("singleContract.pageTitle", { contractNumber: 2 })}</Title>
      <SectionContainer>
        <RowContainer>
          <TextInput
            fullWidth
            name="name"
            label={t("field.offerNamePlaceholderRequired")}
            onChange={formik.handleChange}
            value={formik?.values?.name || ""}
            style={{ marginRight: "24px" }}
            disabled={type === INTERNAL_CONTRACT}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextInput
            fullWidth
            name="number"
            label={t("field.offerNumberPlaceholderRequired")}
            value={formik.values?.number || ""}
            onChange={formik.handleChange}
            disabled={type === INTERNAL_CONTRACT}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik.touched.number && formik.errors.number}
          />
        </RowContainer>

        <RowContainer>
          <SelectInput
            fullWidth
            name="travelOrganizerId"
            label={t("field.travelOrganizerPlaceholderRequired")}
            value={formik.values?.travelOrganizerId || ""}
            style={{ marginRight: "24px" }}
            onChange={formik.handleChange}
            items={!isLoadingAgencies && agencies}
            disabled={type === INTERNAL_CONTRACT}
            error={
              formik.touched.travelOrganizerId &&
              Boolean(formik.errors.travelOrganizerId)
            }
            helperText={
              formik.touched.travelOrganizerId &&
              formik.errors.travelOrganizerId
            }
          />
          <TextInput
            fullWidth
            name="jid"
            label={t("field.jidPlaceholder")}
            value={formik.values?.jid || ""}
            onChange={formik.handleChange}
          />
        </RowContainer>
      </SectionContainer>

      <Divider style={{ marginBottom: 24 }} />
      <SectionTitle>{t("singleContract.sections.travelDetails")}</SectionTitle>
      <TravelDetailsForm
        formik={formik}
        offerDetailsLevelId={offerDetailsLevelId}
        type={type}
      />

      <Divider style={{ marginBottom: 24 }} />
      <SectionTitle>
        {t("singleContract.sections.passengersDetails")}
      </SectionTitle>
      <PassengerForm formik={formik} />

      <Divider style={{ marginBottom: 24, marginTop: 24 }} />
      <SectionTitle>{t("singleContract.sections.calculation")}</SectionTitle>
      <CalculationForm formik={formik} />

      <Divider style={{ marginBottom: 24, marginTop: 24 }} />
      <SectionTitle>{t("singleContract.sections.description")}</SectionTitle>
      <TextInput
        fullWidth
        multiline
        minRows={4}
        maxRows={8}
        name="description"
        label={t("field.descriptionPlaceholder")}
        value={formik?.values?.description || ""}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
      />
      <RowContainer
        style={{ marginBottom: 0, justifyContent: "center", marginTop: 24 }}
      >
        <Button
          variant={buttonVariants.OUTLINED}
          onClick={handleCancel}
          style={{ marginRight: "24px" }}
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant={buttonVariants.CONTAINED}
          type={inputTypes.SUBMIT}
          disabled={!formik.dirty || formik.isSubmitting}
          onClick={formik.handleSubmit}
          style={{
            backgroundColor: !formik.dirty
              ? ""
              : selectedTheme.colors.primaryBlue,
          }}
        >
          {t("common.reserve")}
        </Button>
      </RowContainer>
    </Box>
  );
};

export default ContractForm;
