/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import { useBankAccountDropdownQuery } from "features/bankAccount/bankAccountApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomSelectFilter from "../CustomSelectFilter";
import CustomTextFilter from "../CustomTextFilter";
import DateFilter from "../DateFilter";
import { useStatementTypeDropdownQuery } from "features/statementType/statementTypeApiSlice";

const StatementFilter = ({ filterForm, appliedFilters }) => {
  const { t } = useTranslation();
  const { data: bankAccounts, isLoading: isLoadingBankAccounts } =
    useBankAccountDropdownQuery();
  const { data: statementTypes, isLoading: isLoadingStatementTypes } =
    useStatementTypeDropdownQuery();
  return (
    <Box component="form">
      <RowContainer>
        <DateFilter
          name="From"
          label={t("statement.filter.dateFrom")}
          filterForm={filterForm}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <DateFilter
          name="Till"
          label={t("statement.filter.dateTo")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="AccountNumber"
          label={t("field.accountNumberFilter")}
          filterForm={filterForm}
          items={!isLoadingBankAccounts && bankAccounts}
          style={{ marginRight: "24px" }}
          appliedFilters={appliedFilters}
        />
        <CustomTextFilter
          name="Number"
          label={t("field.statementNumberFilter")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomSelectFilter
          name="StatementTypeId"
          label={t("field.statementTypePlaceholder")}
          filterForm={filterForm}
          items={!isLoadingStatementTypes && statementTypes}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
    </Box>
  );
};

export default StatementFilter;
