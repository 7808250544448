/* eslint-disable react/prop-types */
import { Typography } from "@mui/material";
import React from "react";

const Title = ({ title, variant = "h4", style = { marginBottom: 24 } }) => {
  return (
    <Typography
      dangerouslySetInnerHTML={{ __html: title }}
      variant={variant}
      style={style}
    />
  );
};

export default Title;
