/* eslint-disable react/prop-types */
import React from "react";
import { ContentContainer, MainContainer } from "./MainLayout.styled";
import Sidemenu from "components/Sidemenu/Sidemenu";
import Header from "components/Header/Header";
import useDevice from "hooks/useDevice";
import { useSelector } from "react-redux";
import { selectIsSidebarCollapsed } from "features/app/appSlice";
import { COLLAPSED_WIDTH, FULL_WIDTH } from "constants/sidemenu";

const MainLayout = ({ children }) => {
  const isSidebarCollapsed = useSelector(selectIsSidebarCollapsed);

  const { isMobile } = useDevice();
  return (
    <MainContainer>
      {!isMobile && <Sidemenu />}
      <ContentContainer
        style={{
          minHeight: "100vh",
          marginLeft: isMobile
            ? "0px"
            : isSidebarCollapsed
            ? COLLAPSED_WIDTH
            : FULL_WIDTH,
        }}
      >
        <Header />
        {children}
      </ContentContainer>
    </MainContainer>
  );
};

export default MainLayout;
