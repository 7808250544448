export const externalSaleFormikParams = {
  CLIENT_ID: "clientId",
  ADDRESS: "address",
  LOCATION: "location",
  PHONE_NUMBER: "phoneNumber",
  DATE_OF_BIRTH: "dateOfBirth",
  PASSPORT_NUMBER: "passportNumber",
  TRAVEL_ARRANGER: "travelArranger",
  DEPARTURE_LOCATION: "departureLocation",
  SERVICE: "service",
  QUANTITY: "quantity",
  DOMESTIC_AMOUNT: "domesticAmount",
  DOMESTIC_DISCOUNT: "domesticDiscount",
  DOMESTIC_TOTAL_AMOUNT: "domesticTotalAmount",
  CURRENCY_ID: "currencyId",
  FOREIGN_AMOUNT: "foreignAmount",
  FOREIGN_DISCOUNT: "foreignDiscount",
  FOREIGN_TOTAL_AMOUNT: "foreignTotalAmount",
};
