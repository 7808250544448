import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useAgencyPartnerDropdownQuery,
  useCreateExternalPartnerMutation,
} from "features/agencyPartner/agencyPartnerApiSlice";
import { useTranslation } from "react-i18next";
import AddPartnerModal from "components/Modals/AddPartnerModal/AddPartnerModal";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useFormik } from "formik";
import partnerInitialValues from "initialValues/partnerInitialValues";
import agencyPartnerExternalValidationOtp from "validations/agencyPartnerExternalValidationOtp";
import agencyPartnerExternalValidationNonOtp from "validations/agencyPartnerExternalValidationNonOtp";
import CustomAutoComplete from "./CustomAutoComplete";
import { OTP_TYPE } from "constants/agencyType";

const PartnerSelect = (props) => {
  const { t } = useTranslation();
  const addPartnerButtonText = t("partners.addTitle");
  const [createExternalPartner, { isLoading: isLoadingCreateExternalPartner }] =
    useCreateExternalPartnerMutation();
  const [openAdd, setOpenAdd] = useState(false);
  const [agencyType, setAgencyType] = useState(null);

  const handleSubmit = async (values) => {
    try {
      await createExternalPartner(values).unwrap();
      if (!isLoadingCreateExternalPartner) {
        addPartnerFormik.resetForm();
        setOpenAdd(false);
        makeToastMessage(t("partners.addSuccessMessage"));
      }
    } catch (e) {
      makeErrorToastMessage(e.data.message);
    }
  };

  const addPartnerFormik = useFormik({
    initialValues: partnerInitialValues,
    validationSchema:
      agencyType == OTP_TYPE
        ? agencyPartnerExternalValidationOtp
        : agencyPartnerExternalValidationNonOtp,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleClose = () => {
    setOpenAdd(false);
    addPartnerFormik.resetForm();
  };

  useEffect(() => {
    setAgencyType(addPartnerFormik.values.partnerTypeId);
  }, [addPartnerFormik]);

  return (
    <>
      <AddPartnerModal
        open={openAdd}
        handleClose={handleClose}
        handleCloseBtn={handleClose}
        formik={addPartnerFormik}
        title={addPartnerButtonText}
      />
      <CustomAutoComplete
        name={props?.name}
        label={props?.label}
        style={props?.style}
        value={props?.value}
        formik={props?.formik}
        addButtonText={addPartnerButtonText}
        addButtonClick={() => setOpenAdd(true)}
        useQuery={useAgencyPartnerDropdownQuery}
        additionalOnChange={props?.setPassedAddressFlag}
        hasAdd={props?.hasAdd}
        error={props?.error}
        helperText={props?.helperText}
      />
    </>
  );
};

PartnerSelect.defaultProps = {
  hasAdd: true,
};
PartnerSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  formik: PropTypes.string,
  setPassedAddressFlag: PropTypes.func,
  hasAdd: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default PartnerSelect;
