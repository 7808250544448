/* eslint-disable react/prop-types */
import AccommodationSelect from "components/Inputs/AccommodationSelect";
import AccommodationTypeSelect from "components/Inputs/AccommodationTypeSelect";
import AccommodationUnitTypeSelect from "components/Inputs/AccommodationUnitTypeSelect";
import CategorySelect from "components/Inputs/CategorySelect";
import CountrySelect from "components/Inputs/CountrySelect";
import DatePick from "components/Inputs/DatePick";
import LocationSelect from "components/Inputs/LocationSelect";
import OfferTypeSelect from "components/Inputs/OfferTypeSelect";
import ServiceSelect from "components/Inputs/ServicesSelect";
import TransportationTypeSelect from "components/Inputs/TransportationTypeSelect";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";

const ExternalTravelDetailsForm = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <FormContainer>
      <InformationContainer>
        <InformationMainText>
          {t("externalSale.sections.travelDetails")}
        </InformationMainText>
      </InformationContainer>
      <RowContainer>
        <CategorySelect
          name={"categoryId"}
          label={t("field.categoryIdPlaceholder")}
          formik={formik}
          style={{ marginRight: "24px" }}
        />

        <OfferTypeSelect
          name="offerTypeId"
          label={t("field.offerTypePlaceholder")}
          formik={formik}
          style={{ marginRight: "24px" }}
          hasAdd={false}
        />

        <CountrySelect
          formik={formik}
          name="countryId"
          label={t("field.countryPlaceholder")}
          style={{ marginRight: "24px" }}
        />
        <LocationSelect
          formik={formik}
          name="locationId"
          disabled={!formik.values.countryId}
          label={t("field.locationPlaceholder")}
          countryId={formik.values.countryId}
        />
      </RowContainer>
      <RowContainer>
        <AccommodationTypeSelect
          name="accommodationTypeId"
          label={t("field.accommodationTypeIdPlaceholder")}
          formik={formik}
          style={{ marginRight: "24px" }}
          hasAdd={false}
        />
        <AccommodationSelect
          name="accommodationId"
          label={t("field.accommodationIdPlaceholder")}
          formik={formik}
          style={{ marginRight: "24px" }}
        />
        <AccommodationUnitTypeSelect
          name="accommodationUnitId"
          label={t("field.accommodationUnitTypeIdPlaceholder")}
          formik={formik}
          style={{ marginRight: "24px" }}
        />
        <ServiceSelect
          name="serviceId"
          label={t("field.serviceIdPlaceholder")}
          formik={formik}
        />
      </RowContainer>

      <RowContainer>
        <TransportationTypeSelect
          name="transportationTypeId"
          label={t("field.transportationTypePlaceholder")}
          formik={formik}
          style={{ marginRight: "24px" }}
          hasAdd={false}
        />
        <DatePick
          name="departureDate"
          label={t("field.departureDatePlaceholder")}
          value={formik.values?.departureDate}
          onChange={(value) => formik.setFieldValue("departureDate", value)}
          error={
            formik.touched.departureDate && Boolean(formik.errors.departureDate)
          }
          helperText={
            formik.touched.departureDate && formik.errors.departureDate
          }
          minDate={new Date()}
          style={{ marginRight: "24px" }}
        />
        <DatePick
          name="periodFrom"
          label={t("field.periodFromPlaceholderRequired")}
          value={formik.values?.periodFrom}
          onChange={(value) => formik.setFieldValue("periodFrom", value)}
          error={formik.touched.periodFrom && Boolean(formik.errors.periodFrom)}
          helperText={formik.touched.periodFrom && formik.errors.periodFrom}
          minDate={new Date()}
          style={{ marginRight: "24px" }}
        />
        <DatePick
          name="periodTo"
          label={t("field.periodToPlaceholderRequired")}
          value={formik.values?.periodTo}
          onChange={(value) => formik.setFieldValue("periodTo", value)}
          error={formik.touched.periodTo && Boolean(formik.errors.periodTo)}
          helperText={formik.touched.periodTo && formik.errors.periodTo}
          minDate={new Date()}
        />
      </RowContainer>
    </FormContainer>
  );
};

export default ExternalTravelDetailsForm;
