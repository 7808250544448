import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  CardWithTextContainer,
  PageCardText,
  PageCardTitle,
  IconAndTitleContainer,
  RightArrowStyled,
  PageCardHeader,
  CardContentContainer,
} from "./PageCardWithText.styled";
import { useTranslation } from "react-i18next";
import { UpperBar } from "styles/SharedStyle.styled";
import selectedTheme from "themes";

const PageCardWithText = ({ title, text, path, Icon }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <CardWithTextContainer onClick={() => navigate(path)}>
      <UpperBar color={selectedTheme.colors.primaryBlue} />
      <CardContentContainer>
        <PageCardHeader>
          <IconAndTitleContainer>
            <Icon fontSize="large" />
            <PageCardTitle>{t(title)}</PageCardTitle>
          </IconAndTitleContainer>
          <RightArrowStyled />
        </PageCardHeader>
        <PageCardText>{t(text)}</PageCardText>
      </CardContentContainer>
    </CardWithTextContainer>
  );
};

PageCardWithText.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  path: PropTypes.string,
  Icon: PropTypes.object,
};

export default PageCardWithText;
