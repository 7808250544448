import DatePick from "components/Inputs/DatePick";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import { useAgencyPartnerDropdownQuery } from "features/agencyPartner/agencyPartnerApiSlice";

const ExternalBasicInformation = ({ formik, isAdd }) => {
  const { t } = useTranslation();
  const { data: partners, isLoadingAgnecyPartners } =
    useAgencyPartnerDropdownQuery({ isOTP: true });
  return (
    <FormContainer>
      <InformationContainer>
        <InformationMainText>
          {t("externalSale.sections.basicInformation")}
        </InformationMainText>
      </InformationContainer>
      <RowContainer>
        <TextInput
          fullWidth
          name="number"
          label={t("field.contractNumberPlaceholderRequired")}
          value={formik.values?.number || ""}
          onChange={formik.handleChange}
          style={{ marginRight: "24px" }}
          error={formik.touched.number && Boolean(formik.errors.number)}
          helperText={formik.touched.number && formik.errors.number}
          disabled={!isAdd}
        />
        <DatePick
          name="date"
          label={t("field.contractDatePlaceholderRequired")}
          value={formik.values?.date}
          onChange={(value) => formik.setFieldValue("date", value, true)}
          error={formik.touched.date && Boolean(formik.errors.date)}
          helperText={formik.touched.date && formik.errors.date}
          style={{ marginRight: "24px" }}
          disabled={!isAdd}
        />
        <SelectInput
          fullWidth
          name="otpId"
          label={t("field.travelOrganizerPlaceholderRequired")}
          value={formik.values?.otpId || ""}
          style={{ marginRight: "24px" }}
          onChange={formik.handleChange}
          items={!isLoadingAgnecyPartners && partners}
          error={formik.touched.otpId && Boolean(formik.errors.otpId)}
          helperText={formik.touched.otpId && formik.errors.otpId}
        />
        <TextInput
          fullWidth
          name="jid"
          label={t("field.jidPlaceholder")}
          value={formik.values?.jid || ""}
          onChange={formik.handleChange}
        />
      </RowContainer>
    </FormContainer>
  );
};
ExternalBasicInformation.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    touched: PropTypes.object,
    errors: PropTypes.object,
  }).isRequired,
  isAdd: PropTypes.bool,
};

export default ExternalBasicInformation;
