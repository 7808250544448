import { Box, IconButton, Tooltip } from "@mui/material";
import ReceiptForm from "components/Forms/ReceiptForm/ReceiptForm";
import Title from "components/Title/Title";
import { PAGES } from "constants/pages";
import tooltipPlacements from "constants/tooltipPlacements";
import { selectCurrentAgency, selectCurrentToken } from "features/auth/authSlice";
import {
  useAddReceiptMutation,
  useEditReceiptMutation,
  useReceiptDetailsQuery,
} from "features/receipts/receiptsSlice";
import { useTemplateAgencyInfoGetByAgencyQuery } from "features/templateAgencyInfo/templateAgencyInfoApiSlice";
import { useFormik } from "formik";
import receiptsInitialValues from "initialValues/receiptsInitialValues";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "styles/SharedStyle.styled";
import { convertDateToISO } from "util/dateHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import receiptsValidation from "validations/receiptsValidation";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import selectedTheme from "themes";

const AddEditReceiptPage = () => {
  const { t } = useTranslation();
   const token = useSelector(selectCurrentToken);
  const { id } = useParams();
  const path = useLocation().pathname;
  const isAdd = path === PAGES.ADDRECEIPT.route;
  const navigate = useNavigate();
  const agencyId = useSelector(selectCurrentAgency);
  const { data: receiptDescription } = useTemplateAgencyInfoGetByAgencyQuery({
    agencyId,
  });
  const [addReceipt, { isLoading: isLoadingAddReceipt }] =
    useAddReceiptMutation();
  const [editReceipt, { isLoading: isLoadingEditReceipt }] =
    useEditReceiptMutation();
  const { data: receiptDetails, isLoading: isLoadingReceiptDetails } =
    useReceiptDetailsQuery({ id }, { skip: isAdd });

  const handlePrintReceipt = async () => {
    // eslint-disable-next-line no-undef
    await fetch(`${process.env.REACT_APP_API_URI}/print/receipt?Id=${Number(id)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `Priznanica-${formik?.values?.receiptNumber}-${Date.now()}.pdf`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      }); // eslint-disable-line
  };

  const handleSubmit = async (values, action) => {
    if (isAdd) {
      try {
        await addReceipt({
          ...values,
          date: convertDateToISO(values.date),
          cashAmount: parseFloat(values.cashAmount) || 0,
          cardAmount: parseFloat(values.cardAmount) || 0,
          checkAmount: parseFloat(values.checkAmount) || 0,
          totalAmount: parseFloat(values.totalAmount) || 0,
        })
          .unwrap()
          .then(() => {
            if (!isLoadingAddReceipt) {
              navigate(PAGES.RECEIPTS.route);
              makeToastMessage(t("payment.addSuccessMessage"));
            }
          });
      } catch (e) {
        makeErrorToastMessage(e.data.message);
      }
    }

    if (id) {
      try {
        await editReceipt({
          ...values,
          date: convertDateToISO(values.date),
          cashAmount: parseFloat(values.cashAmount) || 0,
          cardAmount: parseFloat(values.cardAmount) || 0,
          checkAmount: parseFloat(values.checkAmount) || 0,
          totalAmount: parseFloat(values.totalAmount) || 0,
        })
          .unwrap()
          .then(() => {
            if (!isLoadingEditReceipt) {
              navigate(PAGES.RECEIPTS.route);
              makeToastMessage(t("payment.editSuccessMessage"));
            }
          });
      } catch (e) {
        makeErrorToastMessage(e.data.message);
      }
    }
    action.setSubmitting(false);
  };
  const formik = useFormik({
    initialValues:
      id && !isLoadingReceiptDetails
        ? {
            ...receiptDetails,
            date:
              receiptDetails?.date !== null
                ? new Date(receiptDetails?.date)
                : null,
            receiptNotice:
              receiptDetails?.receiptNotice !== null
                ? receiptDetails?.receiptNotice
                : receiptDescription?.receiptText,
          }
        : {
            ...receiptsInitialValues,
            receiptNotice: receiptDescription?.receiptText,
          },
    validationSchema: receiptsValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        style={{ marginBottom: 24 }}
      >
        <Title
          title={
            isAdd
              ? t("payment.addTitle")
              : t("payment.editTitle", {
                  number: receiptDetails?.receiptNumber,
                })
          }
        />
        {!isAdd && (
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Tooltip title={t("common.pdf")} placement={tooltipPlacements.TOP}>
              <IconButton onClick={handlePrintReceipt}>
                <PictureAsPdfIcon
                  style={{
                    width: 32,
                    height: 32,
                    color: selectedTheme.colors.background2,
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <ReceiptForm formik={formik} />
    </PageContainer>
  );
};

export default AddEditReceiptPage;
