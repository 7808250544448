import React from "react";
import {
  codebookPageNavigationLinks,
  codebookPageNavigationLinksSuperAdmin,
} from "constants/navigationConstants";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/SharedStyle.styled";
import Title from "components/Title/Title";
import { CoodebookPageCardContainer } from "./CodebookPage.styled";
import useAuth from "hooks/useAuth";
import { SUPERADMIN_ROLE } from "constants/roleConstants";
import PageCardWithText from "components/Cards/PageCard/PageCardWithText";

const CodebookPage = () => {
  const { t } = useTranslation();
  const { hasRole } = useAuth();
  const navigationLinks = hasRole(SUPERADMIN_ROLE)
    ? codebookPageNavigationLinksSuperAdmin
    : codebookPageNavigationLinks;

  return (
    <PageContainer>
      <Title title={t("pages.codebook")} />
      <CoodebookPageCardContainer>
        {navigationLinks.map((link) => (
          <PageCardWithText
            key={link.title}
            path={link.path}
            Icon={link.icon}
            title={link.title}
            text={link.text}
          />
        ))}
      </CoodebookPageCardContainer>
    </PageContainer>
  );
};

export default CodebookPage;
