import React from "react";

import { ReactComponent as MainIconSvg } from "../../../assets/images/svg/MasterOfTravelSecondary.svg";
import { ReactComponent as LogoIcon } from "../../../assets/images/svg/logoSecondary.svg";
import styled from "styled-components";

const MainIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const MainIcon = () => {
  return (
    <MainIconContainer>
      <LogoIcon />
      <MainIconSvg />
    </MainIconContainer>
  );
};

MainIcon.propTypes = {};

export default MainIcon;
