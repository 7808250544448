export const statementItemFormikParams = {
    CREDIT_DEBIT: "creditDebit",
    CLIENT_PARTNER: "clientPartner",
    PARTNER_ID: "partnerId",
    CLIENT_ID: "clientId",
    INVOICE: "invoice",
    RESERVATION_NUMBER: "reservationNumber",
    CONTRACT_NUMBER: "contractId",
    DESCRIPTION: "description",
    AMOUNT: "amount",
    CURRENCY_ID: "currencyId",
    FOREIGN_AMOUNT: "foreignAmount",
    EXCHANGE_RATE: "exchangeRate",
    TOTAL_AMOUNT: "totalAmount",
  };
  