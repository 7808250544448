import React from "react";
import PropTypes from "prop-types";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DatePick from "components/Inputs/DatePick";
import tooltipPlacements from "constants/tooltipPlacements";

const DateFilter = (props) => {
  const { t } = useTranslation();
  const resetFilterValue = () => {
    if (props?.filterForm?.values?.[props?.name]) {
      props?.filterForm.setFieldValue(props.name, null);
      if (props?.appliedFilters?.[props?.name]) {
        props?.filterForm.handleSubmit();
      }
    }
  };

  return (
    <DatePick
      name={props.name}
      label={t(props.label)}
      onChange={(value) => {
        props?.filterForm.setFieldValue([props?.name], value);
      }}
      value={props?.filterForm?.values?.[props?.name]}
      selected={
        props?.filterForm.values.From
          ? new Date(props?.filterForm.values.From)
          : null
      }
      maxDate={props?.maxDate}
      slots={{
        textField: (params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps?.endAdornment}
                  <InputAdornment position="end">
                    <IconButton onClick={resetFilterValue}>
                      <Tooltip
                        title={t("common.resetFilter")}
                        placement={tooltipPlacements.TOP}
                      >
                        <RestartAltIcon />
                      </Tooltip>
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />
        ),
      }}
      {...props}
    />
  );
};

DateFilter.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  filterForm: PropTypes.object,
  maxDate: PropTypes.instanceOf(Date),
  appliedFilters: PropTypes.array,
};

export default DateFilter;
