/* eslint-disable react/prop-types */
import React from "react";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import { TableContainer } from "./CustomTable.styled";
import { CircularProgress } from "@mui/material";
import CustomTableFooter from "./CustomTableFooter";
import selectedTheme from "themes";

const CustomTable = ({
  data,
  columns,
  additionalOptions,
  title = "",
  setPage,
  setRowsPerPage,
  setSort,
  setSearch,
  isLoading,
  rowsPerPage,
  page,
  count,
  rowsName,
}) => {
  const options = {
    serverSide: true,
    rowHover: false,
    page: page,
    count: count,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 25, 50],
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    customToolbar: null,
    responsive: "vertical",
    selectableRowsHideCheckboxes: true,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor:
            rowIndex % 2 !== 0
              ? selectedTheme.colors.white
              : selectedTheme.colors.tableRowSecondColor,
        },
      };
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "changeRowsPerPage":
          setPage(0);
          setRowsPerPage(tableState.rowsPerPage);
          break;
        case "sort":
          setPage(0);
          setSort(tableState.sortOrder);
          break;
        case "search":
          setPage(0);
          setSearch(tableState.searchText);
          break;
      }
    },
    customSearchRender: debounceSearchRender(1000),
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress color="primary" component="span" />
        ) : (
          "Nema rezultata"
        ),
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sortiraj ${column.label}`,
      },
      pagination: {
        next: "Sledeća Strana",
        previous: "Predhodna Strana",
        rowsPerPage: `Broj ${rowsName}`,
        displayRows: "od",
      },
      toolbar: {
        search: "Pretraga",
        downloadCsv: "Skinite CSV",
        print: "Štampaj",
        viewColumns: "Vidi kolone",
        filterTable: "Filtriranje tabela",
      },
      filter: {
        all: "Svi",
        title: "Filteri",
        reset: "Reset filtera",
      },
      viewColumns: {
        title: "Prikaži kolone",
        titleAria: "Prikaži/Sakrij Kolone Tabele",
      },
    },
  };

  const components = {
    TableFooter: function (props) {
      return (
        <CustomTableFooter
          options={props.options}
          page={page}
          count={count}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsName={rowsName}
        />
      );
    },
  };

  return (
    <TableContainer>
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={{ ...options, ...additionalOptions }}
        components={components}
      />
    </TableContainer>
  );
};

export default CustomTable;
