import { Button } from "@mui/material";
import PropTypes from "prop-types";
import CustomTable from "components/CustomTable/CustomTable";
import { PAGES } from "constants/pages";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ButtonContainer,
  ContentContainer,
  PageHeader,
} from "styles/SharedStyle.styled";
import useBankAccountTable from "hooks/tables/useBankAccountTable";
import Filter from "components/Filter/Filter";
import BankAccountFilter from "components/Filter/BankAccountFilter/BankAccountFilter";
import { useFormik } from "formik";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import useAuth from "hooks/useAuth";
import {
  useBankAccountListQuery,
  useDeleteBankAccountMutation,
} from "features/bankAccount/bankAccountApiSlice";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import modalConstants from "constants/modalConstants";
import { closeModal } from "features/modal/modalSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import buttonVariants from "constants/buttonVariants";
import selectedTheme from "themes";

const ListAddBankAccount = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isManager } = useAuth();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);
  const modals = useSelector((state) => state.modal.modals);
  const deleteBankAccountModal =
    modals?.[modalConstants.DELETE_BANK_ACCOUNT_MODAL];
  const { columns } = useBankAccountTable({ page, rowsPerPage });

  const [deleteBankAccount, { isLoading: isLoadingDeleteBankAccount }] =
    useDeleteBankAccountMutation();

  const [appliedFilters, setAppliedFilters] = useState({});
  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
    setAppliedFilters(getNonEmptyProps(values));
  };

  const { data: bankAccounts, isLoading: isLoadingBankAccounts } =
    useBankAccountListQuery({
      page,
      rowsPerPage,
      sort,
      filter,
      agencyId: Number(id),
    });

  const filterForm = useFormik({
    initialValues: { BankName: "", AccountNumber: "" },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleCancelConfirm = () => {
    if (deleteBankAccountModal?.open) {
      dispatch(closeModal({ id: modalConstants.DELETE_BANK_ACCOUNT_MODAL }));
    }
  };

  const handleConfirm = () => {
    if (deleteBankAccountModal?.open) {
      deleteBankAccount({
        id: deleteBankAccountModal?.data.id,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteBankAccount) {
            dispatch(
              closeModal({ id: modalConstants.DELETE_BANK_ACCOUNT_MODAL })
            );
            makeToastMessage(t("bankAccount.deleteSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("bankAccount.deleteErrorMessage")
          )
        );
    }
  };

  return (
    <ContentContainer>
      <ConfirmModal
        open={deleteBankAccountModal?.open}
        handleCancel={handleCancelConfirm}
        handleConfirm={handleConfirm}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("bankAccount.confirmDelete")}
        isConfirmDisabled={isLoadingDeleteBankAccount}
      />
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <BankAccountFilter
            filterForm={filterForm}
            appliedFilters={appliedFilters}
          />
        </Filter>
        {isManager && (
          <ButtonContainer>
            <Button
              variant={buttonVariants.CONTAINED}
              onClick={() =>
                navigate(PAGES.ADDBANKACCOUNT.route, {
                  state: { agencyId: Number(id) },
                })
              }
              style={{
                backgroundColor: selectedTheme.colors.primaryBlue,
              }}
            >
              {t("bankAccount.addButton")}
            </Button>
          </ButtonContainer>
        )}
      </PageHeader>
      <CustomTable
        columns={columns}
        data={bankAccounts?.data}
        isLoading={isLoadingBankAccounts}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSort={setSort}
        rowsName={t("bankAccount.rowsName")}
        count={bankAccounts?.totalCount}
      />
    </ContentContainer>
  );
};

ListAddBankAccount.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ListAddBankAccount;
