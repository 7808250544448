import { DOMESTIC_TYPE, FOREIGN_TYPE } from "constants/statementType";

export const getStatementItemsFields = (dataArray, initialValues) => {
  if (dataArray?.length) {
    return {
      statementId: "",
      statementItems: dataArray.map((data) => {
        const { clientId, partnerId, ...rest } = data;
        let result = { ...rest };
        if (clientId) {
          result.clientPartner = 2;
          result.clientId = clientId;
        } else if (partnerId) {
          result.clientPartner = 1;
          result.partnerId = partnerId;
        }
        const initialKeys = Object.keys(initialValues.statementItems[0]);
        Object.keys(result)?.forEach((key) => {
          if (!initialKeys.includes(key) && key !== "id") {
            delete result[key];
          }
        });
        return result;
      }),
    };
  }
  return initialValues;
};

export const getStatementFields = (dataArray, statementType, partners, statement, rsdCurrency) => {
  return dataArray.map((data) => {
    const { clientPartner, ...rest } = data;
    let result = { ...rest };

    result.statementId = Number(statement);

    if (clientPartner === 1) {
      delete result.clientId;
      const partner = partners?.find(
        (partner) => partner.value === data.partnerId
      );
      if (partner) {
        result.partnerOnly = partner.partnerOnly;
      }
    } else if (clientPartner === 2) {
      delete result.partnerId;
    }

    if (statementType === DOMESTIC_TYPE) {
      result.currencyId = rsdCurrency;
      result.amount = parseFloat(result.amount);
      delete result.foreignAmount;
      delete result.exchangeRate;
      delete result.totalAmount;
    } else if (statementType === FOREIGN_TYPE) {
      result.exchangeRate =  parseFloat(result.exchangeRate);
      result.foreignAmount = parseFloat(result.foreignAmount);
      result.totalAmount = parseFloat(result.totalAmount);
      delete result.amount;
    }

    return result;
  });
};
