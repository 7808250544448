import PropTypes from "prop-types";
import TextInput from "components/Inputs/TextInput";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";

const ExternalDescription = ({formik}) => {
  const { t } = useTranslation();
  return (
    <FormContainer>
      <InformationContainer>
        <InformationMainText>
          {t("externalSale.sections.description")}
        </InformationMainText>
      </InformationContainer>
      <RowContainer>
        <TextInput
          fullWidth
          multiline
          minRows={4}
          maxRows={8}
          name="description"
          label={t("field.internalDescriptionPlaceholder")}
          value={formik?.values?.description || ""}
          onChange={formik.handleChange}
        />
        </RowContainer>
        <RowContainer>
        <TextInput
          fullWidth
          multiline
          minRows={4}
          maxRows={8}
          name="contractNotice"
          label={t("field.descriptionPlaceholder")}
          value={formik?.values?.contractNotice || ""}
          onChange={formik.handleChange}
        />
      </RowContainer>
    </FormContainer>
  );
};
ExternalDescription.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object,
    handleChange: PropTypes.func,
    touched: PropTypes.object,
    errors: PropTypes.object,
  }).isRequired,
};
export default ExternalDescription;
