/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import React from "react";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomDialog from "../CustomDialog/CustomDialog";
import { useTranslation } from "react-i18next";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import { useOfferTypeDetailsLevelDropdownQuery } from "features/offerType/offerTypeApiSlice";
import CountrySelect from "components/Inputs/CountrySelect";
import LocationSelect from "components/Inputs/LocationSelect";
import AccommodationTypeSelect from "components/Inputs/AccommodationTypeSelect";
import ModalActions from "../ModalActions/ModalActions";

const AddEditCodebookItemModal = ({
  open,
  handleClose,
  formik,
  handleCloseBtn,
  title,
  labelTitle,
  type,
}) => {
  const { t } = useTranslation();

  const { data: offerDetailsLevels } = useOfferTypeDetailsLevelDropdownQuery(
    {},
    { skip: !open || type !== "offerType" }
  );

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title={title}
    >
      <Box component="form">
        {type === "accommodation" && (
          <RowContainer>
            <AccommodationTypeSelect
              name="accommodationTypeId"
              label={t("field.accommodationTypeIdPlaceholderRequired")}
              formik={formik}
              style={{ marginBottom: formik.errors.accommodationTypeId ? 24 : 0 }}
            />
          </RowContainer>
        )}
        {(type === "accommodation" || type === "location") && (
          <RowContainer>
            <CountrySelect
              formik={formik}
              name="countryId"
              label={t("field.countryIdPlaceholder")}
            />
          </RowContainer>
        )}

        {type === "accommodation" && (
          <RowContainer>
            <LocationSelect
              name="locationId"
              disabled={!formik.values.countryId}
              label={t("field.locationIdPlaceholder")}
              formik={formik}
              countryId={formik.values.countryId}
            />
          </RowContainer>
        )}

        <RowContainer>
          <TextInput
            fullWidth
            name="name"
            label={labelTitle}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </RowContainer>

        {type === "currency" && (
          <RowContainer>
            <TextInput
              fullWidth
              name="mark"
              label={t("field.currencyMarkPlaceholderRequired")}
              value={formik.values.mark}
              onChange={(event) =>
                formik.setFieldValue("mark", event.target.value.toUpperCase())
              }
              error={formik.touched.mark && Boolean(formik.errors.mark)}
              helperText={formik.touched.mark && formik.errors.mark}
            />
          </RowContainer>
        )}

        {type === "offerType" && (
          <RowContainer>
            <SelectInput
              fullWidth
              name="offerDetailsLevelId"
              label={t("field.offerDetailsLevelPlaceholder")}
              onChange={formik.handleChange}
              value={formik.values.offerDetailsLevelId}
              error={
                formik.touched.offerDetailsLevelId &&
                Boolean(formik.errors.offerDetailsLevelId)
              }
              helperText={
                formik.touched.offerDetailsLevelId &&
                formik.errors.offerDetailsLevelId
              }
              items={offerDetailsLevels}
            />
          </RowContainer>
        )}
        <ModalActions
          onClose={handleCloseBtn}
          disabled={!formik.dirty || formik.isSubmitting}
          onAction={formik.handleSubmit}
        />
      </Box>
    </CustomDialog>
  );
};

export default AddEditCodebookItemModal;
