import styled from "styled-components";
import selectedTheme from "themes";

export const RowContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const FormContainer = styled.div`
  border: 1px solid ${selectedTheme?.colors?.FormContainerBorderColor};
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  padding: 24px;
  background-color: ${selectedTheme?.colors?.white};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
