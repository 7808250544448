/* eslint-disable react/prop-types */
import { Button, Typography } from "@mui/material";
import React from "react";
import {
  ConfirmButton,
  DialogActionsContainer,
  DialogContainer,
  DialogMessageContainer,
  DialogStyled,
} from "./ConfirmCloseDialog.styled";
import { useTranslation } from "react-i18next";
import buttonVariants from "constants/buttonVariants";
import { UpperBar } from "styles/SharedStyle.styled";
import selectedTheme from "themes";

const ConfirmCloseDialog = ({
  open,
  handleClose,
  handleCancel,
  description,
}) => {
  const { t } = useTranslation();
  return (
    <DialogStyled open={open}>
      <UpperBar color={selectedTheme.colors.primaryBlue} />
      <DialogContainer>
        <DialogMessageContainer>
          <Typography>{description}</Typography>
        </DialogMessageContainer>
        <DialogActionsContainer>
          <ConfirmButton
            variant={buttonVariants.CONTAINED}
            onClick={handleClose}
            style={{ marginRight: "24px" }}
          >
            {t("common.confirm")}
          </ConfirmButton>
          <Button variant={buttonVariants.OUTLINED} onClick={handleCancel}>
            {t("common.cancel")}
          </Button>
        </DialogActionsContainer>
      </DialogContainer>
    </DialogStyled>
  );
};

export default ConfirmCloseDialog;
