import React from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import { useTranslation } from "react-i18next";
import TextInput from "components/Inputs/TextInput";
import SelectInput from "components/Inputs/SelectInput";
import FormActions from "../FormActions/FormActions";
import { useLazyExchangeRateOnDateQuery } from "features/exchange-rate/exchangeRateSlice";
import { convertDateToISO, getOneDayBeforeDate } from "util/dateHelpers";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import {
  StatementItemRow,
  StatementOrdinalNumber,
} from "./StatementItemsForm.styled";
import {
  DOMESTIC_TYPE,
  FOREIGN_TYPE,
  statementTypes,
} from "constants/statementType";
import {
  StatementItemRemoveAction,
  StatementItemsContainer,
} from "./StatementItemForm.styled";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import CurrencySelect from "components/Inputs/CurrencySelect";
import { useCurrencyDropdownQuery } from "features/currency/currencyApiSlice";
import ClientSelect from "components/Inputs/ClientSelect";
import PartnerSelect from "components/Inputs/PartnerSelect";
import { clientPartners } from "constants/clientPartner";
import { isValidInput } from "util/helpers/numberHelpers";
import statementItemsInitialValues from "initialValues/statementItemsInitialValues";
import selectedTheme from "themes";
import buttonVariants from "constants/buttonVariants";
import { statementItemFormikParams } from "constants/formik/statementItemFormikParams";
import { useContractDropdownQuery } from "features/contract/contractApiSlice";

const StatementItemForm = ({
  formik,
  date,
  statementType,
  isEdit,
  isLoading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const statementItems = formik.values?.statementItems || [];
  const error = formik.errors?.statementItems || [];
  const touched = formik.touched?.statementItems || [];

  const { data: currencies } = useCurrencyDropdownQuery();

  const [exchangeRate] = useLazyExchangeRateOnDateQuery();

  const { data: contractNumbers, isLoading: isLoadingContractNumbers } =
    useContractDropdownQuery();

  const calculateForeignTotalAmount = (foreignAmount, exchangeRate) => {
    return parseFloat(foreignAmount * exchangeRate).toFixed(2);
  };
  const handleInputChange = async (index, field, value) => {
    const updatedStatementItems = [...statementItems];
    updatedStatementItems[index] = {
      ...updatedStatementItems[index],
      [field]: value,
    };

    if (field === statementItemFormikParams.CREDIT_DEBIT && value === -1) {
      updatedStatementItems[index].invoice = "";
    }

    if (field === statementItemFormikParams.CLIENT_PARTNER) {
      updatedStatementItems[index].clientId = null;
      updatedStatementItems[index].partnerId = null;
    }

    if (field === statementItemFormikParams.CURRENCY_ID) {
      const { data } = await exchangeRate(
        {
          currencyId: value,
          date: convertDateToISO(getOneDayBeforeDate(date)),
        },
        { skip: value }
      );
      updatedStatementItems[index].exchangeRate =
        data?.rate > 0 ? data?.rate : "";
    }

    if (statementType === FOREIGN_TYPE) {
      updatedStatementItems[index].totalAmount = calculateForeignTotalAmount(
        updatedStatementItems[index].foreignAmount,
        updatedStatementItems[index].exchangeRate
      );
    }
    formik.setFieldValue("statementItems", updatedStatementItems);
  };

  return (
    <Box component="form">
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("statement.sections.statementItemsInfo")}
          </InformationMainText>
        </InformationContainer>
        <>
          {formik?.values.statementItems &&
            formik.values.statementItems.map((item, index) => (
              <React.Fragment key={index}>
                <StatementItemRow>
                  <StatementOrdinalNumber>
                    <TextInput
                      fullWidth
                      label="R. br."
                      value={index + 1}
                      style={{ marginRight: "24px" }}
                    />
                  </StatementOrdinalNumber>
                  <StatementItemsContainer>
                    <RowContainer
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      <SelectInput
                        fullWidth
                        name={statementItemFormikParams.CREDIT_DEBIT}
                        label={t("field.creditDebitPlaceholderRequired")}
                        value={statementItems[index]?.creditDebit}
                        onChange={(e) => {
                          handleInputChange(
                            index,
                            statementItemFormikParams.CREDIT_DEBIT,
                            parseInt(e.target.value)
                          );
                        }}
                        error={
                          touched[index]?.creditDebit &&
                          Boolean(error[index]?.creditDebit)
                        }
                        helperText={
                          touched[index]?.creditDebit &&
                          error[index]?.creditDebit
                        }
                        style={{ marginRight: "24px" }}
                        items={statementTypes}
                      />
                      <SelectInput
                        fullWidth
                        name={statementItemFormikParams.CLIENT_PARTNER}
                        label={t("field.clientPartnerPlaceholderRequired")}
                        value={statementItems[index]?.clientPartner}
                        onChange={(e) => {
                          handleInputChange(
                            index,
                            statementItemFormikParams.CLIENT_PARTNER,
                            e.target.value
                          );
                        }}
                        style={{ marginRight: "24px" }}
                        error={
                          touched[index]?.clientPartner &&
                          Boolean(error[index]?.clientPartner)
                        }
                        helperText={
                          touched[index]?.clientPartner &&
                          error[index]?.clientPartner
                        }
                        items={clientPartners}
                      />
                      {formik.values.statementItems[index].clientPartner ===
                        1 && (
                        <PartnerSelect
                          name={`statementItems[${index}].partnerId`}
                          label={t("field.partnerPlaceholderRequired")}
                          style={{ marginRight: "24px" }}
                          formik={formik}
                          value={statementItems[index]?.partnerId}
                          error={
                            touched[index]?.partnerId &&
                            Boolean(error[index]?.partnerId)
                          }
                          helperText={
                            touched[index]?.partnerId && error[index]?.partnerId
                          }
                        />
                      )}
                      {formik.values.statementItems[index].clientPartner ===
                        2 && (
                        <ClientSelect
                          name={`statementItems[${index}].clientId`}
                          label={t("field.clientPlaceholderRequired")}
                          style={{ marginRight: "24px" }}
                          formik={formik}
                          value={statementItems[index]?.clientId}
                          error={
                            touched[index]?.clientId &&
                            Boolean(error[index]?.clientId)
                          }
                          helperText={
                            touched[index]?.clientId && error[index]?.clientId
                          }
                        />
                      )}

                      {formik.values.statementItems[index].creditDebit ===
                        1 && (
                        <TextInput
                          fullWidth
                          name={statementItemFormikParams.INVOICE}
                          label={t("field.invoiceNumberPlaceholderRequired")}
                          value={statementItems[index]?.invoice}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              statementItemFormikParams.INVOICE,
                              e.target.value
                            )
                          }
                          style={{ marginRight: "24px" }}
                          error={
                            touched[index]?.invoice &&
                            Boolean(error[index]?.invoice)
                          }
                          helperText={
                            touched[index]?.invoice && error[index]?.invoice
                          }
                        />
                      )}

                      <Autocomplete
                        fullWidth
                        id={statementItemFormikParams.CONTRACT_NUMBER}
                        options={
                          isLoadingContractNumbers ? [] : contractNumbers
                        }
                        getOptionLabel={(option) => option.text}
                        value={
                          !isLoadingContractNumbers
                            ? contractNumbers.find(
                                (option) =>
                                  option.value ===
                                  statementItems[index]?.contractId
                              ) || null
                            : null
                        }
                        onChange={(event, newValue) => {
                          const updatedStatementItems = [...statementItems];
                          updatedStatementItems[index] = {
                            ...updatedStatementItems[index],
                            contractId: newValue ? newValue.value : null,
                          };
                          formik.setFieldValue("statementItems", updatedStatementItems);
                        }}
                        renderInput={(params) => (
                          <TextInput
                            {...params}
                            name={statementItemFormikParams.CONTRACT_NUMBER}
                            label={t("field.reservationNumberPlaceholder")}
                            error={
                              touched?.contractId && Boolean(error?.contractId)
                            }
                            helperText={
                              touched?.contractId && error?.contractId
                            }
                          />
                        )}
                      />
                    </RowContainer>
                    <RowContainer>
                      <TextInput
                        fullWidth
                        name={statementItemFormikParams.DESCRIPTION}
                        label={t(
                          "field.statementDescriptionPlaceholderRequired"
                        )}
                        value={statementItems[index]?.description}
                        error={
                          touched[index]?.description &&
                          Boolean(error[index]?.description)
                        }
                        helperText={
                          touched[index]?.description &&
                          error[index]?.description
                        }
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            statementItemFormikParams.DESCRIPTION,
                            e.target.value
                          )
                        }
                        style={{ marginRight: "24px" }}
                      />
                      {statementType === DOMESTIC_TYPE && (
                        <TextInput
                          fullWidth
                          name={statementItemFormikParams.AMOUNT}
                          label={t("field.amountPlaceholderRequired")}
                          value={statementItems[index]?.amount}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue === "" || isValidInput(inputValue)) {
                              handleInputChange(
                                index,
                                statementItemFormikParams.AMOUNT,
                                inputValue
                              );
                            }
                          }}
                          error={
                            touched[index]?.amount &&
                            Boolean(error[index]?.amount)
                          }
                          helperText={
                            touched[index]?.amount && error[index]?.amount
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                RSD
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                      {statementType === FOREIGN_TYPE && (
                        <>
                          <CurrencySelect
                            name={statementItemFormikParams.CURRENCY_ID}
                            label={t("field.currencyPlaceholderRequired")}
                            onChange={(e) => {
                              handleInputChange(
                                index,
                                statementItemFormikParams.CURRENCY_ID,
                                e.target.value
                              );
                            }}
                            value={statementItems[index]?.currencyId}
                            items={currencies}
                            fullWidth
                            error={
                              touched[index]?.currencyId &&
                              Boolean(error[index]?.foreignAmount)
                            }
                            helperText={
                              touched[index]?.currencyId &&
                              error[index]?.currencyId
                            }
                            style={{ marginRight: 24 }}
                          />
                          <TextInput
                            fullWidth
                            name={statementItemFormikParams.FOREIGN_AMOUNT}
                            label={t("field.foreignAmountPlaceholderRequired")}
                            value={statementItems[index]?.foreignAmount}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === "" ||
                                isValidInput(inputValue)
                              ) {
                                handleInputChange(
                                  index,
                                  statementItemFormikParams.FOREIGN_AMOUNT,
                                  inputValue
                                );
                              }
                            }}
                            style={{ marginRight: "24px" }}
                            error={
                              touched[index]?.foreignAmount &&
                              Boolean(error[index]?.foreignAmount)
                            }
                            helperText={
                              touched[index]?.foreignAmount &&
                              error[index]?.foreignAmount
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {
                                    currencies?.find(
                                      (currency) =>
                                        currency.value ===
                                        statementItems[index].currencyId
                                    )?.text
                                  }
                                </InputAdornment>
                              ),
                            }}
                          />

                          <TextInput
                            fullWidth
                            name={statementItemFormikParams.EXCHANGE_RATE}
                            label={t("field.ratePlaceholderRequired")}
                            value={statementItems[index].exchangeRate}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                inputValue === "" ||
                                isValidInput(inputValue, 4)
                              ) {
                                handleInputChange(
                                  index,
                                  statementItemFormikParams.EXCHANGE_RATE,
                                  inputValue
                                );
                              }
                            }}
                            error={
                              touched[index]?.exchangeRate &&
                              Boolean(error[index]?.exchangeRate)
                            }
                            helperText={
                              touched[index]?.exchangeRate &&
                              error[index]?.exchangeRate
                            }
                            style={{ marginRight: "24px" }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {
                                    currencies?.find(
                                      (currency) =>
                                        currency.text.toLowerCase() === "rsd"
                                    ).text
                                  }
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextInput
                            fullWidth
                            disabled
                            name={statementItemFormikParams.TOTAL_AMOUNT}
                            label={t("field.totalAmountPlaceholderRequired")}
                            value={statementItems[index].totalAmount}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {
                                    currencies?.find(
                                      (currency) =>
                                        currency.text.toLowerCase() === "rsd"
                                    ).text
                                  }
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      )}
                    </RowContainer>
                  </StatementItemsContainer>
                  <StatementItemRemoveAction>
                    <IconButton
                      color="error"
                      onClick={() => {
                        if (isEdit) {
                          const updatedItems =
                            formik.values.statementItems.filter(
                              (_, i) => i !== index
                            );
                          formik.setFieldValue("statementItems", updatedItems);
                        }
                        if (!isEdit) {
                          if (formik.values.statementItems.length > 1) {
                            const updatedItems =
                              formik.values.statementItems.filter(
                                (_, i) => i !== index
                              );
                            formik.setFieldValue(
                              "statementItems",
                              updatedItems
                            );
                          } else {
                            formik.setFieldValue(
                              "statementItems",
                              statementItemsInitialValues.statementItems
                            );
                          }
                        }
                      }}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </StatementItemRemoveAction>
                </StatementItemRow>
                {index !== formik.values.statementItems.length - 1 && (
                  <Divider style={{ marginBottom: 24 }} />
                )}
              </React.Fragment>
            ))}
          <Box style={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant={buttonVariants.CONTAINED}
              color="primary"
              startIcon={<AddIcon />}
              onClick={() =>
                formik.setFieldValue("statementItems", [
                  ...formik.values.statementItems,
                  {
                    ...statementItemsInitialValues.statementItems[0],
                  },
                ])
              }
              style={{
                backgroundColor: selectedTheme.colors.primaryBlue,
              }}
            >
              {t("statement.addNewItem")}
            </Button>
          </Box>
        </>
      </FormContainer>
      <FormActions
        disabled={!formik.dirty || isLoading}
        handleConfirm={formik.handleSubmit}
        handleCancel={() => {
          navigate(PAGES.STATEMENTS.route);
        }}
      />
    </Box>
  );
};

StatementItemForm.propTypes = {
  isLoading: PropTypes.bool,
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  date: PropTypes.string,
  statementId: PropTypes.number,
  statementType: PropTypes.number,
  isEdit: PropTypes.bool,
};

export default StatementItemForm;
