import { apiSlice } from "features/api/apiSlice";

export const contractStatusApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    contractStatusDropdown: builder.query({
      query: () => ({
        url: "/contract-status/dropdown",
      }),
    }),
  }),
});

export const { useContractStatusDropdownQuery } = contractStatusApiSlice;
