export const secondaryThemeColors = {
  primaryBlue: "#3498DB",
  text1: "#1D2934",
  text2: "#092B4D",
  text3: "#FFFFFF",
  text4: "#98A1B3",
  background1: "#ECF0F1",
  background2: "#2C3E50",
  successGreen: "#27AE60",
  errorRed: "#E74C3C",
  black: "#000000",
  white: "#FFFFFF",
  boxShadow: "#101828",
  sidebarItemHover: "#0D1B2A",
  FormContainerBorderColor: "#EAECF0",
  tableRowSecondColor: "#F7FAFA",
  iconColor: "#1D2934",
  primaryDark: "#C4DFDF",
  secondaryDark: "#D2E9E9",
  primaryLighter: "#F8F6F4",
  textColor: "#393646",
};
