import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/SharedStyle.styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PAGES } from "constants/pages";
import Title from "components/Title/Title";
import { useFormik } from "formik";
import bankAccountValidation from "validations/bankAccountValidation";
import bankAccountInitialValues from "initialValues/bankAccountInitialValues";
import BankAccountForm from "components/Forms/BankAccountForm/BankAccountForm";
import {
  useAddBankAccountMutation,
  useBankAccountDetailsQuery,
  useEditBankAccountMutation,
} from "features/bankAccount/bankAccountApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import BackdropComponent from "components/Backdrop/BackdropComponent";
import Error from "pages/ErrorPage/ErrorPage";

const AddEditBankAccountPage = () => {
  const { id } = useParams();
  let { state } = useLocation();
  const path = useLocation().pathname;
  const isAdd = path === PAGES.ADDBANKACCOUNT.route;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    data: bankAccount,
    isLoading: isLoadingSingleBankAccount,
    error,
  } = useBankAccountDetailsQuery({ id }, { skip: isAdd });

  const [addBankAccount, { isLoading: isLoadingAddBankAccount }] =
    useAddBankAccountMutation();

  const [editBankAccount, { isLoading: isLoadingEditBankAccount }] =
    useEditBankAccountMutation();

  const handleSubmit = async (val, action) => {
    if (isAdd) {
      await addBankAccount({
        ...val,
        agencyId: Number(state.agencyId),
      })
        .unwrap()
        .then(() => {
          if (!isLoadingAddBankAccount) {
            navigate(PAGES.SETTINGS.route, { state: { tabId: 1 } });
            makeToastMessage(t("bankAccount.addSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("bankAccount.addErrorMessage")
          )
        )
        .finally(() => {
          action.setSubmitting(false);
        });
    }

    if (id) {
      await editBankAccount(val)
        .unwrap()
        .then(() => {
          if (!isLoadingEditBankAccount) {
            navigate(PAGES.SETTINGS.route, { state: { tabId: 1 } });
            makeToastMessage(t("bankAccount.editSuccessMessage"));
          }
        })
        .catch((e) => makeErrorToastMessage(e.data.message))
        .finally(() => {
          action.setSubmitting(false);
        });
    }
  };

  const addEditUserFormik = useFormik({
    initialValues:
      id && !isLoadingSingleBankAccount
        ? bankAccount
        : bankAccountInitialValues,
    validationSchema: bankAccountValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  if (isLoadingSingleBankAccount) {
    return (
      <BackdropComponent
        position="absolute"
        isLoading={isLoadingSingleBankAccount}
      />
    );
  }

  if (error) {
    if (error.status === 400) {
      return <Error />;
    }
  }

  return (
    <PageContainer>
      <Title
        title={isAdd ? t("bankAccount.addTitle") : t("bankAccount.editTitle")}
      />
      <BankAccountForm formik={addEditUserFormik} isAdd={isAdd} />
    </PageContainer>
  );
};
AddEditBankAccountPage.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object,
    errors: PropTypes.object,
    dirty: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
  }).isRequired,
};
export default AddEditBankAccountPage;
