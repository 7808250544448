import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  number: Yup.string().required(i18next.t("field.contractNumberRequired")),
  date: Yup.date()
    .required(i18next.t("field.contractDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  otpId: Yup.string().required(i18next.t("field.travelOrganizerIdRequired")),
  departureDate: Yup.date()
    .required(i18next.t("field.departureDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  periodFrom: Yup.date()
    .required(i18next.t("field.periodFromRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  periodTo: Yup.date()
    .required(i18next.t("field.periodToRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  contractTravelers: Yup.object().shape({
    travelers: Yup.array().of(
      Yup.object().shape({
        clientId: Yup.string().required(
          i18next.t("field.passengerNameRequired")
        ),
        departureLocation: Yup.string().required(
          i18next.t("field.departurePlaceRequired")
        ),
      })
    ),
  }),
  contractCalculations: Yup.object().shape({
    calculations: Yup.array().of(
      Yup.object().shape({
        service: Yup.string().required(i18next.t("field.serviceIdRequired")),
        quantity: Yup.number()
          .required(i18next.t("field.quantityRequired"))
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable(),
        domesticAmount: Yup.lazy((value, context) => {
          return context.parent.foreignAmount === undefined && value === undefined
            ? Yup.number().required(i18next.t("field.atLeastOneAmountRequired"))
            : Yup.number().notRequired();
        }),
        foreignAmount: Yup.number(),
        currencyId: Yup.lazy((value, context) => {
          return context.parent.foreignAmount !== undefined
            ? Yup.string().required(i18next.t("field.nbsCurrencyRequired"))
            : Yup.string().notRequired();
        }),
      })
    ),
  }),
});
