import { Button } from "@mui/material";
import Codebooks from "components/Codebooks/Codebooks";
import CustomTable from "components/CustomTable/CustomTable";
import Search from "components/Search/Search";
import Title from "components/Title/Title";
import buttonVariants from "constants/buttonVariants";
import modalConstants from "constants/modalConstants";
import { closeModal, openModal } from "features/modal/modalSlice";
import {
  useAddOfferTypeMutation,
  useDeleteOfferTypeMutation,
  useEditOfferTypeMutation,
  useOfferTypeListQuery,
} from "features/offerType/offerTypeApiSlice";
import { useFormik } from "formik";
import useCodebookTable from "hooks/tables/useCodebookTable";
import useAuth from "hooks/useAuth";
import useDebounce from "hooks/useDebounceHook";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader, PageContainer } from "styles/SharedStyle.styled";
import selectedTheme from "themes";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import * as Yup from "yup";

const OfferTypePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const addModal = modals?.[modalConstants.ADD_CODEBOOK_MODAL];
  const editModal = modals?.[modalConstants.EDIT_CODEBOOK_MODAL];
  const deleteModal = modals?.[modalConstants.DELETE_CODEBOOK_MODAL];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ name: "name", direction: "asc" });
  const { isSuperAdmin } = useAuth();
  const { columns } = useCodebookTable({
    page,
    rowsPerPage,
    type: "offerType",
    isChangeAllowed: isSuperAdmin,
  });

  const debouncedSearch = useDebounce(search);

  const { data: offerType, isLoading } = useOfferTypeListQuery({
    page,
    rowsPerPage,
    search: debouncedSearch,
    sort,
  });
  const [addOfferType, { isLoading: isLoadingAddService }] =
    useAddOfferTypeMutation();

  const [editOfferType, { isLoading: isLoadingEditService }] =
    useEditOfferTypeMutation();

  const [deleteOfferType, { isLoading: isLoadingDeleteService }] =
    useDeleteOfferTypeMutation();

  const handleSubmit = (val) => {
    if (addModal?.open) {
      addOfferType(val)
        .unwrap()
        .then(() => {
          if (!isLoadingAddService) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
            makeToastMessage(t("offerType.addSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("offerType.addErrorMessage")
          )
        );
    }

    if (editModal?.open) {
      editOfferType(val)
        .unwrap()
        .then(() => {
          if (!isLoadingEditService) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
            makeToastMessage(t("offerType.editSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("offerType.editErrorMessage")
          )
        );
    }
  };

  const handleConfirmDelete = () => {
    if (deleteModal?.open) {
      deleteOfferType({ id: deleteModal?.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteService) {
            dispatch(closeModal({ id: modalConstants.DELETE_CODEBOOK_MODAL }));
            makeToastMessage(t("offerType.deleteSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("offerType.deleteErrorMessage")
          )
        );
    }
  };

  const formik = useFormik({
    initialValues: editModal?.open
      ? editModal?.data
      : { name: "", offerDetailsLevelId: "" },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.offerTypeRequired")),
      offerDetailsLevelId: Yup.string().required(
        t("field.offerDetailsLevelRequired")
      ),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOpenAdd = () => {
    dispatch(openModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <PageContainer>
      <Codebooks
        formik={formik}
        editTitle={t("offerType.editTitle")}
        addTitle={t("offerType.addTitle")}
        labelTitle={t("field.offerTypePlaceholderRequired")}
        confirmCloseDescription={t("offerType.confirmClose")}
        confirmDeleteDescription={t("offerType.confirmDelete")}
        handleConfirmDelete={handleConfirmDelete}
        isLoadingDelete={isLoadingDeleteService}
        type="offerType"
      />

      <Title title={t("offerType.pageTitle")} />
      <PageHeader>
        <Search
          search={search}
          handleChange={handleSearch}
          label={t("offerType.searchPlaceholder")}
        />
        {isSuperAdmin && (
          <Button
            variant={buttonVariants.CONTAINED}
            style={{ backgroundColor: selectedTheme.colors.primaryBlue }}
            onClick={handleOpenAdd}
          >
            {t("offerType.addButton")}
          </Button>
        )}
      </PageHeader>
      <CustomTable
        columns={columns}
        data={offerType?.data}
        isLoading={isLoading}
        count={offerType?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("offerType.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default OfferTypePage;
