import React from "react";

import PointOfSaleIconMui from "@mui/icons-material/PointOfSale";
import styled from "styled-components";

const PointOfSaleIconMuiStyled = styled(PointOfSaleIconMui)`
  fill: ${(props) => props?.theme?.colors?.iconColor};
`;

const PointOfSaleIcon = (props) => {
  return <PointOfSaleIconMuiStyled {...props} />;
};

PointOfSaleIcon.propTypes = {};

export default PointOfSaleIcon;
