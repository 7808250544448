import { useFormik } from "formik";
import agencySettingsInitialValues from "initialValues/agencySettingsInitialValues";
import React, { useEffect, useState } from "react";
import { PageContainer } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import ptpAgencySettingsValidation from "validations/ptpAgencySettingsValidation";
import otpAgencyValidation from "validations/otpAgencyValidation";
import { useTranslation } from "react-i18next";
import { PTP_TYPE } from "constants/agencyType";
import { useAddAgencyMutation } from "features/agency/agencyApiSlice";
import { formatToIsoDate } from "util/dateHelpers";
import { useNavigate } from "react-router-dom";
import AddAgencyForm from "components/Forms/AddAgencyForm/AddAgencyForm";
import Title from "components/Title/Title";

const AddAgencyPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [agencyType, setAgencyType] = useState(null);
  const [addAgency, { isLoading: isLoadingAddAgency }] = useAddAgencyMutation();

  const handleSubmit = async (val, action) => {
    await addAgency({
      ...val,
      licenceNumberExpirationDate:
        agencyType === PTP_TYPE
          ? null
          : formatToIsoDate(val.licenceNumberExpirationDate),
      licenceNumber: agencyType === PTP_TYPE ? null : val.licenceNumber,
    })
      .unwrap()
      .then(() => {
        if (!isLoadingAddAgency) {
          navigate("/");
          makeToastMessage(t("agency.addSuccessMessage"));
        }
      })
      .catch((e) => makeErrorToastMessage(e.data.message))
      .finally(() => {
        action.setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: agencySettingsInitialValues,
    validationSchema:
      agencyType === PTP_TYPE
        ? ptpAgencySettingsValidation
        : otpAgencyValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    setAgencyType(formik.values.agencyTypeId);
  }, [formik]);

  return (
    <PageContainer>
      <Title title={t("admin.addAgencyTitle")} />
      <AddAgencyForm formik={formik} />
    </PageContainer>
  );
};

export default AddAgencyPage;
