/* eslint-disable react/prop-types */
import { Box, Button, Divider, Grid, IconButton } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import buttonVariants from "constants/buttonVariants";
import { useCurrencyDropdownQuery } from "features/currency/currencyApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
} from "styles/SharedStyle.styled";
import { isValidInput } from "util/helpers/numberHelpers";
import AddIcon from "@mui/icons-material/Add";
import selectedTheme from "themes";
import useDevice from "hooks/useDevice";
import DeleteIcon from "@mui/icons-material/Delete";
import externalSaleInitialValues from "initialValues/externalSaleInitialValues";
import { externalSaleFormikParams } from "constants/formik/externalSaleParams";

const ExternalCalculationForm = ({ formik }) => {
  const { data: currencies } = useCurrencyDropdownQuery();
  const { t } = useTranslation();
  const calculations = formik.values?.contractCalculations?.calculations || [];
  const error = formik.errors?.contractCalculations?.calculations || [];
  const touched = formik.touched?.contractCalculations?.calculations || [];
  const { isMobile } = useDevice();

  const calculateDomesticTotalAmount = (
    quantity,
    domesticAmount,
    domesticDiscount
  ) => {
    return parseFloat(quantity * domesticAmount - domesticDiscount).toFixed(2);
  };

  const calculateForeignTotalAmount = (
    quantity,
    foreignAmount,
    foreignDiscountAmount
  ) => {
    return parseFloat(quantity * foreignAmount - foreignDiscountAmount).toFixed(
      2
    );
  };

  const handleInputChange = (index, field, value) => {
    const updatedCalculations = [...calculations];
    updatedCalculations[index] = {
      ...updatedCalculations[index],
      [field]: value,
    };
    if (field === externalSaleFormikParams.DOMESTIC_AMOUNT && !value) {
      updatedCalculations[index].domesticDiscount = "";
    }
    if (field === externalSaleFormikParams.FOREIGN_AMOUNT && !value) {
      updatedCalculations[index].foreignDiscount = "";
      updatedCalculations[index].currencyId = "";
    }

    if (field === externalSaleFormikParams.DOMESTIC_DISCOUNT) {
      const maxDomesticDiscount =
        updatedCalculations[index].quantity *
        updatedCalculations[index].domesticAmount;
      if (updatedCalculations[index].domesticDiscount >= maxDomesticDiscount) {
        return;
      }
    }

    if (field === externalSaleFormikParams.FOREIGN_DISCOUNT) {
      const maxForeignDiscount =
        updatedCalculations[index].quantity *
        updatedCalculations[index].foreignAmount;
      if (updatedCalculations[index].foreignDiscount >= maxForeignDiscount) {
        return;
      }
    }
    updatedCalculations[index].domesticTotalAmount =
      calculateDomesticTotalAmount(
        updatedCalculations[index].quantity,
        updatedCalculations[index].domesticAmount,
        updatedCalculations[index].domesticDiscount
      );
    updatedCalculations[index].foreignTotalAmount = calculateForeignTotalAmount(
      updatedCalculations[index].quantity,
      updatedCalculations[index].foreignAmount,
      updatedCalculations[index].foreignDiscount
    );
    formik.setFieldValue(
      "contractCalculations.calculations",
      updatedCalculations
    );
  };

  return (
    <FormContainer>
      <InformationContainer>
        <InformationMainText>
          {t("externalSale.sections.calculation")}
        </InformationMainText>
      </InformationContainer>
      {formik.values.contractCalculations.calculations &&
        formik.values.contractCalculations.calculations.map(
          (calculation, index) => (
            <React.Fragment key={index}>
              <Grid
                container
                rowSpacing={{ sm: 3, md: 3 }}
                style={{ marginBottom: 24 }}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    rowSpacing={{ sm: 3 }}
                    columnSpacing={{ sm: 3 }}
                  >
                    <Grid item xs={12} md={0.6}>
                      <TextInput fullWidth label="R. br." value={index + 1} />
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <TextInput
                        fullWidth
                        name={externalSaleFormikParams.SERVICE}
                        onChange={(e) => {
                          handleInputChange(
                            index,
                            externalSaleFormikParams.SERVICE,
                            e.target.value
                          );
                        }}
                        label={t("field.serviceIdPlaceholderRequired")}
                        value={calculations[index]?.service}
                        style={{ marginRight: "24px" }}
                        error={
                          touched[index]?.service &&
                          Boolean(error[index]?.service)
                        }
                        helperText={
                          touched[index]?.service && error[index]?.service
                        }
                      />
                    </Grid>
                    {!isMobile && <Grid item xs={12} md={1} />}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    rowSpacing={{ sm: 3 }}
                    columnSpacing={{ sm: 3 }}
                  >
                    <Grid item xs={12} md={2}>
                      <TextInput
                        fullWidth
                        name={externalSaleFormikParams.QUANTITY}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === "" || isValidInput(inputValue)) {
                            handleInputChange(
                              index,
                              externalSaleFormikParams.QUANTITY,
                              inputValue
                            );
                          }
                        }}
                        label={t(
                          "field.externalSaleCalculationQualityRequired"
                        )}
                        value={calculations[index]?.quantity}
                        error={
                          touched[index]?.quantity &&
                          Boolean(error[index]?.quantity)
                        }
                        helperText={
                          touched[index]?.quantity && error[index]?.quantity
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextInput
                        fullWidth
                        name={externalSaleFormikParams.DOMESTIC_AMOUNT}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === "" || isValidInput(inputValue)) {
                            handleInputChange(
                              index,
                              externalSaleFormikParams.DOMESTIC_AMOUNT,
                              inputValue
                            );
                          }
                        }}
                        label={t("field.calculationDomesticAmountRequired")}
                        value={calculations[index]?.domesticAmount}
                        error={
                          touched[index]?.domesticAmount &&
                          Boolean(error[index]?.domesticAmount)
                        }
                        helperText={
                          touched[index]?.domesticAmount &&
                          error[index]?.domesticAmount
                        }
                        InputLabelProps={{
                          shrink: calculations[index]?.domesticAmount >= 0,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextInput
                        fullWidth
                        name={externalSaleFormikParams.DOMESTIC_DISCOUNT}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === "" || isValidInput(inputValue)) {
                            handleInputChange(
                              index,
                              externalSaleFormikParams.DOMESTIC_DISCOUNT,
                              inputValue
                            );
                          }
                        }}
                        label={t("field.calculationDomesticDiscrount")}
                        value={calculations[index]?.domesticDiscount}
                        disabled={
                          !formik.values.contractCalculations.calculations[
                            index
                          ].domesticAmount
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextInput
                        fullWidth
                        disabled
                        name={externalSaleFormikParams.DOMESTIC_TOTAL_AMOUNT}
                        label={t(
                          "field.calculationDomesticTotalAmountRequired"
                        )}
                        value={calculations[index]?.domesticTotalAmount}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {!isMobile && <Grid item xs={12} md={0.4} />}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    rowSpacing={{ sm: 3 }}
                    columnSpacing={{ sm: 3 }}
                  >
                    <Grid item xs={12} md={2}>
                      {(calculations[index]?.foreignAmount !== "" || calculations[index]?.foreignAmount > 0 )&& (
                        <SelectInput
                          fullWidth
                          name={externalSaleFormikParams.CURRENCY_ID}
                          label={t("dropdown.currency")}
                          onChange={(e) => {
                            handleInputChange(
                              index,
                              externalSaleFormikParams.CURRENCY_ID,
                              e.target.value
                            );
                          }}
                          value={calculations[index]?.currencyId}
                          error={
                            touched[index]?.currencyId &&
                            Boolean(error[index]?.currencyId)
                          }
                          helperText={
                            touched[index]?.currencyId &&
                            error[index]?.currencyId
                          }
                          items={currencies}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextInput
                        fullWidth
                        name={externalSaleFormikParams.FOREIGN_AMOUNT}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === "" || isValidInput(inputValue)) {
                            handleInputChange(
                              index,
                              externalSaleFormikParams.FOREIGN_AMOUNT,
                              inputValue
                            );
                          }
                        }}
                        label={t("field.calculationForeignAmountRequired")}
                        value={calculations[index]?.foreignAmount}
                        InputLabelProps={{
                          shrink: calculations[index]?.foreignAmount >= 0,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextInput
                        fullWidth
                        name={externalSaleFormikParams.FOREIGN_DISCOUNT}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            inputValue === "" ||
                            isValidInput(parseFloat(inputValue))
                          ) {
                            handleInputChange(
                              index,
                              externalSaleFormikParams.FOREIGN_DISCOUNT,
                              inputValue
                            );
                          }
                        }}
                        label={t("field.calculationForeignDiscrount")}
                        value={calculations[index]?.foreignDiscount}
                        style={{ marginRight: "24px" }}
                        disabled={
                          !formik.values.contractCalculations.calculations[
                            index
                          ].foreignAmount
                        }
                        InputLabelProps={{
                          shrink: calculations[index]?.foreignDiscount >= 0,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextInput
                        fullWidth
                        disabled
                        name={externalSaleFormikParams.FOREIGN_TOTAL_AMOUNT}
                        label={t("field.calculationForeignTotalAmountRequired")}
                        value={calculations[index]?.foreignTotalAmount}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={0.4}
                      style={{ alignSelf: "flex-end" }}
                    >
                      <IconButton
                        color="error"
                        onClick={() => {
                          if (
                            formik.values.contractCalculations.calculations
                              .length > 1
                          ) {
                            formik.setFieldValue(
                              "contractCalculations.calculations",
                              formik.values.contractCalculations.calculations.filter(
                                (_, i) => i !== index
                              ) // Remove the item
                            );
                          } else {
                            formik.setFieldValue(
                              "contractCalculations.calculations",
                              [
                                externalSaleInitialValues.contractCalculations
                                  .calculations[0],
                              ]
                            );
                          }
                        }}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {
                // should show divider if it is not the last item
                formik.values.contractCalculations.calculations.length - 1 !==
                  index && <Divider style={{ marginBottom: 24 }} />
              }
            </React.Fragment>
          )
        )}
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant={buttonVariants.CONTAINED}
          color="primary"
          startIcon={<AddIcon />}
          onClick={() =>
            formik.setFieldValue("contractCalculations.calculations", [
              ...formik.values.contractCalculations.calculations,
              externalSaleInitialValues.contractCalculations.calculations[0],
            ])
          }
          style={{
            backgroundColor: selectedTheme.colors.primaryBlue,
          }}
        >
          {t("singleContract.addCalculation")}
        </Button>
      </Box>
    </FormContainer>
  );
};

export default ExternalCalculationForm;
