import { Autocomplete, Box } from "@mui/material";
import DatePick from "components/Inputs/DatePick";
import TextInput from "components/Inputs/TextInput";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import FormActions from "../FormActions/FormActions";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import ClientSelect from "components/Inputs/ClientSelect";
import { useContractDropdownQuery } from "features/contract/contractApiSlice";
import { isValidInput } from "util/helpers/numberHelpers";
import { receiptFormikParams } from "constants/formik/receiptFormikParams";

const ReceiptForm = ({ formik }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const value = formik.values || {};
  const error = formik.errors || {};
  const touched = formik.touched || {};

  const safeSum = (...values) => {
    return values
      ? parseFloat(
          values.reduce((sum, value) => sum + (Number(value) || 0), 0)
        ).toFixed(2)
      : 0;
  };

  const { data: contractNumbers, isLoading: isLoadingContractNumbers } =
    useContractDropdownQuery();

  const handleInputChange = async (field, value) => {
    const updatedItems = { ...formik.values, [field]: value };
    if (
      field === receiptFormikParams.CASH_AMOUNT ||
      field === receiptFormikParams.CARD_AMOUNT ||
      field === receiptFormikParams.CHECK_AMOUNT
    ) {
      const totalAmount = safeSum(
        updatedItems.cashAmount,
        updatedItems.cardAmount,
        updatedItems.checkAmount
      );
      formik.setFieldValue(receiptFormikParams.TOTAL_AMOUNT, totalAmount);
    }

    formik.setFieldValue(field, value);
  };

  const handleCancel = () => {
    navigate(PAGES.RECEIPTS.route);
  };

  return (
    <Box component="form">
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("payment.sections.basicInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <TextInput
            fullWidth
            name={receiptFormikParams.RECEIPT_NUMBER}
            label={t("field.receiptNumberPlaceholderRequired")}
            value={value?.receiptNumber}
            onChange={formik.handleChange}
            error={touched?.receiptNumber && Boolean(error?.receiptNumber)}
            helperText={touched?.receiptNumber && error?.receiptNumber}
            style={{ marginRight: "24px" }}
          />
          <DatePick
            name={receiptFormikParams.DATE}
            label={t("field.termDate")}
            value={value?.date}
            onChange={(value) =>
              formik.setFieldValue(receiptFormikParams.DATE, value, true)
            }
            error={touched?.date && Boolean(error?.date)}
            helperText={touched?.date && error?.date}
            style={{ marginRight: "24px" }}
          />
          <ClientSelect
            name={receiptFormikParams.CLIENT_ID}
            label={t("field.clientNameAndSurnamePlaceholder")}
            style={{ marginRight: "24px" }}
            formik={formik}
          />
          <TextInput
            fullWidth
            name={receiptFormikParams.DESCRIPTION}
            label={t("field.travelDescriptionPlaceholder")}
            value={value?.description}
            onChange={formik.handleChange}
            error={touched?.description && Boolean(error?.description)}
            helperText={touched?.description && error?.description}
            style={{ marginRight: "24px" }}
          />
          <Autocomplete
            fullWidth
            id={receiptFormikParams.CONTRACT_ID}
            options={isLoadingContractNumbers ? [] : contractNumbers}
            getOptionLabel={(option) => option.text}
            value={
              !isLoadingContractNumbers
                ? contractNumbers.find(
                    (option) => option.value === value?.contractId
                  ) || null
                : null
            }
            onChange={(event, newValue) => {
              formik.setFieldValue(
                receiptFormikParams.CONTRACT_ID,
                newValue ? newValue.value : ""
              );
            }}
            renderInput={(params) => (
              <TextInput
                {...params}
                name={receiptFormikParams.CONTRACT_ID}
                label={t("field.contractNumber")}
                error={touched?.contractId && Boolean(error?.contractId)}
                helperText={touched?.contractId && error?.contractId}
              />
            )}
          />
        </RowContainer>
      </FormContainer>

      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("payment.sections.paymentInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <TextInput
            fullWidth
            name={receiptFormikParams.CASH_AMOUNT}
            label={t("field.cashAmountPlaceholder")}
            value={value?.cashAmount}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue === "" || isValidInput(inputValue)) {
                handleInputChange(receiptFormikParams.CASH_AMOUNT, inputValue);
              }
            }}
            error={touched?.cashAmount && Boolean(error?.cashAmount)}
            helperText={touched?.cashAmount && error?.cashAmount}
            style={{ marginRight: "24px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextInput
            fullWidth
            name={receiptFormikParams.CARD_AMOUNT}
            label={t("field.cardAmountPlaceholder")}
            value={value?.cardAmount}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue === "" || isValidInput(inputValue)) {
                handleInputChange(receiptFormikParams.CARD_AMOUNT, inputValue);
              }
            }}
            style={{ marginRight: "24px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextInput
            fullWidth
            name={receiptFormikParams.CHECK_AMOUNT}
            label={t("field.checkAmountPlaceholder")}
            value={value?.checkAmount}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue === "" || isValidInput(inputValue)) {
                handleInputChange(receiptFormikParams.CHECK_AMOUNT, inputValue);
              }
            }}
            style={{ marginRight: "24px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextInput
            fullWidth
            disabled
            name={receiptFormikParams.TOTAL_AMOUNT}
            label={t("field.totalAmount")}
            value={value?.totalAmount}
            error={touched?.totalAmount && Boolean(error?.totalAmount)}
            helperText={touched?.totalAmount && error?.totalAmount}
            style={{ marginRight: "24px" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextInput
            fullWidth
            name={receiptFormikParams.DUE_AMOUNT}
            label={t("field.restAmount")}
            value={value?.dueAmount}
            onChange={formik.handleChange}
            error={touched?.dueAmount && Boolean(error?.dueAmount)}
            helperText={touched?.dueAmount && error?.dueAmount}
          />
        </RowContainer>
      </FormContainer>
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("payment.sections.noteInfo")}
          </InformationMainText>
        </InformationContainer>
        <TextInput
          name={receiptFormikParams.RECEIPT_NOTICE}
          value={value?.receiptNotice}
          onChange={formik.handleChange}
          multiline
          minRows={5}
          label={t("settings.printTab.defaultTextForReceiptNotes")}
          style={{ marginBottom: 24 }}
        />
      </FormContainer>
      <FormActions
        disabled={!formik.dirty || formik.isSubmitting}
        handleCancel={handleCancel}
        handleConfirm={formik.handleSubmit}
      />
    </Box>
  );
};
ReceiptForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ReceiptForm;
