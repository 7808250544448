import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useAgencyPartnerGetInfoQuery } from "features/agencyPartner/agencyPartnerApiSlice";
import { PartnerCardContainer } from "./PartnerCard.styled";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTextInput from "components/Inputs/CustomTextInput";
import { invoiceFormikParams } from "constants/formik/invoiceFormikParams";

const PartnerCard = (props) => {
  const { t } = useTranslation();
  const { data: partner, error } = useAgencyPartnerGetInfoQuery({
    id: props?.partnerId,
    internalPartner: props?.partnerOnly,
  });

  const defaultDisabled = {
    name: false,
    address: false,
    location: false,
    pib: false,
    legal: false,
    ...props.disabled,
  };

  useEffect(() => {
    if (!props?.passedAddressFlag) {
      props?.formik.setFieldValue(
        invoiceFormikParams.CLIENT_PARTNER_NAME,
        partner?.name
      );
      props?.formik.setFieldValue(
        invoiceFormikParams.CLIENT_PARTNER_ADDRESS,
        partner?.address
      );
      props?.formik.setFieldValue(
        invoiceFormikParams.CLIENT_PARTNER_LOCATION_AND_POSTAL_CODE,
        partner?.location +
          (partner?.postalCode ? `, ${partner?.postalCode}` : "")
      );
      props?.formik.setFieldValue(
        invoiceFormikParams.PARTNER_PIB,
        partner?.pib
      );
      props?.formik.setFieldValue(
        invoiceFormikParams.PARTNER_LEGAL_IDENTIFICATION_NUMBER,
        partner?.legalIdentificationNumber
      );
    }
  }, [partner]);

  return (
    <PartnerCardContainer>
      {error ? (
        <Typography variant="body1">{t("common.errorOccurred")}</Typography>
      ) : (
        <>
          <CustomTextInput
            fullWidth
            name={invoiceFormikParams.CLIENT_PARTNER_NAME}
            label={t("field.invoiceClientPartnerInfo.partnerNamePlaceholder")}
            formik={props?.formik}
            disabled={defaultDisabled.name}
          />

          <CustomTextInput
            fullWidth
            name={invoiceFormikParams.CLIENT_PARTNER_ADDRESS}
            label={t("field.invoiceClientPartnerInfo.addressPlaceholder")}
            formik={props?.formik}
            disabled={defaultDisabled.address}
          />
          <CustomTextInput
            fullWidth
            name={invoiceFormikParams.CLIENT_PARTNER_LOCATION_AND_POSTAL_CODE}
            label={t(
              "field.invoiceClientPartnerInfo.locationAndPostalCodePlaceholder"
            )}
            formik={props?.formik}
            disabled={defaultDisabled.location}
          />
          <CustomTextInput
            fullWidth
            name={invoiceFormikParams.PARTNER_PIB}
            label={t("field.invoiceClientPartnerInfo.pibPlaceholder")}
            formik={props?.formik}
            disabled={defaultDisabled.pib}
          />
          <CustomTextInput
            fullWidth
            name={invoiceFormikParams.PARTNER_LEGAL_IDENTIFICATION_NUMBER}
            label={t(
              "field.invoiceClientPartnerInfo.legalIdentificationNumberPlaceholder"
            )}
            formik={props?.formik}
            disabled={defaultDisabled.legal}
          />
        </>
      )}
    </PartnerCardContainer>
  );
};
PartnerCard.propTypes = {
  partnerId: PropTypes.number,
  partnerOnly: PropTypes.bool,
  formik: PropTypes.object,
  passedAddressFlag: PropTypes.bool,
  disabled: {
    name: PropTypes.bool,
    address: PropTypes.bool,
    location: PropTypes.bool,
    pib: PropTypes.bool,
    legal: PropTypes.bool,
  },
};

export default PartnerCard;
