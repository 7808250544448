import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useFormik } from "formik";
import CustomAutoComplete from "./CustomAutoComplete";
import * as Yup from "yup";
import AddEditCodebookItemModal from "components/Modals/AddEditCodebookItemModal/AddEditCodebookItemModal";
import { useAddCountryMutation, useCountryDropdownQuery } from "features/country/countryApiSlice";

const CountrySelect = (props) => {
  const { t } = useTranslation();
  const addButtonText = t("country.addButton");
  const [
    createCountry,
    { isLoading: isLoadingCreateCountry },
  ] = useAddCountryMutation();
  const [openAdd, setOpenAdd] = useState(false);

  const handleSubmit = async (values) => {
    try {
      await createCountry(values).unwrap();
      if (!isLoadingCreateCountry) {
        addCountryFormik.resetForm();
        setOpenAdd(false);
        makeToastMessage(t("country.addSuccessMessage"));
      }
    } catch (e) {
      makeErrorToastMessage(e.data.message);
    }
  };

  const addCountryFormik = useFormik({
    initialValues: { name: "" },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.countryIdRequiredRequired")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleClose = () => {
    setOpenAdd(false);
    addCountryFormik.resetForm();
  };
  const { skip, ...rest } = props.apiProps || {};
  return (
    <>
      <AddEditCodebookItemModal
        open={openAdd}
        handleClose={handleClose}
        handleCloseBtn={handleClose}
        formik={addCountryFormik}
        title={t("country.addTitle")}
        labelTitle={t("field.countryIdPlaceholder")}
      />
      <CustomAutoComplete
        name={props?.name}
        label={props?.label}
        style={props?.style}
        value={props?.value}
        formik={props?.formik}
        addButtonText={addButtonText}
        addButtonClick={() => setOpenAdd(true)}
        useQuery={() => useCountryDropdownQuery(rest, { skip })}
        additionalOnChange={() => props?.formik.setFieldValue("locationId", "")}
        hasAdd={props?.hasAdd}
        disabled={props?.disabled}
      />
    </>
  );
};

CountrySelect.defaultProps = {
  hasAdd: true,
  disabled: false,
};

CountrySelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  formik: PropTypes.string,
  setPassedAddressFlag: PropTypes.func,
  hasAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  apiProps: PropTypes.object,
};

export default CountrySelect;
