/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import DatePick from "./DatePick";

const CustomDatePickContainer = styled(DatePick)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
    left: 0;
  }
`;

const CustomDatePick = (props) => {
  return (
    <CustomDatePickContainer
      name={props?.name}
      label={props?.label}
      value={props?.value ?? props?.formik?.values?.[props?.name]}
      onChange={(value) =>
        props?.formik.setFieldValue(props?.name, value, true)
      }
      disabled={props?.disabled}
      format={props?.format}
      slotProps={{
        textField: {
          fullWidth: props?.fullWidth,
          error:
            props?.formik?.touched?.[props?.name] &&
            Boolean(props?.formik?.errors?.[props?.name]),
          style: props?.style,
          helperText:
            props?.formik?.touched?.[props?.name] &&
            props?.formik?.errors?.[props?.name],
        },
      }}
      {...props}
    />
  );
};

CustomDatePick.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  formik: PropTypes.object,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

CustomDatePick.defaultProps = {
  disabled: false,
  format: "dd.MM.yyyy.",
  error: false,
  helperText: "",
};

export default CustomDatePick;
