import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useFormik } from "formik";
import CustomAutoComplete from "./CustomAutoComplete";
import * as Yup from "yup";
import AddEditCodebookItemModal from "components/Modals/AddEditCodebookItemModal/AddEditCodebookItemModal";
import {
  useAccommodationUnitTypeDropdownQuery,
  useAddAccommodationUnitTypeMutation,
} from "features/accommodationUnitType/accommodationUnitTypeApiSlice";

const AccommodationUnitTypeSelect = (props) => {
  const { t } = useTranslation();
  const addButtonText = t("accommodationUnitType.addButton");
  const [
    createAccommodationUnitType,
    { isLoading: isLoadingCreateAccommodationUnitType },
  ] = useAddAccommodationUnitTypeMutation();
  const [openAdd, setOpenAdd] = useState(false);

  const handleSubmit = async (values) => {
    try {
      await createAccommodationUnitType(values).unwrap();
      if (!isLoadingCreateAccommodationUnitType) {
        addAccommodationUnitTypeFormik.resetForm();
        setOpenAdd(false);
        makeToastMessage(t("accommodationUnitType.addSuccessMessage"));
      }
    } catch (e) {
      makeErrorToastMessage(e.data.message);
    }
  };

  const addAccommodationUnitTypeFormik = useFormik({
    initialValues: { name: "" },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.accommodationUnitTypeRequired")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleClose = () => {
    setOpenAdd(false);
    addAccommodationUnitTypeFormik.resetForm();
  };
  const { skip, ...rest } = props.apiProps || {};
  return (
    <>
      <AddEditCodebookItemModal
        open={openAdd}
        handleClose={handleClose}
        handleCloseBtn={handleClose}
        formik={addAccommodationUnitTypeFormik}
        title={t("accommodationUnitType.addTitle")}
        labelTitle={t("field.accommodationUnitTypePlaceholder")}
      />
      <CustomAutoComplete
        name={props?.name}
        label={props?.label}
        style={props?.style}
        value={props?.value}
        formik={props?.formik}
        addButtonText={addButtonText}
        addButtonClick={() => setOpenAdd(true)}
        useQuery={() => useAccommodationUnitTypeDropdownQuery(rest, { skip })}
        additionalOnChange={props?.setPassedAddressFlag}
        hasAdd={props?.hasAdd}
        disabled={props?.disabled}
      />
    </>
  );
};

AccommodationUnitTypeSelect.defaultProps = {
  hasAdd: true,
};

AccommodationUnitTypeSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  formik: PropTypes.string,
  setPassedAddressFlag: PropTypes.func,
  hasAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  apiProps: PropTypes.object,
};

export default AccommodationUnitTypeSelect;
