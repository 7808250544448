import { Box, Button, Dialog } from "@mui/material";
import styled from "styled-components";

export const DialogStyled = styled(Dialog)`
  & .MuiDialog-container {
    & .MuiPaper-root {
      width: 100%;
      min-width: 653px;
      border-radius: 8px;
    }
  }
  margin-top: -300px;
`;

export const DialogContainer = styled(Box)`
  padding: 24px;
`;

export const DialogMessageContainer = styled(Box)`
  margin-bottom: 24px;
`;

export const DialogActionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;

export const ConfirmButton = styled(Button)`
  background-color: ${(props) => props?.bgColor};
`;
