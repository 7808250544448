import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  issueDate: Yup.date()
    .required(i18next.t("field.invoiceDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  location: Yup.string().required(i18next.t("field.invoiceLocationRequired")),
  transactionDate: Yup.date()
    .required(i18next.t("field.invoiceTransactionDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  dueDate: Yup.date()
    .required(i18next.t("field.invoiceDueDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError"))
    .test(
      "is-after-issue-date",
      i18next.t("field.dueDateMustBeAfterIssueDate"),
      function (value) {
        const { issueDate } = this.parent;
        if (!value || !issueDate) return true;
        return value >= issueDate;
      }
    )
    .test(
      "is-after-transaction-date",
      i18next.t("field.dueDateMustBeAfterTransactionDate"),
      function (value) {
        const { transactionDate } = this.parent;
        if (!value || !transactionDate) return true;
        return value >= transactionDate;
      }
    ),
  currencyId: Yup.string().required(i18next.t("field.nbsCurrencyRequired")),
  clientId: Yup.string().test(
    "clientId-or-partnerId",
    i18next.t("field.clientIdRequired"),
    function (value) {
      const { clientPartner } = this.parent;
      if (clientPartner === 2 && !value) {
        return false;
      } else {
        return true;
      }
    }
  ),
  partnerId: Yup.string().test(
    "clientId-or-partnerId",
    i18next.t("field.partnerIdRequired"),
    function (value) {
      const { clientPartner } = this.parent;
      if (clientPartner === 1 && !value) {
        return false;
      } else {
        return true;
      }
    }
  ),
  clientPartner: Yup.number().required(
    i18next.t("field.clientPartnerRequired")
  ),
  items: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required(
        i18next.t("field.invoiceDescriptionRequired")
      ),
      price: Yup.string().required(i18next.t("field.invoicePriceRequired")),
      quantity: Yup.number()
        .required(i18next.t("field.invoiceQuantityRequired"))
        .min(1, i18next.t("field.invoiceQuantityMin")),
    })
  ),
});
