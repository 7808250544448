import { Button } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FormActionsContainer } from "./FormActions.styled";
import useDevice from "hooks/useDevice";
import selectedTheme from "themes";
import buttonVariants from "constants/buttonVariants";
import inputTypes from "constants/inputTypes";

const FormActions = ({ handleCancel, disabled, handleConfirm }) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  return (
    <FormActionsContainer>
      <Button
        fullWidth={isMobile}
        variant={buttonVariants.OUTLINED}
        onClick={handleCancel}
        style={{ marginRight: "24px" }}
      >
        {t("common.cancel")}
      </Button>
      <Button
        fullWidth={isMobile}
        variant={buttonVariants.CONTAINED}
        type={inputTypes.SUBMIT}
        disabled={disabled}
        onClick={handleConfirm}
        style={{
          backgroundColor: disabled ? "" : selectedTheme.colors.primaryBlue,
        }}
      >
        {t("common.save")}
      </Button>
    </FormActionsContainer>
  );
};
FormActions.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default FormActions;
