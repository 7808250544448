import { PAGES } from "./pages";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PublicIcon from "@mui/icons-material/Public";
import MapIcon from "@mui/icons-material/Map";
import SingleBedIcon from "@mui/icons-material/SingleBed";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import OutboxIcon from "@mui/icons-material/Outbox";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";
import CurrencyIcon from "@mui/icons-material/AttachMoney";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import CategoryIcon from "@mui/icons-material/Category";
import HotelIcon from "@mui/icons-material/Hotel";
import VillaIcon from "@mui/icons-material/Villa";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import RestaurantIcon from "@mui/icons-material/Restaurant";

export const headerNavigationConstants = [
  PAGES.HOME,
  PAGES.DASHBOARD,
  PAGES.PROFILE,
  PAGES.SETTINGS,
];

export const homePageNavigationLinks = [
  {
    title: PAGES.CONTRACTS.title,
    path: PAGES.CONTRACTS.route,
    icon: HistoryEduIcon,
    text: "contract.codebookCardText",
  },
  {
    title: "externalSale.codebookCardTitle",
    path: PAGES.EXTERNAL_SALE.route,
    icon: OutboxIcon,
    text: "externalSale.codebookCardText",
  },
  {
    title: PAGES.RECEIPTS.title,
    path: PAGES.RECEIPTS.route,
    icon: ReceiptIcon,
    text: "receipt.codebookCardText",
  },
  {
    title: PAGES.INVOICE.title,
    path: PAGES.INVOICE.route,
    icon: RequestPageIcon,
    text: "invoice.codebookCardText",
  },
];

export const codebookPageNavigationLinks = [
  {
    title: PAGES.CATEGORY.title,
    path: PAGES.CATEGORY.route,
    icon: CategoryIcon,
    text: "category.codebookCardText",
  },
  {
    title: PAGES.COUNTRY.title,
    path: PAGES.COUNTRY.route,
    icon: PublicIcon,
    text: "country.codebookCardText",
  },
  {
    title: PAGES.LOCATION.title,
    path: PAGES.LOCATION.route,
    icon: MapIcon,
    text: "location.codebookCardText",
  },
  {
    title: PAGES.ACCOMMODATION_TYPE.title,
    path: PAGES.ACCOMMODATION_TYPE.route,
    icon: SingleBedIcon,
    text: "accommodationType.codebookCardText",
  },
  {
    title: PAGES.ACCOMMODATION.title,
    path: PAGES.ACCOMMODATION.route,
    icon: HotelIcon,
    text: "accommodation.codebookCardText",
  },
  {
    title: PAGES.ACCOMMODATION_UNIT_TYPE.title,
    path: PAGES.ACCOMMODATION_UNIT_TYPE.route,
    icon: VillaIcon,
    text: "accommodationUnitType.codebookCardText",
  },
  {
    title: PAGES.ACCOMMODATION_UNIT_PROPERTY.title,
    path: PAGES.ACCOMMODATION_UNIT_PROPERTY.route,
    icon: MapsHomeWorkIcon,
    text: "accommodationUnitCharacteristics.codebookCardText",
  },
  {
    title: PAGES.SERVICES.title,
    path: PAGES.SERVICES.route,
    icon: RestaurantIcon,
    text: "services.codebookCardText",
  },
  {
    title: PAGES.TRANSPORTATION_TYPE.title,
    path: PAGES.TRANSPORTATION_TYPE.route,
    icon: AirportShuttleIcon,
    text: "transportationType.codebookCardText",
  },
  {
    title: PAGES.OFFER_TYPE.title,
    path: PAGES.OFFER_TYPE.route,
    icon: CardGiftcardIcon,
    text: "offerType.codebookCardText",
  },
];

export const codebookPageNavigationLinksSuperAdmin = [
  {
    title: PAGES.COUNTRY.title,
    path: PAGES.COUNTRY.route,
    icon: PublicIcon,
    text: "country.codebookCardText",
  },
  {
    title: PAGES.LOCATION.title,
    path: PAGES.LOCATION.route,
    icon: MapIcon,
    text: "location.codebookCardText",
  },
  {
    title: PAGES.ACCOMMODATION_TYPE.title,
    path: PAGES.ACCOMMODATION_TYPE.route,
    icon: SingleBedIcon,
    text: "accommodationType.codebookCardText",
  },
  {
    title: PAGES.TRANSPORTATION_TYPE.title,
    path: PAGES.TRANSPORTATION_TYPE.route,
    icon: AirportShuttleIcon,
    text: "transportationType.codebookCardText",
  },
  {
    title: PAGES.OFFER_TYPE.title,
    path: PAGES.OFFER_TYPE.route,
    icon: CardGiftcardIcon,
    text: "offerType.codebookCardText",
  },
  {
    title: PAGES.CURRENCY.title,
    path: PAGES.CURRENCY.route,
    icon: CurrencyIcon,
    text: "currency.codebookCardText",
  },
];

export const salesPageNavigationLinks = [
  {
    title: PAGES.INTERNAL_SALE.title,
    path: PAGES.INTERNAL_SALE.route,
    icon: MoveToInboxIcon,
    text: "internalSale.codebookCardText",
  },
  {
    title: PAGES.EXTERNAL_SALE.title,
    path: PAGES.EXTERNAL_SALE.route,
    icon: OutboxIcon,
    text: "externalSale.codebookCardText",
  },
];

export const paymentPageNavigationLinks = [
  {
    title: PAGES.RECEIPTS.title,
    path: PAGES.RECEIPTS.route,
    icon: ReceiptIcon,
    text: "receipt.codebookCardText",
  },
  {
    title: PAGES.INVOICE.title,
    path: PAGES.INVOICE.route,
    icon: RequestPageIcon,
    text: "invoice.codebookCardText",
  },
  {
    title: PAGES.PROINVOICE.title,
    path: PAGES.PROINVOICE.route,
    icon: RequestPageOutlinedIcon,
    text: "proinvoice.codebookCardText",
  },
];
