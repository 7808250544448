import React, { useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/SharedStyle.styled";
import useClearingsTable from "hooks/tables/useClearingsTable";
import PropTypes from "prop-types";
import { useClearingListQuery } from "features/clearing/clearingApiSlice";
import EditClearingModal from "components/Modals/EditClearingModal/EditClearingModal";
import { useSelector } from "react-redux";
import modalConstants from "constants/modalConstants";

// eslint-disable-next-line no-unused-vars
const ClearingsContentPage = ({ type, contractId, currency }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const modals = useSelector((state) => state.modal.modals);
  const editModal = modals?.[modalConstants.EDIT_CLEARING_MODAL];
  const { columns } = useClearingsTable({
    page,
    rowsPerPage,
  });
  const { data: clearingList, isLoading: isLoadingClearingsList } =
    useClearingListQuery({
      page,
      rowsPerPage,
      contractId,
    });

  return (
    <PageContainer>
      <EditClearingModal open={editModal?.open} clearing={editModal?.data} currency={currency} />
      <CustomTable
        columns={columns}
        data={clearingList?.data}
        count={clearingList?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("clearing.rowsName")}
        isLoading={isLoadingClearingsList}
      />
    </PageContainer>
  );
};

ClearingsContentPage.propTypes = {
  type: PropTypes.string,
  contractId: PropTypes.number,
  currency: PropTypes.string,
};

export default ClearingsContentPage;
