export const invoiceFormikParams = {
  NUMBER: "number",
  ISSUE_DATE: "issueDate",
  LOCATION: "location",
  TRANSACTION_DATE: "transactionDate",
  DUE_DATE: "dueDate",
  CONTRACT_NUMBER: "contractNumber",
  CONTRACT_ID: "contractId",
  DESCRIPTION: "description",
  CURRENCY_ID: "currencyId",
  CURRENCY_NAME: "currencyName",
  RATE: "rate",
  CLIENT_PARTNER: "clientPartner",
  CLIENT_ID: "clientId",
  PARTNER_ID: "partnerId",
  TOTAL_AMOUNT: "totalAmount",
  CHAR_TOTAL_AMOUNT: "charTotalAmount",
  NOTE: "note",
  CLIENT_PARTNER_NAME: "clientPartnerName",
  CLIENT_PARTNER_ADDRESS: "clientPartnerAddress",
  CLIENT_PARTNER_LOCATION_AND_POSTAL_CODE: "clientPartnerLocationAndPostalCode",
  PARTNER_PIB: "partnerPib",
  PARTNER_LEGAL_IDENTIFICATION_NUMBER: "partnerLegalIdentificationNumber",
  ITEMS: "items",
  INVOICE_NOTICE: "invoiceNotice",
};
