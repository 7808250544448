/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomTextFilter from "../CustomTextFilter";
import DateFilter from "../DateFilter";
import { useClientDropdownQuery } from "features/client/clientApiSlice";
import CustomAutoCompleteFilter from "../CustomAutoCompleteFilter";
import { useAgencyPartnerDropdownQuery } from "features/agencyPartner/agencyPartnerApiSlice";
import { invoiceFilterFormikParams } from "constants/formik/filters/invoiceFilterFormikParams";

const InvoiceFilter = ({ filterForm, isProInvoice, appliedFilters }) => {
  const { t } = useTranslation();

  return (
    <Box component="form">
      <RowContainer>
        <CustomTextFilter
          name={invoiceFilterFormikParams.INVOICE_NUMBER}
          label={
            isProInvoice
              ? t("field.proInvoiceNumberFilter")
              : t("field.invoiceNumberFilter")
          }
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <DateFilter
          name={invoiceFilterFormikParams.ISSUE_DATE_FROM}
          label={
            isProInvoice
              ? t("field.proInvoiceIssueDateFromFilter")
              : t("field.invoiceIssueDateFromFilter")
          }
          filterForm={filterForm}
          appliedFilters={appliedFilters}
          style={{ marginRight: "24px" }}
        />
        <DateFilter
          name={invoiceFilterFormikParams.ISSUE_DATE_TO}
          label={
            isProInvoice
              ? t("field.proInvoiceIssueDateToFilter")
              : t("field.invoiceIssueDateToFilter")
          }
          filterForm={filterForm}
          appliedFilters={appliedFilters}
        />
      </RowContainer>
      <RowContainer>
        <CustomAutoCompleteFilter
          name={invoiceFilterFormikParams.CLIENT_ID}
          label={t("field.clientFilter")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
          useQuery={useClientDropdownQuery}
          style={{ marginRight: "24px" }}
        />
        <CustomAutoCompleteFilter
          name={invoiceFilterFormikParams.PARTNER_ID}
          label={t("field.partnerFilter")}
          filterForm={filterForm}
          appliedFilters={appliedFilters}
          useQuery={useAgencyPartnerDropdownQuery}
        />
      </RowContainer>
    </Box>
  );
};

export default InvoiceFilter;
