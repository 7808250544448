import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useClientDetailsQuery } from "features/client/clientApiSlice";
import { ClientCardContainer } from "./ClientCard.styled";
import { useLocationDetailsQuery } from "features/location/locationApiSlice";
import { useTranslation } from "react-i18next";
import CustomTextInput from "components/Inputs/CustomTextInput";
import { invoiceFormikParams } from "constants/formik/invoiceFormikParams";

const ClientCard = (props) => {
  const { t } = useTranslation();
  const { data: client } = useClientDetailsQuery({
    id: props?.clientId,
  });

  const { data: location } = useLocationDetailsQuery(
    { id: client?.locationId },
    { skip: !client }
  );

  const defaultDisabled = {
    name: false,
    address: false,
    location: false,
    ...props.disabled,
  };

  useEffect(() => {
    if (!props?.passedAddressFlag) {
      props?.formik.setFieldValue(
        invoiceFormikParams.CLIENT_PARTNER_NAME,
        client?.surname + " " + client?.name
      );
      props?.formik.setFieldValue(
        invoiceFormikParams.CLIENT_PARTNER_ADDRESS,
        client?.address
      );
      props?.formik.setFieldValue(
        invoiceFormikParams.CLIENT_PARTNER_LOCATION_AND_POSTAL_CODE,
        location?.name + (client?.postalCode ? `, ${client?.postalCode}` : "")
      );
    }
  }, [client, location]);

  return (
    <ClientCardContainer>
      <CustomTextInput
        fullWidth
        name={invoiceFormikParams.CLIENT_PARTNER_NAME}
        label={t("field.invoiceClientPartnerInfo.clientNamePlaceholder")}
        formik={props?.formik}
        disabled={defaultDisabled.name}
      />
      <CustomTextInput
        fullWidth
        name={invoiceFormikParams.CLIENT_PARTNER_ADDRESS}
        label={t("field.invoiceClientPartnerInfo.addressPlaceholder")}
        formik={props?.formik}
        disabled={defaultDisabled.address}
      />
      <CustomTextInput
        fullWidth
        name={invoiceFormikParams.CLIENT_PARTNER_LOCATION_AND_POSTAL_CODE}
        label={t(
          "field.invoiceClientPartnerInfo.locationAndPostalCodePlaceholder"
        )}
        formik={props?.formik}
        disabled={defaultDisabled.location}
      />
    </ClientCardContainer>
  );
};
ClientCard.propTypes = {
  clientId: PropTypes.number.isRequired,
  formik: PropTypes.object,
  passedAddressFlag: PropTypes.bool,
  disabled: {
    name: PropTypes.bool,
    address: PropTypes.bool,
    location: PropTypes.bool,
  },
};

export default ClientCard;
