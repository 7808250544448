export const isValidInput = (value, decimalPlaces = 2, wholeNumber = false) => {
  let regex;
  if (wholeNumber) {
    regex = new RegExp(`^[1-9][0-9]*$`);
  } else {
    regex = new RegExp(
      `^(?!.*\\..*\\.)[1-9][0-9]*(\\.[0-9]{0,${decimalPlaces}})?$`
    );
  }
  return regex.test(value);
};