import { apiSlice } from "features/api/apiSlice";

export const invoiceApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Invoice", "InvoiceSingle"],
  endpoints: (builder) => ({
    invoiceDropdown: builder.query({
      query: () => ({
        url: "/invoice/dropdown",
      }),
      providesTags: ["Invoice"],
    }),
    invoiceList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, filter, sort } = args;
        return {
          url:
            "/invoice/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              ...(sort && {
                SortPropertyName: sort.name,
                Ascending: sort.direction === "asc" ? true : false,
              }),
              ...(filter && filter),
            }),
        };
      },
      providesTags: ["Invoice"],
    }),
    invoiceDetails: builder.query({
      query: ({ id }) => ({
        url: `/invoice/${id}`,
      }),
      providesTags: ["InvoiceSingle"],
    }),
    addInvoice: builder.mutation({
      query: (data) => ({
        url: "/invoice/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Invoice"],
    }),
    editInvoice: builder.mutation({
      query: (data) => ({
        url: "/invoice/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Invoice", "InvoiceSingle"],
    }),
    deleteInvoice: builder.mutation({
      query: (data) => ({
        url: "/invoice",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["Invoice"],
    }),
  }),
});

export const {
  useInvoiceDropdownQuery,
  useInvoiceListQuery,
  useInvoiceDetailsQuery,
  useAddInvoiceMutation,
  useEditInvoiceMutation,
  useDeleteInvoiceMutation,
} = invoiceApiSlice;
