/* eslint-disable react/prop-types */
import { Button, Typography } from "@mui/material";
import React from "react";
import {
  ConfirmButton,
  DialogActionsContainer,
  DialogContainer,
  DialogMessageContainer,
  DialogStyled,
} from "./ConfirmModal.styled";
import { UpperBar } from "styles/SharedStyle.styled";
import selectedTheme from "themes";
import buttonVariants from "constants/buttonVariants";

const ConfirmModal = ({
  open,
  handleConfirm,
  handleCancel,
  description,
  confirmLabel,
  cancelLabel,
  color = selectedTheme.colors.errorRed,
  btnCancel = "primary",
  isConfirmDisabled = false,
}) => {
  return (
    <DialogStyled open={open}>
      <UpperBar color={color} />
      <DialogContainer>
        <DialogMessageContainer>
          <Typography sx={{ whiteSpace: "pre-line" }}>{description}</Typography>
        </DialogMessageContainer>
        <DialogActionsContainer>
          <ConfirmButton
            variant={buttonVariants.CONTAINED}
            onClick={handleConfirm}
            style={{ marginRight: "24px" }}
            bgColor={color}
            disabled={isConfirmDisabled}
          >
            {confirmLabel}
          </ConfirmButton>
          <Button
            variant={buttonVariants.OUTLINED}
            onClick={handleCancel}
            color={btnCancel}
          >
            {cancelLabel}
          </Button>
        </DialogActionsContainer>
      </DialogContainer>
    </DialogStyled>
  );
};

export default ConfirmModal;
