import { Box } from "@mui/material";
import AccommodationUnitTypeSelect from "components/Inputs/AccommodationUnitTypeSelect";
import MultiSelectInput from "components/Inputs/MultiSelectInput";
import TextInput from "components/Inputs/TextInput";
import { useUnitPropertyDropdownQuery } from "features/unitProperty/unitPropertyApiSlice";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import FormActions from "../FormActions/FormActions";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import ImagePicker from "components/ImagePicker/ImagePicker";
import { useAccommodationUnitPhotosQuery } from "features/accommodationUnit/accommodationUnitApiSlice";

const AccommodationUnitForm = ({ formik, accommodationId }) => {
  const { data: unitProperty } = useUnitPropertyDropdownQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: unitPropertyPhotos, isLoading: isLoadingUnitPropertyPhotos } =
    useAccommodationUnitPhotosQuery({ accommodationUnitId: accommodationId });
  const [agencyLogoImage, setAgencyLogoImage] = useState(
    unitPropertyPhotos?.data?.length ? [unitPropertyPhotos?.data] : []
  );
  useEffect(() => {
    if (!isLoadingUnitPropertyPhotos) {
      setAgencyLogoImage(
        unitPropertyPhotos?.data?.length ? [unitPropertyPhotos?.data] : []
      );
    }
  }, [isLoadingUnitPropertyPhotos]);

  const handleDeletePhotos = (index) => {
    setAgencyLogoImage((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
  };
  return (
    <Box component="form">
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("accommodationUnit.sections.basicInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <TextInput
            fullWidth
            name="name"
            label={t("field.accommodationUnitNamePlaceholder")}
            value={formik?.values?.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            style={{ marginRight: 24 }}
          />
          <AccommodationUnitTypeSelect
            name="accommodationUnitTypeId"
            label={t("field.accommodationUnitTypeRequiredPlaceholder")}
            formik={formik}
            hasAdd={false}
            apiProps={{ skip: !open }}
          />
        </RowContainer>
        <RowContainer>
          <MultiSelectInput
            names={unitProperty}
            selectedValues={formik?.values?.unitProperties}
            formik={formik}
            name="unitProperties"
            label={"Karakteristike smeštajne jedinice"}
          />
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            multiline
            minRows={4}
            maxRows={8}
            name="description"
            label={t("field.accommodationUnitDescriptionPlaceholder")}
            value={formik?.values?.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
        </RowContainer>
      </FormContainer>

      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("accommodationUnit.sections.imagesInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <ImagePicker
            descriptionText={t("agency.logoDescriptionText")}
            additionalDescriptionText={t("common.pictureSize", { size: "2" })}
            //label={t("field.agencyLogoPlaceholder")}
            images={agencyLogoImage}
            setImages={setAgencyLogoImage}
            styleContainer={{ marginRight: "24px" }}
            name="logo"
            isLoading={false}
            handleDelete={handleDeletePhotos}
            multiple={true}
          />
        </RowContainer>
      </FormContainer>

      <FormActions
        disabled={!formik.dirty || formik.isSubmitting}
        handleCancel={() => {
          navigate(
            PAGES.ACCOMMODATION_UNIT.route.replace(":id", accommodationId)
          );
        }}
        handleConfirm={formik.handleSubmit}
      />
    </Box>
  );
};
AccommodationUnitForm.propTypes = {
  accommodationId: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
};

export default AccommodationUnitForm;
