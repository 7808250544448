import { Box, Button } from "@mui/material";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  ContentContainer,
} from "components/Forms/AgencyForm/AgencyForm.styled";
import PasswordInput from "components/Inputs/PasswordInput";
import buttonVariants from "constants/buttonVariants";
import formikParams from "constants/formikParams";
import inputTypes from "constants/inputTypes";
import { useChangePasswordMutation } from "features/auth/authApiSlice";
import { useFormik } from "formik";
import changePasswordInitialValues from "initialValues/changePasswordInitialValues";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import selectedTheme from "themes";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import changePasswordValidation from "validations/changePasswordValidation";

const ChangePassword = () => {
  const { t } = useTranslation();
  const [changePassword, { isLoading: isLoadingChangePassword }] =
    useChangePasswordMutation();
  const handleSubmit = async (values) => {
    try {
      await changePassword(values)
        .unwrap()
        .then(() => {
          if (!isLoadingChangePassword) {
            formik.resetForm();
            makeToastMessage(t("profile.changePasswordSuccessMessage"));
          }
        });
    } catch (error) {
      makeErrorToastMessage(error.data.message);
    }
  };
  const formik = useFormik({
    initialValues: changePasswordInitialValues,
    validationSchema: changePasswordValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <Box
      style={{ marginTop: 32 }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <FormContainer style={{ marginBottom: 24 }}>
        <InformationContainer>
          <InformationMainText>
            {t("profile.tabs.changePassword")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer style={{ marginBottom: 24 }}>
          <PasswordInput
            fullWidth
            name={formikParams.OLD_PASSWORD}
            label={t("field.oldPasswordPlaceholder")}
            formik={formik}
            style={{ marginRight: "24px" }}
          />
          <PasswordInput
            fullWidth
            name={formikParams.NEW_PASSWORD}
            label={t("field.newPasswordPlaceholder")}
            formik={formik}
            style={{ marginRight: "24px" }}
          />
          <PasswordInput
            fullWidth
            name={formikParams.CONFIRM_PASSWORD}
            label={t("field.confirmPasswordPlaceholder")}
            formik={formik}
          />
        </RowContainer>
      </FormContainer>
      <ContentContainer>
        <InformationContainer />
        <RowContainer style={{ marginTop: 24, justifyContent: "center" }}>
          <Button
            onClick={() => formik.resetForm()}
            variant={buttonVariants.OUTLINED}
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            type={inputTypes.SUBMIT}
            variant={buttonVariants.CONTAINED}
            disabled={!formik.dirty}
            style={{
              backgroundColor: selectedTheme.colors.primaryBlue,
            }}
          >
            {t("common.save")}
          </Button>
        </RowContainer>
      </ContentContainer>
    </Box>
  );
};

export default ChangePassword;
