import { Button } from "@mui/material";
import Codebooks from "components/Codebooks/Codebooks";
import CustomTable from "components/CustomTable/CustomTable";
import Search from "components/Search/Search";
import Title from "components/Title/Title";
import buttonVariants from "constants/buttonVariants";
import modalConstants from "constants/modalConstants";
import {
  useAccommodationUnitTypeListQuery,
  useAddAccommodationUnitTypeMutation,
  useDeleteAccommodationUnitTypeMutation,
  useEditAccommodationUnitTypeMutation,
} from "features/accommodationUnitType/accommodationUnitTypeApiSlice";
import { closeModal, openModal } from "features/modal/modalSlice";
import { useFormik } from "formik";
import useCodebookTable from "hooks/tables/useCodebookTable";
import useDebounce from "hooks/useDebounceHook";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader, PageContainer } from "styles/SharedStyle.styled";
import selectedTheme from "themes";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import * as Yup from "yup";

const AccommodationUnitTypePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const addModal = modals?.[modalConstants.ADD_CODEBOOK_MODAL];
  const editModal = modals?.[modalConstants.EDIT_CODEBOOK_MODAL];
  const deleteModal = modals?.[modalConstants.DELETE_CODEBOOK_MODAL];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ name: "name", direction: "asc" });
  const { columns } = useCodebookTable({
    page,
    rowsPerPage,
  });

  const debouncedSearch = useDebounce(search);

  const { data: accomodationUnitTypes, isLoading } =
    useAccommodationUnitTypeListQuery({
      page,
      rowsPerPage,
      search: debouncedSearch,
      sort,
    });
  const [
    addAccomodationUnitType,
    { isLoading: isLoadingAddAccommodationUnitType },
  ] = useAddAccommodationUnitTypeMutation();

  const [
    editAccommodationUnitType,
    { isLoading: isLoadingEditAccommodationUnitType },
  ] = useEditAccommodationUnitTypeMutation();

  const [
    deleteAccommodationUnitType,
    { isLoading: isLoadingDeleteAccommodationUnitType },
  ] = useDeleteAccommodationUnitTypeMutation();

  const handleSubmit = (val) => {
    if (addModal?.open) {
      const { name } = val;
      addAccomodationUnitType({ name })
        .unwrap()
        .then(() => {
          if (!isLoadingAddAccommodationUnitType) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
            makeToastMessage(t("accommodationUnitType.addSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("accommodationUnitType.addErrorMessage")
          )
        );
    }

    if (editModal?.open) {
      const { id, name } = val;

      editAccommodationUnitType({ name, id })
        .unwrap()
        .then(() => {
          if (!isLoadingEditAccommodationUnitType) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
            makeToastMessage(t("accommodationUnitType.editSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("accommodationUnitType.editErrorMessage")
          )
        );
    }
  };

  const handleConfirmDelete = () => {
    if (deleteModal?.open) {
      deleteAccommodationUnitType({ id: deleteModal?.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteAccommodationUnitType) {
            dispatch(closeModal({ id: modalConstants.DELETE_CODEBOOK_MODAL }));
            makeToastMessage(t("accommodationUnitType.deleteSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("accommodationUnitType.deleteErrorMessage")
          )
        );
    }
  };

  const formik = useFormik({
    initialValues: editModal?.open ? editModal?.data : { name: "" },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.accommodationUnitTypeRequired")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOpenAdd = () => {
    dispatch(openModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <PageContainer>
      <Codebooks
        formik={formik}
        editTitle={t("accommodationUnitType.editTitle")}
        addTitle={t("accommodationUnitType.addTitle")}
        labelTitle={t("field.accommodationUnitTypePlaceholder")}
        confirmCloseDescription={t("accommodationUnitType.confirmClose")}
        confirmDeleteDescription={t("accommodationUnitType.confirmDelete")}
        handleConfirmDelete={handleConfirmDelete}
        isLoadingDelete={isLoadingDeleteAccommodationUnitType}
      />
      <Title title={t("accommodationUnitType.pageTitle")} />
      <PageHeader>
        <Search
          label={t("accommodationUnitType.searchPlaceholder")}
          handleChange={handleSearch}
          search={search}
        />
        <Button
          variant={buttonVariants.CONTAINED}
          style={{ backgroundColor: selectedTheme.colors.primaryBlue }}
          onClick={handleOpenAdd}
        >
          {t("accommodationUnitType.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={accomodationUnitTypes?.data}
        isLoading={isLoading}
        count={accomodationUnitTypes?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("accommodationUnitType.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default AccommodationUnitTypePage;
