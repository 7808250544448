export const receiptFormikParams = {
  RECEIPT_NUMBER: "receiptNumber",
  DATE: "date",
  CLIENT_ID: "clientId",
  DESCRIPTION: "description",
  CONTRACT_ID: "contractId",
  CASH_AMOUNT: "cashAmount",
  CARD_AMOUNT: "cardAmount",
  CHECK_AMOUNT: "checkAmount",
  TOTAL_AMOUNT: "totalAmount",
  DUE_AMOUNT: "dueAmount",
  RECEIPT_NOTICE: "receiptNotice",
};
