/* eslint-disable react/prop-types */
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomDialog from "../CustomDialog/CustomDialog";
import ModalActions from "../ModalActions/ModalActions";
import modalConstants from "constants/modalConstants";
import { useDispatch, useSelector } from "react-redux";
import { useClientDetailsQuery } from "features/client/clientApiSlice";
import { closeModal } from "features/modal/modalSlice";
import CustomTextInput from "components/Inputs/CustomTextInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import formikParams from "constants/formikParams";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useSendEmailMutation } from "features/contract/contractApiSlice";

const SendContractToEmail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sendEmail, { isLoading: isLoadingSendEmail }] = useSendEmailMutation();
  const modals = useSelector((state) => state.modal.modals);
  const sendContractToEmailModal =
    modals?.[modalConstants.SEND_CONTRACT_TO_EMAIL_MODAL];
  const { data: client } = useClientDetailsQuery(
    {
      id: sendContractToEmailModal?.data?.clientId,
    },
    { skip: !sendContractToEmailModal?.data?.clientId }
  );

  const handleSubmit = () => {
    sendEmail({ email: formik.values.email })
      .then(() => {
        if (!isLoadingSendEmail) {
          dispatch(
            closeModal({ id: modalConstants.SEND_CONTRACT_TO_EMAIL_MODAL })
          );
          makeToastMessage(t("externalSale.sentContractToEmailSuccessMessage"));
          formik.resetForm();
        }
      })
      .catch((error) => {
        makeErrorToastMessage(error.data.message);
      });
  };

  const handleClose = () => {
    dispatch(closeModal({ id: modalConstants.SEND_CONTRACT_TO_EMAIL_MODAL }));
    formik.resetForm();
  };

  const sendContractToEmailValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("forgotPassword.emailRequired"))
      .email(t("forgotPassword.emailFormat")),
  });

  const formik = useFormik({
    initialValues: {
      email: client?.email ?? "",
    },
    validationSchema: sendContractToEmailValidationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <CustomDialog
      open={sendContractToEmailModal?.open}
      handleClose={handleClose}
      handleCloseBtn={handleClose}
      title={t("common.sendToEmail")}
      maxWidth={"500px"}
    >
      <RowContainer>
        <CustomTextInput
          fullWidth
          label={t("field.travelArrangerNameAndSurnamePlaceholder")}
          value={client?.surname + " " + client?.name}
          disabled
        />
      </RowContainer>
      <RowContainer>
        <CustomTextInput
          name={formikParams.EMAIL}
          label={t("field.emailPlaceholder")}
          formik={formik}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email}
          fullWidth
        />
      </RowContainer>
      <ModalActions
        onClose={handleClose}
        actionBtnText={"common.send"}
        disabled={formik.isSubmitting}
        onAction={formik.handleSubmit}
      />
    </CustomDialog>
  );
};

export default SendContractToEmail;
