import React from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  CustomTextInputStyled,
  InputFieldContainer,
  InputFieldsContainer,
  InputLabel,
  LoginButton,
  LoginContentContainer,
  LoginForm,
  LoginImageContainer,
  LoginPageBox,
  LoginPageContainer,
  LoginTitle,
  LogoImageStyled,
  LogoTextAndTitleContainer,
  LogoTextStyled,
  PasswordInputStyled,
} from "./LoginPage.styled";
import loginValidation from "validations/loginValidation";
import loginInitialValues from "initialValues/loginInitialValues";
import { useLoginMutation } from "features/auth/authApiSlice";
import BackdropComponent from "components/Backdrop/BackdropComponent";
import { batch, useDispatch } from "react-redux";
import {
  setAgency,
  setRole,
  setToken,
  setAgencyType,
  setAgencyName,
} from "features/auth/authSlice";
import { makeErrorToastMessage } from "util/toastMessage";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import buttonVariants from "constants/buttonVariants";
import formikParams from "constants/formikParams";
import inputTypes from "constants/inputTypes";

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const { email, password } = formik.values;
    try {
      const token = await login({ email, password }).unwrap();
      const role = decodeToken(token?.jwt);
      batch(() => {
        dispatch(setRole(role?.Role));
        dispatch(setAgency(role?.Agency));
        dispatch(setAgencyType(role?.AgencyType));
        dispatch(setAgencyName(role?.AgencyName));
        dispatch(setToken(token));
      });
      navigate("/", { replace: true });
    } catch (err) {
      makeErrorToastMessage(err.data.message);
    }
  };

  const formik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <LoginPageContainer>
      <LoginPageBox>
        <LoginImageContainer>
          <LogoImageStyled />
        </LoginImageContainer>
        <LoginContentContainer>
          <LogoTextAndTitleContainer>
            <LogoTextStyled />
            <LoginTitle>{t("login.logInTitle")}</LoginTitle>
          </LogoTextAndTitleContainer>
          <LoginForm component="form" onSubmit={formik.handleSubmit}>
            <BackdropComponent position="absolute" isLoading={isLoading} />
            <InputFieldsContainer>
              <InputFieldContainer>
                <InputLabel>{t("login.emailLabel")}</InputLabel>
                <CustomTextInputStyled
                  name={formikParams.EMAIL}
                  placeholder={t("common.labelEmail")}
                  formik={formik}
                  fullWidth
                  autoFocus
                />
              </InputFieldContainer>
              <InputFieldContainer>
                <InputLabel>{t("login.passwordLabel")}</InputLabel>
                <PasswordInputStyled
                  placeholder={t("common.labelPassword")}
                  formik={formik}
                  fullWidth
                />
              </InputFieldContainer>
            </InputFieldsContainer>
            <LoginButton
              type={inputTypes.SUBMIT}
              variant={buttonVariants.CONTAINED}
              fullWidth
            >
              {t("login.logIn")}
            </LoginButton>
          </LoginForm>
        </LoginContentContainer>
      </LoginPageBox>
    </LoginPageContainer>
  );
};

LoginPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};
export default LoginPage;
