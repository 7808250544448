import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  useAddClientMutation,
  useClientDropdownQuery,
} from "features/client/clientApiSlice";
import { useTranslation } from "react-i18next";
import { convertDateToISO } from "util/dateHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useFormik } from "formik";
import clientInitialValues from "initialValues/clientInitialValues";
import clientValidation from "validations/clientValidation";
import AddClientModal from "components/Modals/AddClientModal/AddClientModal";
import CustomAutoComplete from "./CustomAutoComplete";

const ClientSelect = (props) => {
  const { t } = useTranslation();
  const [addClient, { isLoading: isLoadingAddClient }] = useAddClientMutation();
  const addClientButtonText = t("client.addButton");
  const [openAdd, setOpenAdd] = useState(false);

  const handleSubmit = async (values) => {
    try {
      await addClient({
        ...values,
        dateOfBirth: convertDateToISO(values.dateOfBirth),
        passportExpirationDate: values.passportExpirationDate
          ? convertDateToISO(values.passportExpirationDate)
          : null,
      }).unwrap();
      if (!isLoadingAddClient) {
        addClientFormik.resetForm();
        setOpenAdd(false);
        makeToastMessage(t("client.addSuccessMessage"));
      }
    } catch (e) {
      makeErrorToastMessage(e.data.message);
    }
  };

  const addClientFormik = useFormik({
    initialValues: clientInitialValues,
    validationSchema: clientValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  const handleClose = () => {
    setOpenAdd(false);
    addClientFormik.resetForm();
  };

  return (
    <>
      <AddClientModal
        open={openAdd}
        handleClose={handleClose}
        handleCloseBtn={handleClose}
        formik={addClientFormik}
        title={addClientButtonText}
      />
      <CustomAutoComplete
        name={props?.name}
        label={props?.label}
        style={props?.style}
        value={props?.value}
        formik={props?.formik}
        addButtonText={addClientButtonText}
        addButtonClick={() => setOpenAdd(true)}
        useQuery={useClientDropdownQuery}
        additionalOnChange={props?.setPassedAddressFlag}
        error={props?.error}
        helperText={props?.helperText}
      />
    </>
  );
};

ClientSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  formik: PropTypes.any,
  setPassedAddressFlag: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

export default ClientSelect;