/* eslint-disable react/prop-types */
import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog/CustomDialog";
import { RowContainer } from "styles/SharedStyle.styled";
import TextInput from "components/Inputs/TextInput";
import DatePick from "components/Inputs/DatePick";
import { validateNumericInput } from "util/helpers/preventNonNumericChars";
import CurrencySelect from "components/Inputs/CurrencySelect";
import { useCurrencyDropdownQuery } from "features/currency/currencyApiSlice";
import ModalActions from "../ModalActions/ModalActions";

const AddExchangeRateModal = ({
  open,
  handleClose,
  formik,
  handleCloseBtn,
  edit = false,
  title,
}) => {
  const { t } = useTranslation();

  const { data: currencies } = useCurrencyDropdownQuery();

  const newDate = new Date(formik?.values?.date);

  const isValidInput = (value) => {
    const regex = /^(?!.*\..*\.)[1-9][0-9]*(\.[0-9]{0,4})?$/;
    return regex.test(value);
  };

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title={title}
    >
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginBottom: "24px" }}
      >
        {edit ? t("exchangeRate.editTitle") : t("exchangeRate.addTitle")}
      </Typography>
      <Box component="form">
        <RowContainer>
          <DatePick
            name="date"
            disabled
            label={t("field.dateCalendar")}
            value={newDate}
            onChange={(value) => formik.setFieldValue("date", value, true)}
            error={formik.touched.date && Boolean(formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
          />
        </RowContainer>
        <RowContainer>
          <CurrencySelect
            name="currencyId"
            label={t("field.currencyPlaceholderRequired")}
            onChange={formik.handleChange}
            value={formik?.values?.currencyId}
            onKeyDown={validateNumericInput}
            items={currencies}
            fullWidth
            error={
              formik.touched.CurrencyId && Boolean(formik.errors.CurrencyId)
            }
            helperText={formik.touched.CurrencyId && formik.errors.CurrencyId}
          />
        </RowContainer>
        <RowContainer>
          <TextInput
            fullWidth
            name="rate"
            type="number"
            label={t("field.middleCourse")}
            value={formik?.values?.rate}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue === "" || isValidInput(inputValue)) {
                formik.setFieldValue("rate", parseFloat(inputValue));
              }
            }}
            error={formik.touched.rate && Boolean(formik.errors.rate)}
            helperText={formik.touched.rate && formik.errors.rate}
            style={{ marginBottom: "24px" }}
          />
        </RowContainer>
        <ModalActions
          onClose={handleCloseBtn}
          disabled={!formik.dirty || formik.isSubmitting}
          onAction={formik.handleSubmit}
        />
      </Box>
    </CustomDialog>
  );
};

export default AddExchangeRateModal;
