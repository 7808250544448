import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import styled from "styled-components";

export const PageContainer = styled(Box)`
  padding: 32px;
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled(Box)`
  margin-top: 32px;
`;

export const RowContainer = styled(Box)`
  margin-bottom: 24px;
  display: flex;
  // add style for small devices
  @media (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const PageHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

export const FormContainer = styled(Box)`
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  padding: 24px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const InformationContainer = styled(Box)`
  margin-bottom: 24px;
`;

export const InformationMainText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: #344054;
`;

export const UpperBar = styled.div`
  width: 100%;
  height: 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${(props) => props?.color};
`;
