/* eslint-disable no-unused-vars */
import { Button } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import StatementFilter from "components/Filter/StatementFilter/StatementFilter";
import Filter from "components/Filter/Filter";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import Title from "components/Title/Title";
import modalConstants from "constants/modalConstants";
import { PAGES } from "constants/pages";
import { closeModal } from "features/modal/modalSlice";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import useStatementTable from "hooks/tables/useStatementTable";
import {
  useDeleteStatementMutation,
  useStatementListQuery,
} from "features/statement/statementApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { convertDateToISO } from "util/dateHelpers";
import buttonVariants from "constants/buttonVariants";
import selectedTheme from "themes";
import { getFirstDayOfCurrentYear } from "util/helpers/dateHelpers";

const StatementsPage = () => {
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState({ name: "date", direction: "desc" });
  const [filter, setFilter] = useState({
    From: convertDateToISO(getFirstDayOfCurrentYear()),
  });
  const { columns } = useStatementTable({
    page,
    rowsPerPage,
  });

  const { data: statements, isLoading: isLoadingStatements } =
    useStatementListQuery({
      page,
      rowsPerPage,
      sort,
      filter,
    });
  const [deleteStatement, { isLoading: isLoadingDeleteStatement }] =
    useDeleteStatementMutation();
  const deleteModal = modals?.[modalConstants.DELETE_STATEMENT_MODAL];
  const [appliedFilters, setAppliedFilters] = useState({});
  const handleFilter = (values) => {
    setPage(0);
    const formatedValues = {
      ...values,
      From: values.From ? convertDateToISO(values.From) : null,
      Till: values.Till ? convertDateToISO(values.Till) : null,
    };
    setFilter(getNonEmptyProps(formatedValues));
    setAppliedFilters(getNonEmptyProps(formatedValues));
  };
  const filterForm = useFormik({
    initialValues: {
      From: getFirstDayOfCurrentYear(),
      Till: null,
      AccountNumber: "",
      Number: "",
      StatementTypeId: "",
    },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  const handleClose = () => {
    dispatch(closeModal({ id: modalConstants.DELETE_STATEMENT_MODAL }));
  };
  const handleConfirmDelete = () => {
    if (deleteModal?.open) {
      deleteStatement({ id: deleteModal?.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteStatement) {
            makeToastMessage(t("statement.deleteSuccessMessage"));
          }
        })
        .catch((err) => makeErrorToastMessage(err.data.message));
      dispatch(closeModal({ id: modalConstants.DELETE_STATEMENT_MODAL }));
    }
  };
  return (
    <PageContainer>
      <ConfirmModal
        open={deleteModal?.open}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleClose}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={
          deleteModal?.data?.containsItems
            ? t("statement.confirmDeleteContainsItems")
            : t("statement.confirmDelete")
        }
        isConfirmDisabled={isLoadingDeleteStatement}
      />
      <Title title={t("statement.pageTitle")} />
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            filterForm.resetForm();
          }}
        >
          <StatementFilter
            filterForm={filterForm}
            appliedFilters={appliedFilters}
          />
        </Filter>
        <Button
          variant={buttonVariants.CONTAINED}
          style={{ backgroundColor: selectedTheme.colors.primaryBlue }}
          onClick={() => navigate(PAGES.ADDSTATEMENT.route)}
        >
          {t("statement.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={statements?.data}
        isLoading={isLoadingStatements}
        count={statements?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("statement.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default StatementsPage;
