import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut, setToken } from "features/auth/authSlice";
import { isExpired } from "react-jwt";
// https://localhost:63506/api
const baseQuery = fetchBaseQuery({
  // eslint-disable-next-line no-undef
  baseUrl: process.env.REACT_APP_API_URI,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token.jwt;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    const token = api.getState().auth.token;
    const expired = isExpired(token);
    if (expired) {
      const refreshResult = await baseQuery(
        {
          url: "/refresh",
          method: "POST",
          body: { refreshToken: token.refreshToken },
        },
        api,
        extraOptions
      );
      if (refreshResult?.data) {
        const user = api.getState().auth.user;
        api.dispatch(setToken({ ...refreshResult.data, user }));
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logOut());
      }
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  // eslint-disable-next-line no-unused-vars
  endpoints: (builder) => ({}),
});
