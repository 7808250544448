import i18n from "../i18nt/index";

export const contractStatus = {
  1: {
    label: i18n.t("contractStatus.inProgress"),
    color: "#377CBE",
    background: "#F6FBFF",
  },
  2: {
    label: i18n.t("contractStatus.notConfirmed"),
    color: "#F8C12F",
    background: "#FFFBEF",
  },
  3: {
    label: i18n.t("contractStatus.confirmed"),
    color: "#F8C12F",
    background: "#FFFBEF",
  },
  4: {
    label: i18n.t("contractStatus.completed"),
    color: "#1CB948",
    background: "#F2FEF5",
  },
  5: {
    label: i18n.t("contractStatus.canceled"),
    color: "#BF3F37",
    background: "#FCECEB",
  },
};
