import { apiSlice } from "features/api/apiSlice";

export const externalSaleApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["ExternalSale", "ExternalSaleSingle"],
  endpoints: (builder) => ({
    externalSaleDetails: builder.query({
      query: ({ id }) => ({
        url: `/external-sale/${id}`,
      }),
      providesTags: ["ExternalSaleSingle"],
    }),
    addExternalSale: builder.mutation({
      query: (data) => ({
        url: "/external-sale/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["ExternalSale"],
    }),
    editExternalSale: builder.mutation({
      query: (data) => ({
        url: "/external-sale/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["ExternalSaleSingle"],
    }),
  }),
});

export const {
  useAddExternalSaleMutation,
  useExternalSaleDetailsQuery,
  useEditExternalSaleMutation,
} = externalSaleApiSlice;
