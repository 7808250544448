import { Autocomplete, Button, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import CustomTextInput from "./CustomTextInput";

const CustomAutoComplete = (props) => {
  const { data, isLoading } = props.useQuery();
  const [addButtonText, setAddButtonText] = useState("");
  const { hasAdd } = props;

  useEffect(() => {
    if (props?.addButtonText) {
      setAddButtonText(props?.addButtonText);
    }
  }, [props?.addButtonText]);

  const onAddButtonClick = useMemo(() => {
    return props?.addButtonClick;
  }, [props?.addButtonClick]);

  const options = !isLoading
    ? [addButtonText, ...(Array.isArray(data) ? data : [])]
    : [];

  return (
    <>
      <Autocomplete
        fullWidth
        style={props?.style}
        id={props?.name}
        options={options}
        getOptionLabel={(option) => option?.text}
        disabled={props?.disabled}
        value={
          !isLoading
            ? data?.find(
                (option) =>
                  option?.value ===
                  (props?.value ?? props?.formik?.values?.[props?.name])
              ) || null
            : null
        }
        onChange={(event, newValue) => {
          props?.formik?.setFieldValue(
            props?.name,
            newValue ? newValue.value : null
          );
          props?.additionalOnChange?.();
        }}
        filterOptions={(filteredOptions, state) => {
          const inputValue = state.inputValue.toLowerCase();
          const filtered = data
            ? data?.filter((singleData) =>
                singleData?.text?.toLowerCase().includes(inputValue)
              )
            : [];

          return hasAdd ? [addButtonText, ...filtered] : filtered;
        }}
        renderOption={(props, option) => {
          if (hasAdd) {
            if (option === addButtonText) {
              return (
                <Button
                  fullWidth
                  variant="contained"
                  onClick={onAddButtonClick}
                >
                  {addButtonText}
                </Button>
              );
            }
          }
          return (
            <li {...props} key={option?.value}>
              <Typography>{option?.text}</Typography>
            </li>
          );
        }}
        renderInput={(params) => (
          <CustomTextInput
            {...params}
            name={props?.name}
            label={props?.label}
            error={
              props?.error
                ? props?.error
                : props?.formik?.touched?.[props?.name] &&
                  Boolean(props?.formik?.errors?.[props?.name])
            }
            helperText={
              props?.helperText
                ? props?.helperText
                : props?.formik?.touched?.[props?.name] &&
                  props?.formik?.errors?.[props?.name]
            }
          />
        )}
      />
    </>
  );
};

CustomAutoComplete.defaultProps = {
  hasAdd: true,
  disabled: false,
};

CustomAutoComplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  useQuery: PropTypes.func,
  formik: PropTypes.any,
  addButtonText: PropTypes.string,
  addButtonClick: PropTypes.func,
  additionalOnChange: PropTypes.func,
  hasAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

export default CustomAutoComplete;
