import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { BREADCRUMBS } from "constants/breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function BreadcrumbsComponent() {
  const location = useLocation();
  const navigate = useNavigate();
  let { pathname, state } = location;
  pathname = pathname.replace(/\/\d+/g, "/");
  const matchedBreadcrumb = Object.values(BREADCRUMBS).find(
    (breadcrumb) => breadcrumb.route === pathname
  );
  if (!matchedBreadcrumb) {
    return null;
  }
  const breadcrumbs = matchedBreadcrumb.breadcrumbs;
  console.log("Breadcrumb state:", state);
  return (
    <Breadcrumbs
      sx={{ marginLeft: "8px" }}
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {breadcrumbs.map((breadcrumb, index) => {
        if (index === breadcrumbs.length - 1 || !breadcrumb.route) {
          return (
            <Typography key={breadcrumb.route || index} color="text.primary">
              {breadcrumb.title}
            </Typography>
          );
        }

        const handleClick = (event) => {
          event.preventDefault();

          const routeState = { ...state };

          let finalRoute = breadcrumb.route;
          if (breadcrumb.dynamic && state?.addAgencyId) {
            finalRoute = breadcrumb.route.replace(":id", state.addAgencyId);
          } else if (breadcrumb.dynamic && state?.editAgencyId) {
            finalRoute = breadcrumb.route.replace(":id", state.editAgencyId);
          }

          if (breadcrumb.tabId !== undefined) {
            routeState.tabId = breadcrumb.tabId;
          }

          navigate(finalRoute, { state: routeState });
        };

        return (
          <Link
            key={breadcrumb.route || index}
            underline="hover"
            color="inherit"
            href={breadcrumb.route}
            onClick={handleClick}
          >
            {breadcrumb.title}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}

export default BreadcrumbsComponent;
