import { Badge, Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const HeaderProfilePopoverContentContainer = styled(Box)`
  padding: 0 8px;
  padding-bottom: 8px;
  cursor: default;
  background-color: ${(props) => props?.theme?.colors?.tableRowSecondColor};
  border-radius: 8px;
`;
export const HeaderProfilePopoverContentHeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 60px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${(props) => props?.theme?.colors?.background2};
`;
export const ProfileInitials = styled(NavLink)`
  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 44px;
  text-decoration: none;
  background-color: ${(props) => props?.theme?.colors?.primaryLighter};
`;
export const ProfileDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  padding: 8px 0;
`;
export const ProfileName = styled(NavLink)`
  font-family: Inter;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props?.theme?.colors?.textColor};
`;
export const ProfileMail = styled(Typography)`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props?.theme?.colors?.textColor};
`;
export const HeaderProfileMenu = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const HeaderProfileMenuItem = styled(NavLink)`
  padding: 4px 8px;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props?.theme?.colors?.textColor};
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.background2};
    color: ${(props) => props?.theme?.colors?.white};
  }
`;

export const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
