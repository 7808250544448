/* eslint-disable no-unused-vars */
import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  statementItems: Yup.array().of(
    Yup.object().shape({
      creditDebit: Yup.number().required(
        i18next.t("field.creditDebitRequired")
      ),
      clientPartner: Yup.number().required(
        i18next.t("field.clientPartnerRequired")
      ),
      clientId: Yup.lazy((value, context) => {
        return context.parent.clientPartner === 2
          ? Yup.string().required(i18next.t("field.clientIdRequired"))
          : Yup.string().notRequired();
      }),
      partnerId: Yup.lazy((value, context) => {
        return context.parent.clientPartner === 1
          ? Yup.string().required(i18next.t("field.partnerIdRequired"))
          : Yup.string().notRequired();
      }),
      foreignAmount: Yup.number().required(
        i18next.t("field.foreignAmountRequired")
      ),
      exchangeRate: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(0.01, i18next.t("field.exchangeRateRequired"))
        .nullable()
        .required(i18next.t("field.exchangeRateRequired")),
      description: Yup.string().required(
        i18next.t("field.descriptionRequired")
      ),
      currencyId: Yup.string().required(i18next.t("field.nbsCurrencyRequired")),
    })
  ),
});
