/* eslint-disable react/prop-types */
import { Box, Divider } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog/CustomDialog";
import { RowContainer } from "styles/SharedStyle.styled";
import TextInput from "components/Inputs/TextInput";
import SelectInput from "components/Inputs/SelectInput";
import { useAgencyDropdownQuery } from "features/agency/agencyApiSlice";
import {
  ACCOMMODATION,
  ARRANGEMENT,
  TRANSPORT,
} from "constants/offerDetailsLevel";
import ModalActions from "../ModalActions/ModalActions";
import CategorySelect from "components/Inputs/CategorySelect";
import OfferTypeSelect from "components/Inputs/OfferTypeSelect";
import CountrySelect from "components/Inputs/CountrySelect";
import LocationSelect from "components/Inputs/LocationSelect";
import AccommodationTypeSelect from "components/Inputs/AccommodationTypeSelect";
import AccommodationSelect from "components/Inputs/AccommodationSelect";
import ServiceSelect from "components/Inputs/ServicesSelect";
import TransportationTypeSelect from "components/Inputs/TransportationTypeSelect";

const PreviewInternalSaleModal = ({
  open,
  handleClose,
  formik,
  title,
  offerDetailsLevelId,
  handleConfirm,
}) => {
  const { t } = useTranslation();
  const { data: agencies } = useAgencyDropdownQuery({}, { skip: !open });

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleClose}
      title={title}
      maxWidth="lg"
    >
      <Box component="form">
        <RowContainer>
          <TextInput
            fullWidth
            name="name"
            label={t("field.offerNamePlaceholderRequired")}
            value={formik?.values?.name || ""}
            style={{ marginRight: "24px" }}
            disabled
          />
          <TextInput
            fullWidth
            name="number"
            label={t("field.offerNumberPlaceholderRequired")}
            value={formik.values?.number || ""}
            disabled
          />
        </RowContainer>
        <RowContainer>
          <SelectInput
            fullWidth
            name="travelOrganizerId"
            label={t("field.travelOrganizerPlaceholderRequired")}
            value={formik.values?.travelOrganizerId || ""}
            style={{ marginRight: "24px" }}
            items={agencies}
            disabled
          />
          <TextInput
            fullWidth
            name="jid"
            label={t("field.jidPlaceholder")}
            value={formik.values?.jid || ""}
            onChange={formik.handleChange}
            disabled
          />
        </RowContainer>

        <Divider style={{ marginBottom: 24 }} />

        <RowContainer>
          <CategorySelect
            name="categoryId"
            label={t("field.categoryPlaceholderRequired")}
            disabled={true}
            style={{ marginRight: "24px" }}
            formik={formik}
            apiProps={{ skip: !open }}
            hasAdd={false}
          />

          <OfferTypeSelect
            name="offerTypeId"
            label={t("field.offerTypePlaceholderRequired")}
            formik={formik}
            disabled={true}
            apiProps={{ skip: !open }}
            hasAdd={false}
          />
        </RowContainer>

        <RowContainer>
          <CountrySelect
            name="countryId"
            label={t("field.countryIdPlaceholder")}
            style={{ marginRight: "24px" }}
            disabled={true}
            formik={formik}
            apiProps={{ skip: !open }}
            hasAdd={false}
          />
          <LocationSelect
            name="locationId"
            disabled={true}
            label={t("field.locationIdPlaceholder")}
            formik={formik}
            hasAdd={false}
            countryId={formik.values.countryId}
          />
        </RowContainer>

        {(offerDetailsLevelId === ARRANGEMENT ||
          offerDetailsLevelId === ACCOMMODATION) && (
          <RowContainer>
            <AccommodationTypeSelect
              name="accommodationTypeId"
              label={t("field.accommodationTypeIdPlaceholder")}
              style={{ marginRight: "24px" }}
              disabled={true}
              formik={formik}
              apiProps={{ skip: !open }}
              hasAdd={false}
            />
            <AccommodationSelect
              name="accommodationId"
              label={t("field.accommodationIdPlaceholderRequired")}
              value={formik.values?.accommodationId || ""}
              disabled={true}
              formik={formik}
              hasAdd={false}
              apiProps={{ skip: !open }}
            />
          </RowContainer>
        )}

        {(offerDetailsLevelId === ARRANGEMENT ||
          offerDetailsLevelId === ACCOMMODATION) && (
          <RowContainer>
            <AccommodationTypeSelect
              name="accommodationUnitTypeId"
              label={t("field.accommodationUnitTypeRequiredPlaceholder")}
              disabled={true}
              formik={formik}
              apiProps={{ skip: !open }}
              style={{ marginRight: "24px" }}
            />
            <ServiceSelect
              name="serviceId"
              label={t("field.serviceIdPlaceholderRequired")}
              disabled={true}
              formik={formik}
              apiProps={{ skip: !open }}
            />
          </RowContainer>
        )}

        {(offerDetailsLevelId === ARRANGEMENT ||
          offerDetailsLevelId === TRANSPORT) && (
          <RowContainer>
            <TransportationTypeSelect
              name="transportationTypeId"
              label={t("field.transportatopnTypeIdPlaceholderRequired")}
              formik={formik}
              style={{ marginRight: "24px" }}
              disabled={true}
              apiProps={{ skip: !open }}
            />
            <Box style={{ width: "100%" }}></Box>
          </RowContainer>
        )}

        <Divider style={{ marginBottom: 24 }} />

        <RowContainer>
          <SelectInput
            fullWidth
            name="priceId"
            label={"Cenovnik*"}
            value={formik.values?.accommodationUnitTypeId || ""}
            disabled
          />
        </RowContainer>

        <Divider style={{ marginBottom: 24 }} />

        <RowContainer>
          <TextInput
            fullWidth
            multiline
            minRows={4}
            maxRows={8}
            name="travelProgram"
            label={t("field.travelProgramPlaceholder")}
            value={formik?.values?.travelProgram || ""}
            style={{ marginRight: "24px" }}
            disabled
          />

          <TextInput
            fullWidth
            multiline
            minRows={4}
            maxRows={8}
            name="description"
            label={t("field.descriptionPlaceholder")}
            value={formik?.values?.description || ""}
            disabled
          />
        </RowContainer>
        <ModalActions
          onClose={handleClose}
          onAction={handleConfirm}
          actionBtnText={t("common.reserve")}
        />
      </Box>
    </CustomDialog>
  );
};

export default PreviewInternalSaleModal;
