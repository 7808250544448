import { apiSlice } from "features/api/apiSlice";

export const agencyPartnerApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["AgencyPartner", "AgencyPartnerSingle", "AgencyDropdown"],
  endpoints: (builder) => ({
    agencyPartnerDropdown: builder.query({
      query: ({ internalPartner, isOTP } = {}) => ({
        url:
          "/agency-partner/dropdown?" +
          new URLSearchParams({
            internalPartner: internalPartner ?? null,
            isOTP: isOTP ?? null,
          }),
      }),
      providesTags: ["AgencyPartner"],
    }),
    agencyPartnerList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, sort, filter } = args;
        return {
          url:
            "/agency-partner/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(sort && {
                SortPropertyName: sort.name,
                Ascending: sort.direction === "asc" ? true : false,
              }),
              //   ...(sortDirection && { orderBy: sortDirection }),
              ...(filter && filter),
            }),
        };
      },
      providesTags: ["AgencyPartner"],
    }),
    agencyPartnerDetails: builder.query({
      query: ({ id }) => ({
        url: `/agency-partner/${id}`,
      }),
      providesTags: ["AgencyPartnerSingle"],
    }),
    agencyPartnerGetInfo: builder.query({
      query: ({ id, internalPartner }) => ({
        url: `/agency-partner/get-info?${new URLSearchParams({
          id,
          internalPartner,
        })}`,
      }),
      providesTags: ["AgencyPartnerSingle"],
    }),

    createExistingPartner: builder.mutation({
      query: (data) => ({
        url: "/agency-partner/create-existing",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["AgencyPartner", "AgencyDropdown"],
    }),
    createExternalPartner: builder.mutation({
      query: (data) => ({
        url: "/agency-partner/create-external",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["AgencyPartner"],
    }),
    editExternalPartner: builder.mutation({
      query: (data) => ({
        url: "/agency-partner/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["AgencyPartner", "AgencyPartnerSingle"],
    }),
    deleteExisting: builder.mutation({
      query: (data) => ({
        url: "/agency-partner/delete-existing",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["AgencyPartner"],
    }),
    deleteExternal: builder.mutation({
      query: (data) => ({
        url: "/agency-partner/delete-external",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["AgencyPartner"],
    }),
  }),
});

export const {
  useAgencyPartnerDropdownQuery,
  useAgencyPartnerListQuery,
  useAgencyPartnerDetailsQuery,
  useAgencyPartnerGetInfoQuery,
  useCreateExistingPartnerMutation,
  useCreateExternalPartnerMutation,
  useEditExternalPartnerMutation,
  useDeleteExistingMutation,
  useDeleteExternalMutation,
} = agencyPartnerApiSlice;
