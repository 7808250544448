export default {
  serviceId: null,
  transportationTypeId: null,
  accommodationId: null,
  accommodationTypeId: null,
  accommodationUnitId: null,
  categoryId: null,
  countryId: null,
  locationId: null,
  offerTypeId: null,
  name: "",
  number: "",
  date: null,
  description: "",
  otpId: "",
  ptpotpNumber: "",
  periodFrom: null,
  periodTo: null,
  departureDate: null,
  jid: "",
  contractTravelers: {
    contractId: 0,
    travelers: [
      {
        contractTravelerId: 0,
        clientId: "",
        departureLocation: "",
        travelArranger: true,
        description: "",
        address: "",
        dateOfBirth: "",
        passportNumber: "",
        phoneNumber: "",
      },
    ],
  },
  contractCalculations: {
    contractId: 0,
    calculations: [
      {
        contractCalculationId: 0,
        service: "",
        quantity: "",
        domesticAmount: "",
        foreignAmount: "",
        domesticTotalAmount: "",
        foreignTotalAmount: "",
        domesticDiscount: "",
        foreignDiscount: "",
        currencyId: null,
      },
    ],
  },
};