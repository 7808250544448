import { apiSlice } from "features/api/apiSlice";

export const clearingApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Clearing", "ClearingSingle", "ClearingAmounts"],
  endpoints: (builder) => ({
    clearingList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, contractId } = args;
        return {
          url:
            "/clearing/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              ContractId: contractId,
            }),
        };
      },
      providesTags: ["Clearing"],
    }),
    clearingDetails: builder.query({
      query: ({ id }) => ({
        url: `/clearing/${id}`,
      }),
      providesTags: ["ClearingSingle"],
    }),
    clearingContractDetails: builder.query({
      query: ({ id }) => ({
        url: `/clearing/contract/${id}`,
      }),
      providesTags: ["ClearingContractSingle"],
    }),
    clearingAmounts: builder.query({
      query: ({ contractId }) => ({
        url: `clearing/amounts/${contractId}`,
      }),
      providesTags: ["ClearingAmounts"],
    }),
    addClearing: builder.mutation({
      query: (data) => ({
        url: "/clearing/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Clearing", "ClearingDropdown", "ClearingAmounts"],
    }),
    editClearing: builder.mutation({
      query: (data) => ({
        url: "/clearing/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Clearing", "ClearingSingle", "ClearingAmounts"],
    }),
    deleteClearing: builder.mutation({
      query: (data) => ({
        url: "/clearing",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["Clearing", "ClearingAmounts"],
    }),
  }),
});

export const {
  useClearingListQuery,
  useClearingDetailsQuery,
  useClearingContractDetailsQuery,
  useClearingAmountsQuery,
  useAddClearingMutation,
  useEditClearingMutation,
  useDeleteClearingMutation,
} = clearingApiSlice;
