import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { List, Paper, Box, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { selectIsSidebarCollapsed } from "features/app/appSlice";
import { SidebarItem, SidebarItemContainer } from "./Sidemenu/Sidemenu.styled";
import selectedTheme from "themes";
ParentItem.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  hasSub: PropTypes.bool,
  Icon: PropTypes.object,
};

function ParentItem({ path, title, hasSub, Icon, ...other }) {
  const isSidebarCollapsed = useSelector(selectIsSidebarCollapsed);
  return (
    <Tooltip title={isSidebarCollapsed ? title : ""} placement="right-start">
      <SidebarItemContainer
        style={{
          justifyContent: isSidebarCollapsed
            ? "center"
            : hasSub
            ? "space-between"
            : "",
        }}
        {...other}
      >
        <Link
          to={path}
          style={{
            textDecoration: "none",
            width: "100%",
          }}
        >
          <SidebarItem
            style={{
              display: "flex",
              justifyContent: isSidebarCollapsed ? "center" : "flex-start",
              alignItems: "center",
              borderRadius: "6px",
              marginTop: "2px",
              padding: "8px 12px",
              width: "100%",
              textDecoration: "none",
            }}
          >
            <Icon style={{ marginRight: "12px" }} />
            {!isSidebarCollapsed && title}
          </SidebarItem>
        </Link>
      </SidebarItemContainer>
    </Tooltip>
  );
}

MenuItem.propTypes = {
  parent: PropTypes.object,
  hoveredItem: PropTypes.string,
  setHoveredItem: PropTypes.func,
};

function MenuItem({ parent, hoveredItem, setHoveredItem }) {
  const { title, path, Icon, children } = parent;

  if (children) {
    return (
      <Box sx={{ position: "relative" }}>
        <ParentItem
          onMouseEnter={() => setHoveredItem(title)}
          onMouseLeave={() => setHoveredItem(null)}
          path={path}
          title={title}
          hasSub
          Icon={Icon}
        >
          {title}
        </ParentItem>

        {hoveredItem === title && (
          <Paper
            onMouseEnter={() => setHoveredItem(title)}
            onMouseLeave={() => setHoveredItem(null)}
            sx={{
              position: "absolute",
              left: "100%",
              top: 0,
              width: 300,
              borderRadius: 2,
              zIndex: 9,
              bgcolor: selectedTheme.colors.background2,
            }}
          >
            <Box>
              {children.map((list, id) => {
                const { items } = list;

                return (
                  <Box key={id}>
                    {items.map((link) => (
                      <Link
                        state={link.linkState}
                        key={link.title}
                        style={{ textDecoration: "none" }}
                        to={link.path}
                      >
                        <SidebarItemContainer>
                          <SidebarItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              borderRadius: "6px",
                              width: "100%",
                              padding: "8px 12px",
                            }}
                          >
                            {link.title}
                          </SidebarItem>
                        </SidebarItemContainer>
                      </Link>
                    ))}
                  </Box>
                );
              })}
            </Box>
          </Paper>
        )}
      </Box>
    );
  }

  return (
    <ParentItem path={path} title={title} Icon={Icon}>
      {title}
    </ParentItem>
  );
}

MenuDesktopVertical.propTypes = {
  navConfig: PropTypes.array,
  hoveredItem: PropTypes.string,
  setHoveredItem: PropTypes.func,
};

export default function MenuDesktopVertical({
  navConfig,
  hoveredItem,
  setHoveredItem,
  ...other
}) {
  return (
    <List disablePadding {...other}>
      {navConfig.map((parent) => (
        <MenuItem
          key={parent.title}
          parent={parent}
          hoveredItem={hoveredItem}
          setHoveredItem={setHoveredItem}
        />
      ))}
    </List>
  );
}
