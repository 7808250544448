export const clearingInitialValues = (clearing) => {
  return {
    domesticAmount: clearing?.domesticAmount ?? "",
    foreignAmount: clearing?.foreignAmount?.toFixed() ?? "",
    totalAmount: clearing?.originalAmount ?? "",
    exchangeRate: clearing?.exchangeRate ?? "",
    foreignAmountInForeignCurrency:
      clearing?.foreignAmount != null && clearing?.exchangeRate != null
        ? clearing?.exchangeRate == 0
          ? 0
          : (clearing.foreignAmount / clearing.exchangeRate).toFixed(2)
        : "",
    currencyId: "",
  };
};
