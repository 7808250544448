const charsToExclude = [".", "+", "-", "e", "E", ","];

export const preventNonNumericChars = (event) => {
  if (charsToExclude.includes(event.key)) {
    event.preventDefault();
  }
};

export const phoneNumberChars = (event) => {
  var key = event.which || event.keyCode;
  if (key === 9) {
    // Tab key
    event.target.nextElementSibling.focus();
    return;
  }
  if (
    key &&
    (key <= 47 || key >= 58) &&
    (key < 96 || key > 105) &&
    key != 8 &&
    key != 37 &&
    key != 39
  ) {
    event.preventDefault();
  }
};

export const validateNumericInput = (event) => {
  const key = event.which || event.keyCode;
  // Allow: backspace, delete, tab, escape, enter
  // decimal point (190 for regular keyboard, 110 for numpad)
  if (
    key == 8 ||
    key == 46 ||
    key == 9 ||
    key == 27 ||
    key == 13 ||
    key == 110 ||
    key == 190 ||
    // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
    (key >= 65 && key <= 90 && event.ctrlKey === true) ||
    // Allow: home, end, left, right
    (key >= 35 && key <= 39)
  ) {
    return; // let it happen, don't do anything
  }
  // Ensure that it is a number and stop the keypress
  if ((event.shiftKey || key < 48 || key > 57) && (key < 96 || key > 105)) {
    event.preventDefault();
  }
};
