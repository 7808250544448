/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, Popover, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useTranslation } from "react-i18next";
import { FilterActionsContainer, FilterTopContainer } from "./Filter.styled";
import { UpperBar } from "styles/SharedStyle.styled";
import selectedTheme from "themes";

const Filter = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmitFilters = () => {
    props?.filterForm.handleSubmit();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box style={{ marginRight: 24 }}>
      <Button
        onClick={handleClick}
        aria-describedby={id}
        style={{ color: selectedTheme.colors.primaryBlue }}
      >
        <FilterListIcon />
        {t("common.filter")}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <UpperBar color={selectedTheme.colors.primaryBlue} />
        <Box style={{ padding: 16, width: props?.oneInputColumn ? 500 : 750 }}>
          <FilterTopContainer>
            <Tooltip
              title={t("common.resetFilters")}
              placement="top"
              style={{ marginRight: 5 }}
            >
              <Button onClick={props?.handleReset}>
                <RestartAltIcon style={{ fill: selectedTheme.colors.text1 }} />
              </Button>
            </Tooltip>
            <Tooltip title={t("common.close")} placement="top">
              <Button onClick={handleClose}>
                <CloseIcon style={{ fill: selectedTheme.colors.text1 }} />
              </Button>
            </Tooltip>
          </FilterTopContainer>
          {props?.children}
          <FilterActionsContainer>
            <Button style={{ marginRight: 24 }} onClick={handleClose}>
              {t("common.cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmitFilters}
              style={{ backgroundColor: selectedTheme.colors.primaryBlue }}
            >
              {t("common.apply")}
            </Button>
          </FilterActionsContainer>
        </Box>
      </Popover>
    </Box>
  );
};

Filter.propTypes = {
  children: PropTypes.node,
  filterForm: PropTypes.object,
  handleReset: PropTypes.func,
  oneInputColumn: PropTypes.bool,
};

export default Filter;
