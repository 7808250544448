import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import { useTranslation } from "react-i18next";
import { format, isToday, parseISO } from "date-fns";
import CreateIcon from "components/Icon/Icons/CreateIcon";

const useExchangeRateTable = ({
  setOpenEdit,
  setEditData,
  page,
  rowsPerPage,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "currencyId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "date",
      label: "Datum",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata) => {
          if (!tableMetadata || !tableMetadata.rowData) return "";
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return rowData.date
            ? format(new Date(rowData.date), "dd.MM.yyyy.")
            : "";
        },
      },
    },
    {
      name: "currency",
      label: "Valuta",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "rate",
      label: "Srednji kurs NBS",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        customBodyRender: (_, tableMeta) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          const dateValue = rowData.date ? parseISO(rowData.date) : null;

          if (dateValue && isToday(dateValue)) {
            return (
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip
                  title="Izmeni"
                  placement="top"
                  style={{ marginRight: 5 }}
                >
                  <IconButton
                    onClick={() => {
                      setEditData(rowData);
                      setOpenEdit(true);
                    }}
                  >
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          }
          return null;
        },
      },
    },
  ];
  return { columns };
};

export default useExchangeRateTable;
