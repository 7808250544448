/* eslint-disable react/prop-types */
import styled from "@emotion/styled";
import { MenuItem, TextField } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CustomSelectInputContainer = styled(TextField)`
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
    left: 0;
  }
`;

const CustomSelectInput = (props) => {
  const { t } = useTranslation();
  return (
    <CustomSelectInputContainer
      select
      name={props.name}
      type={props.type}
      label={t(props.label)}
      onChange={props?.onChange ?? props?.formik?.handleChange}
      value={props?.value ?? props?.formik?.values?.[props?.name]}
      error={
        props?.formik?.touched?.[props?.name] &&
        Boolean(props?.formik?.errors?.[props?.name])
      }
      helperText={
        props?.formik?.touched?.[props?.name] &&
        props?.formik?.errors?.[props?.name]
      }
      fullWidth={props?.fullWidth}
      disabled={props?.disabled}
      InputLabelProps={{
        shrink: props?.value,
      }}
      SelectProps={{
        MenuProps: {
          sx: { maxHeight: 300 },
        },
      }}
      {...props}
    >
      {props?.selectPlaceholder && (
        <MenuItem value={""}>{props?.selectPlaceholder}</MenuItem>
      )}
      {props?.items &&
        props?.items?.map?.((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
    </CustomSelectInputContainer>
  );
};

CustomSelectInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  formik: PropTypes.object,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  selectPlaceholder: PropTypes.string,
};

export default CustomSelectInput;
