import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  name: Yup.string().required(
    i18next.t("field.accommodationUnitNamePlaceholderRequired")
  ),
  accommodationUnitTypeId: Yup.string().required(
    i18next.t("field.accommodationUnitTypeIdRequired")
  ),
});
