/* eslint-disable react/prop-types */
import React from "react";
import {
  CenterOverlayIcon,
  ImageOverlayContainer,
  TopRightButtonStyle,
  ZoomIconStyle,
} from "./ImageOverlay.styled";
import { IconButton } from "@mui/material";
import { ChangeCircle, Delete } from "@mui/icons-material";

const ImageOverlay = ({
  handleZoomClick,
  img,
  index,
  handleDelete,
  disabledActions,
}) => {
  return (
    <ImageOverlayContainer>
      {!disabledActions && (
        <TopRightButtonStyle>
          <IconButton
            disableRipple
            onClick={(event) => {
              event.stopPropagation();
              handleDelete(img);
            }}
          >
            <Delete style={{ color: "white" }} />
          </IconButton>
          <IconButton disableRipple>
            <ChangeCircle style={{ color: "white" }} />
          </IconButton>
        </TopRightButtonStyle>
      )}
      <CenterOverlayIcon>
        <IconButton
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            handleZoomClick(index);
          }}
        >
          <ZoomIconStyle />
        </IconButton>
      </CenterOverlayIcon>
    </ImageOverlayContainer>
  );
};

export default ImageOverlay;
