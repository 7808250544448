import { Button } from "@mui/material";
import Codebooks from "components/Codebooks/Codebooks";
import CustomTable from "components/CustomTable/CustomTable";
import Search from "components/Search/Search";
import Title from "components/Title/Title";
import buttonVariants from "constants/buttonVariants";
import modalConstants from "constants/modalConstants";
import {
  useAddCountryMutation,
  useCountryListQuery,
  useDeleteCountryMutation,
  useEditCountryMutation,
} from "features/country/countryApiSlice";
import { closeModal, openModal } from "features/modal/modalSlice";
import { useFormik } from "formik";
import useCodebookTable from "hooks/tables/useCodebookTable";
import useDebounce from "hooks/useDebounceHook";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader, PageContainer } from "styles/SharedStyle.styled";
import selectedTheme from "themes";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import * as Yup from "yup";

const CountryPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const addModal = modals?.[modalConstants.ADD_CODEBOOK_MODAL];
  const editModal = modals?.[modalConstants.EDIT_CODEBOOK_MODAL];
  const deleteModal = modals?.[modalConstants.DELETE_CODEBOOK_MODAL];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ name: "name", direction: "asc" });
  const { columns } = useCodebookTable({
    page,
    rowsPerPage,
  });

  const debouncedSearch = useDebounce(search);

  const { data: countries, isLoading } = useCountryListQuery({
    page,
    rowsPerPage,
    search: debouncedSearch,
    sort,
  });
  const [addCountry, { isLoading: isLoadingAddCountry }] =
    useAddCountryMutation();

  const [editCountry, { isLoading: isLoadingEditCountry }] =
    useEditCountryMutation();

  const [deleteCountry, { isLoading: isLoadingDeleteCountry }] =
    useDeleteCountryMutation();

  const handleSubmit = (val) => {
    if (addModal?.open) {
      const { name } = val;
      addCountry({ name })
        .unwrap()
        .then(() => {
          if (!isLoadingAddCountry) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
            makeToastMessage(t("country.addSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(e.data.message || t("country.addErrorMessage"))
        );
    }

    if (editModal?.open) {
      const { id, name } = val;

      editCountry({ name, id })
        .unwrap()
        .then(() => {
          if (!isLoadingEditCountry) {
            formik.resetForm();
            dispatch(closeModal({ id: modalConstants.EDIT_CODEBOOK_MODAL }));
            makeToastMessage(t("country.editSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(e.data.message || t("country.editErrorMessage"))
        );
    }
  };

  const handleConfirmDelete = () => {
    if (deleteModal?.open) {
      deleteCountry({ id: deleteModal?.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteCountry) {
            dispatch(closeModal({ id: modalConstants.DELETE_CODEBOOK_MODAL }));
            makeToastMessage(t("country.deleteSuccessMessage"));
          }
        })
        .catch((e) =>
          makeErrorToastMessage(
            e.data.message || t("country.deleteErrorMessage")
          )
        );
    }
  };

  const formik = useFormik({
    initialValues: editModal?.open ? editModal?.data : { name: "" },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.countryRequired")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOpenAdd = () => {
    dispatch(openModal({ id: modalConstants.ADD_CODEBOOK_MODAL }));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <PageContainer>
      <Codebooks
        formik={formik}
        editTitle={t("country.editTitle")}
        addTitle={t("country.addTitle")}
        labelTitle={t("field.countryNamePlaceholder")}
        confirmCloseDescription={t("country.confirmClose")}
        confirmDeleteDescription={t("country.confirmDelete")}
        handleConfirmDelete={handleConfirmDelete}
        isLoadingDelete={isLoadingDeleteCountry}
      />
      <Title title={t("country.pageTitle")} />
      <PageHeader>
        <Search
          label={t("country.searchPlaceholder")}
          handleChange={handleSearch}
          search={search}
        />
        <Button
          variant={buttonVariants.CONTAINED}
          style={{ backgroundColor: selectedTheme.colors.primaryBlue }}
          onClick={handleOpenAdd}
        >
          {t("country.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={countries?.data}
        isLoading={isLoading}
        count={countries?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("country.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default CountryPage;
