import { PAGES } from "constants/pages";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import BackdropComponent from "components/Backdrop/BackdropComponent";
import Error from "pages/ErrorPage/ErrorPage";
import Title from "components/Title/Title";
import PartnerForm from "components/Forms/PartnerForm/PartnerForm";
import {
  useAgencyPartnerDetailsQuery,
  useCreateExternalPartnerMutation,
  useEditExternalPartnerMutation,
} from "features/agencyPartner/agencyPartnerApiSlice";
import partnerInitialValues from "initialValues/partnerInitialValues";
import { OTP_TYPE } from "constants/agencyType";
import agencyPartnerExternalValidationOtp from "validations/agencyPartnerExternalValidationOtp";
import agencyPartnerExternalValidationNonOtp from "validations/agencyPartnerExternalValidationNonOtp";

const PartnerAddEditPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const path = useLocation().pathname;
  const isAdd = path === PAGES.ADDPARTNER.route;
  const navigate = useNavigate();
  const [agencyType, setAgencyType] = useState(null);

  const {
    data: partnerDetails,
    isLoading: isLoadingPartnerDetails,
    error,
  } = useAgencyPartnerDetailsQuery({ id: id }, { skip: isAdd });
  const [createExternalPartner, { isLoading: isLoadingCreateExternalPartner }] =
    useCreateExternalPartnerMutation();
  const [editPartner, { isLoading: isLoadingEditPartner }] =
    useEditExternalPartnerMutation();

  const handleSubmit = async (values, action) => {
    if (values.licenceDate) {
      const parsedDate = new Date(values.licenceDate);
      values.licenceDate = isNaN(parsedDate) ? new Date() : parsedDate;
    }
    if (!id) {
      await createExternalPartner(values)
        .unwrap()
        .then(() => {
          if (!isLoadingCreateExternalPartner) {
            navigate(PAGES.PARTNERS.route);
            makeToastMessage(t("partners.addSuccessMessage"));
          }
        })
        .catch((e) => makeErrorToastMessage(e.data.message));
    }

    if (id) {
      await editPartner(values)
        .unwrap()
        .then(() => {
          if (!isLoadingEditPartner) {
            navigate(PAGES.PARTNERS.route);
            makeToastMessage(t("partners.editSuccessMessage"));
          }
        })
        .catch((e) => makeErrorToastMessage(e.data.message));
    }
    action.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues:
      id && !isLoadingPartnerDetails ? partnerDetails : partnerInitialValues,
    validationSchema:
      agencyType == OTP_TYPE
        ? agencyPartnerExternalValidationOtp
        : agencyPartnerExternalValidationNonOtp,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    setAgencyType(formik.values.partnerTypeId);
  }, [formik]);

  if (isLoadingPartnerDetails) {
    return (
      <BackdropComponent
        position="absolute"
        isLoading={isLoadingPartnerDetails}
      />
    );
  }

  if (error) {
    if (error.status === 400) {
      return <Error />;
    }
  }
  return (
    <PageContainer>
      <Title title={id ? t("partners.editTitle") : t("partners.addTitle")} />
      <PartnerForm formik={formik} />
    </PageContainer>
  );
};

export default PartnerAddEditPage;
