/* eslint-disable react/prop-types */
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ImagePickerContainer, ImagePickerLabel } from "./ImagePicker.styled";
import { Box, CircularProgress, Typography } from "@mui/material";
import ImageOverlay from "components/ImageOverlay/ImageOverlay";
import ImageCarousel from "components/ImageCarousel/ImageCarousel";

const ImagePicker = ({
  multiple = false,
  styleContainer,
  images,
  setImages,
  size = 2, // size in MB
  descriptionText = "Odaberite sliku",
  additionalDescriptionText = "",
  width = 200,
  height = 200,
  label,
  isLoading,
  handleDelete,
  maxImages = 10,
  disabledActions = false,
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [carouselOpen, setCarouselOpen] = useState(false);

  const showOverlay = (index) => setActiveImageIndex(index);

  const hideOverlay = () => setActiveImageIndex(null);

  const handleZoomClick = (index) => {
    setActiveImageIndex(index);
    setCarouselOpen(true);
  };

  const handleCloseCarousel = () => {
    setCarouselOpen(false);
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const validFiles = acceptedFiles.filter((file) => {
        const fileSizeMB = file.size / 1024 / 1024;
        const fileType = file.type;
        const isValidFormat =
          fileType === "image/png" ||
          fileType === "image/jpeg" ||
          fileType === "image/jpg";

        if (fileSizeMB > size) {
          alert(`Datoteka ${file.name} je veća od ${size}MB`);
          return false;
        }

        if (!isValidFormat) {
          alert(
            `Datoteka ${file.name} nije validan format. Dozvoljeni formati su PNG, JPG, i JPEG.`
          );
          return false;
        }

        return true;
      });

      const base64Files = await Promise.all(
        validFiles.map((file) => toBase64(file))
      );
      if (!multiple) {
        setImages(base64Files);
        return;
      }
      if (multiple) {
        if (images.length + base64Files.length > maxImages) {
          alert(`Maksimalan broj slika je ${maxImages}`);
          return;
        }
        setImages((prevImages) => [...prevImages, ...base64Files]);
      }
    },
    [multiple, setImages, size, images, maxImages]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    disabled: disabledActions,
  });

  return (
    <Box
      style={{
        ...styleContainer,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {label && <ImagePickerLabel>{label}</ImagePickerLabel>}
      <ImagePickerContainer
        {...getRootProps()}
        disabledActions={disabledActions}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <input {...getInputProps()} name={name} />
            <Box
              style={{
                display: "flex",
                flexWrap: "wrap",
                position: "relative",
                justifyContent: "center",
                gap: 16,
              }}
            >
              {!images?.length && (
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Typography>{descriptionText}</Typography>
                  {additionalDescriptionText && (
                    <Typography variant="body2">
                      {additionalDescriptionText}
                    </Typography>
                  )}
                </Box>
              )}
              {!isLoading &&
                images?.map((image, index) => (
                  <Box
                    onMouseEnter={() => showOverlay(index)}
                    onMouseLeave={hideOverlay}
                    key={index}
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      src={image}
                      alt={`uploaded ${index}`}
                      style={{ width, height, objectFit: "contain" }}
                    />
                    {activeImageIndex === index && (
                      <ImageOverlay
                        index={index}
                        img={image}
                        handleZoomClick={handleZoomClick}
                        handleDelete={() => handleDelete(index)}
                        disabledActions={disabledActions}
                      />
                    )}
                  </Box>
                ))}
            </Box>
          </>
        )}
      </ImagePickerContainer>
      {images?.length > 0 && (
        <ImageCarousel
          open={carouselOpen}
          handleClose={handleCloseCarousel}
          images={images}
          initialIndex={activeImageIndex}
        />
      )}
    </Box>
  );
};

export default ImagePicker;
