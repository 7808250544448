import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import { ReactComponent as LogoImage } from "assets/images/svg/logoPrimary.svg";
import { ReactComponent as LogoText } from "assets/images/svg/MasterOfTravelPrimary.svg";
import CustomTextInput from "components/Inputs/CustomTextInput";
import PasswordInput from "components/Inputs/PasswordInput";
import selectedTheme from "themes";

export const LoginPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${selectedTheme?.colors?.background2};
`;

export const LoginPageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${selectedTheme?.colors?.white};
  padding: 24px;
  border-radius: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 32px 16px;
  }
`;

export const LoginImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 64px;
  margin-left: 16px;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 32px;
  }
`;

export const LoginContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px; 
  min-width: 350px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LogoTextAndTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const LogoImageStyled = styled(LogoImage)`
  width: 302.08px;
  height: 304.19px;
`;

export const LogoTextStyled = styled(LogoText)`
  width: 130px;
`;

export const LoginTitle = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 28.13px;
  color: ${selectedTheme?.colors?.text1};
`;

export const LoginForm = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: 100%;
`;

export const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const InputLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
  color: ${selectedTheme?.colors?.text1};
`;

const createStyledInputField = (InputField) => styled(InputField)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border: 2.5px solid ${selectedTheme.colors?.text4};
      border-radius: 8px;
    }
  }
  & .MuiInputBase-input {
    padding-top: 20px;
  }
  & .MuiInputBase-input::placeholder {
    color: ${selectedTheme.colors?.text4};
    font-weight: 400;
    font-size: 24px;
    line-height: 28.13px;
    align-self: center;
    opacity: 1;
  }
`;

export const CustomTextInputStyled = createStyledInputField(CustomTextInput);
export const PasswordInputStyled = createStyledInputField(PasswordInput);

export const LoginButton = styled(Button)`
  font-weight: 400;
  font-size: 24px;
  line-height: 28.13px;
  height: 60px;
  border-radius: 8px;
  background-color: ${selectedTheme?.colors?.primaryBlue};
  text-transform: none;
`;
