/* eslint-disable react/prop-types */
import { Box, Button, Divider, IconButton } from "@mui/material";
import DatePick from "components/Inputs/DatePick";
import TextInput from "components/Inputs/TextInput";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  useClientDropdownQuery,
  useLazyClientDetailsQuery,
} from "features/client/clientApiSlice";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import PassengerSelect from "components/Inputs/PassengerSelect";
import {
  ExternalPassengerItemsContainer,
  ExternalPassengerOrdinalNumber,
  ExternalPassengerActions,
  ExternalPassengerRow,
} from "./ExternalPassenger.styled";
import Checkbox from "components/Checkbox/Checkbox";
import buttonVariants from "constants/buttonVariants";
import selectedTheme from "themes";
import { externalSaleFormikParams } from "constants/formik/externalSaleParams";

const ExternalPassenger = ({ formik }) => {
  const { t } = useTranslation();
  const { data: clients } = useClientDropdownQuery();

  const [client] = useLazyClientDetailsQuery();

  const getDetails = async (index, id) => {
    const { data } = await client({ id });
    if (data) {
      const passenger = `contractTravelers.travelers[${index}]`;
      formik.setFieldValue(`${passenger}.address`, data.address);
      formik.setFieldValue(`${passenger}.location`, data.location);
      formik.setFieldValue(`${passenger}.phoneNumber`, data.phoneNumber);
      formik.setFieldValue(`${passenger}.dateOfBirth`, data.dateOfBirth);
      formik.setFieldValue(`${passenger}.passportNumber`, data.passportNumber);
    }
  };

  const onCheckPassengerAsTravelArranger = (e, i) => {
    const value = e.target.checked;
    if (value) {
      formik.values.contractTravelers.travelers.forEach((el, index) => {
        formik.setFieldValue(
          `contractTravelers.travelers[${index}].travelArranger`,
          false
        );
      });
    }
    formik.setFieldValue(
      `contractTravelers.travelers[${i}].travelArranger`,
      e.target.checked
    );
  };

  return (
    <FormContainer>
      <InformationContainer>
        <InformationMainText>
          {t("externalSale.sections.passengersDetails")}
        </InformationMainText>
      </InformationContainer>
      {formik.values?.contractTravelers?.travelers &&
        formik.values?.contractTravelers?.travelers?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <ExternalPassengerRow>
                <ExternalPassengerOrdinalNumber>
                  <TextInput
                    fullWidth
                    label="R. br."
                    value={index + 1}
                    style={{ marginRight: "24px" }}
                    disabled
                  />
                </ExternalPassengerOrdinalNumber>
                <ExternalPassengerItemsContainer>
                  <RowContainer>
                    <PassengerSelect
                      fullWidth
                      name={externalSaleFormikParams.CLIENT_ID}
                      label={t("field.passengerNamePlaceholderRequired")}
                      items={clients}
                      value={
                        formik.values?.contractTravelers?.travelers[index]
                          .clientId
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `contractTravelers.travelers[${index}].clientId`,
                          e.target.value
                        );
                        getDetails(index, e.target.value);
                      }}
                      error={
                        formik.touched.contractTravelers?.travelers?.[index]
                          ?.clientId &&
                        Boolean(
                          formik.errors.contractTravelers?.travelers?.[index]
                            ?.clientId
                        )
                      }
                      helperText={
                        formik.touched.contractTravelers?.travelers?.[index]
                          ?.clientId &&
                        formik.errors.contractTravelers?.travelers?.[index]
                          ?.clientId
                      }
                      style={{ marginRight: "24px" }}
                    />
                    <TextInput
                      fullWidth
                      name={externalSaleFormikParams.ADDRESS}
                      label={t("field.addressPlaceholder")}
                      value={
                        formik.values?.contractTravelers?.travelers[index]
                          .address || ""
                      }
                      style={{ marginRight: "24px" }}
                      disabled
                    />
                    <TextInput
                      fullWidth
                      name={externalSaleFormikParams.LOCATION}
                      label={t("field.locationIdPlaceholder")}
                      value={
                        formik.values?.contractTravelers?.travelers[index]
                          .location || ""
                      }
                      style={{ marginRight: "24px" }}
                      disabled
                    />
                    <TextInput
                      fullWidth
                      name={externalSaleFormikParams.PHONE_NUMBER}
                      label={t("field.phoneNumberPlaceholder")}
                      value={
                        formik.values?.contractTravelers?.travelers[index]
                          ?.phoneNumber || ""
                      }
                      disabled
                    />
                  </RowContainer>
                  <RowContainer>
                    <DatePick
                      name={externalSaleFormikParams.DATE_OF_BIRTH}
                      label={t("field.dateOfBirthPlaceholder")}
                      value={
                        new Date(
                          formik.values?.contractTravelers?.travelers[
                            index
                          ]?.dateOfBirth
                        ) || null
                      }
                      disabled
                      style={{ marginRight: "24px" }}
                    />
                    <TextInput
                      fullWidth
                      name={externalSaleFormikParams.PASSPORT_NUMBER}
                      label={t("field.passportNumberPlaceholder")}
                      value={
                        formik.values?.contractTravelers?.travelers[index]
                          ?.passportNumber || ""
                      }
                      style={{ marginRight: "24px" }}
                      disabled
                    />
                    <TextInput
                      fullWidth
                      name={externalSaleFormikParams.DEPARTURE_LOCATION}
                      label={t("field.departurePlacePlaceholder")}
                      value={
                        formik.values?.contractTravelers?.travelers[index]
                          ?.departureLocation || ""
                      }
                      style={{ marginRight: "24px" }}
                      error={
                        formik.touched.contractTravelers?.travelers?.[index]
                          ?.departureLocation &&
                        Boolean(
                          formik.errors.contractTravelers?.travelers?.[index]
                            ?.departureLocation
                        )
                      }
                      helperText={
                        formik.touched.contractTravelers?.travelers?.[index]
                          ?.departureLocation &&
                        formik.errors.contractTravelers?.travelers?.[index]
                          ?.departureLocation
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `contractTravelers.travelers[${index}].departureLocation`,
                          e.target.value
                        );
                      }}
                    />
                    <Checkbox
                      label={t("field.travelArrangerPlaceholder")}
                      checked={
                        formik.values.contractTravelers.travelers[index]
                          .travelArranger
                      }
                      onChange={(e) =>
                        onCheckPassengerAsTravelArranger(e, index)
                      }
                      name={externalSaleFormikParams.TRAVEL_ARRANGER}
                      value={
                        formik.values.contractTravelers.travelers[index]
                          .travelArranger
                      }
                    />
                  </RowContainer>
                </ExternalPassengerItemsContainer>
                <ExternalPassengerActions>
                  <IconButton
                    color="error"
                    onClick={() => {
                      if (
                        formik.values.contractTravelers.travelers.length > 1
                      ) {
                        formik.setFieldValue(
                          "contractTravelers.travelers",
                          formik.values.contractTravelers.travelers.filter(
                            (_, i) => i !== index
                          ) // Remove the item
                        );
                      } else {
                        formik.setFieldValue("contractTravelers.travelers", [
                          {
                            contractTravelerId: 0,
                            clientId: "",
                            departureLocation: "",
                            travelArranger: true,
                            description: "",
                          },
                        ]);
                      }
                    }}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ExternalPassengerActions>
              </ExternalPassengerRow>
              {
                // should show divider if it is not the last item
                formik.values.contractTravelers.travelers.length - 1 !==
                  index && <Divider style={{ marginBottom: 24 }} />
              }
            </React.Fragment>
          );
        })}

      <Box style={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          variant={buttonVariants.CONTAINED}
          color="primary"
          startIcon={<AddIcon />}
          onClick={() =>
            formik.setFieldValue("contractTravelers.travelers", [
              ...formik.values.contractTravelers.travelers,
              {
                contractTravelerId: 0,
                clientId: "",
                departureLocation: "",
                travelArranger: false,
                description: "",
              },
            ])
          }
          style={{
            backgroundColor: selectedTheme.colors.primaryBlue,
          }}
        >
          {t("singleContract.addPassenger")}
        </Button>
      </Box>
    </FormContainer>
  );
};

export default ExternalPassenger;
