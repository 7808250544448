import React from "react";

import BookmarkAddedIconMui from "@mui/icons-material/BookmarkAdded";
import styled from "styled-components";

const BookmarkAddedIconMuiStyled = styled(BookmarkAddedIconMui)`
  fill: ${(props) => props?.theme?.colors?.iconColor};
`;

const BookmarkAddedIcon = (props) => {
  return <BookmarkAddedIconMuiStyled {...props} />;
};

BookmarkAddedIcon.propTypes = {};

export default BookmarkAddedIcon;
