import React from "react";
import { useSelector } from "react-redux";

import { SUPERADMIN_ROLE } from "constants/roleConstants";
import { homePageNavigationLinks } from "constants/navigationConstants";
import { OTP_TYPE } from "constants/agencyType";
import useAuth from "hooks/useAuth";
import AdminPage from "pages/AdminPage/AdminPage";
import { selectCurrentAgencyType } from "features/auth/authSlice";
import { HomePageCardContainer } from "./HomePage.styled";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/SharedStyle.styled";
import Title from "components/Title/Title";
import PageCardWithText from "components/Cards/PageCard/PageCardWithText";

const HomePage = () => {
  const agencyType = useSelector(selectCurrentAgencyType);
  const { hasRole } = useAuth();
  const { t } = useTranslation();
  const isOtp = agencyType === OTP_TYPE;

  return hasRole(SUPERADMIN_ROLE) ? (
    <AdminPage />
  ) : (
    <PageContainer>
      <Title title={t("pages.home")} />

      <HomePageCardContainer>
        {homePageNavigationLinks.map((link) => (
          <PageCardWithText
            key={link.title}
            isOtp={isOtp}
            path={link.path}
            Icon={link.icon}
            title={link.title}
            text={link.text}
          />
        ))}
      </HomePageCardContainer>
    </PageContainer>
  );
};

export default HomePage;
