/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import inputTypes from "constants/inputTypes";
import CustomTextInput from "./CustomTextInput";
import formikParams from "constants/formikParams";

const PasswordInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleChange = (e) => {
    if (!showPassword) {
      e.preventDefault();
    }
  };
  return (
    <CustomTextInput
      name={props?.name}
      label={props?.label}
      type={showPassword ? inputTypes.TEXT : inputTypes.PASSWORD}
      value={props?.value}
      formik={props?.formik}
      fullWidth={props?.fullWidth}
      disabled={props?.disabled}
      onCut={handleChange}
      onCopy={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

PasswordInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  formik: PropTypes.object,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

PasswordInput.defaultProps = {
  name: formikParams.PASSWORD,
};

export default PasswordInput;
