import { Box } from "@mui/material";
import styled from "styled-components";

export const ClientCardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const ClientCardInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 24px;
  @media (max-width: 960px) {
    padding-right: 0;
    padding-bottom: 24px;
  }
`;
